import React, {useState} from 'react';
import {Textarea, Icon} from '@lightricks/react-design-system';
import styles from './Bio.module.scss';

interface BioProps {
  value: string | undefined;
  onChange: (bio: string) => void;
}

const BIO_PLACEHOLDER = `@  YourHandle | Your Name
📍  Location + Industry Expert
🎯  Specific Niche & Style
✨  Brand Partnerships . Campaigns
🌟  Unique Value: Awards / Features / Reach
🔗  Portfolio: link.bio/yourname
`;

export default function Bio({value, onChange}: BioProps) {
  const [minRows, setMinRows] = useState(10);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setMinRows(15);
    setIsFocused(true);
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const handleBlur = () => {
    setMinRows(10);
    setIsFocused(false);
  };

  return (
    <div className={styles.bioContainer}>
      <Textarea
        className={styles.bioTextarea}
        label=""
        value={value}
        onChange={(event) => onChange(event.target.value)}
        minRows={minRows}
        placeholder={BIO_PLACEHOLDER}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!isFocused && (
        <div className={styles.editIcon}>
          <Icon
            size="medium"
            appearance="inverse-secondary"
            name="Features-Makeup-Liner"
          />
        </div>
      )}
    </div>
  );
}
