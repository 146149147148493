import React from 'react';
import {Body, Icon} from '@lightricks/react-design-system';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import styles from './DateInputWithPicker.module.scss';

interface DateInputWithPickerProps {
  date?: Date;
  errorMessage?: string;
  onChange: (date: Date | null) => void;
  label: string;
  placeholder?: string;
}

function DateInputWithPicker(props: DateInputWithPickerProps) {
  const {date, errorMessage, onChange, label, placeholder} = props;

  return (
    <div className={styles.dateInputWithPicker}>
      <div className={styles.content}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={date ? dayjs(date) : null}
            onChange={(newValue) => {
              onChange(newValue ? newValue.toDate() : null);
            }}
            maxDate={dayjs()}
            className={styles.datePicker}
            disableHighlightToday
            slots={{
              openPickerIcon: () => (
                <Icon
                  name="Navigation-Calendar"
                  size="medium"
                  appearance="secondary"
                />
              ),
            }}
            slotProps={{
              textField: {
                InputProps: {
                  placeholder,
                },
              },
            }}
          />
        </LocalizationProvider>
        {errorMessage && (
          <Body size="sm" className={styles.otpError}>
            {errorMessage}
          </Body>
        )}
      </div>
    </div>
  );
}

export default DateInputWithPicker;
