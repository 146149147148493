import React, {useState, useCallback, useEffect} from 'react';
import {Player} from '@lottiefiles/react-lottie-player';
import {Body, Icon} from '@lightricks/react-design-system';
import instagramFacebookConnectAnimation from '@/assets/lotties/brand-safety/instagram_facebook_connect_animation.json';
import styles from './InstagramInstructions.module.scss';
import {TranslateComponent} from '@/utils/translate';

const INSTAGRAM_INSTRUCTION_KEYS = [
  'Sign in to your Facebook account',
  'Press <b>edit access link</b>, select the account you want to use',
  'Choose the Instagram account to connect',
  'Then, confirm your Instagram is linked to your Facebook Page',
] as const;

interface InstructionItemProps {
  number: number;
  translationKey: string;
}

function InstructionItem({number, translationKey}: InstructionItemProps) {
  return (
    <li className={styles.instructionItem}>
      <div className={styles.numberCircle}>{number}</div>
      <Body size="xl">
        <TranslateComponent value={translationKey} components={{b: <b />}} />
      </Body>
    </li>
  );
}

export default function InstagramInstructions() {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const handleCloseVideo = useCallback(() => {
    setIsVideoModalOpen(false);
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseVideo();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleCloseVideo]);

  const handleOpenVideo = () => {
    setIsVideoModalOpen(true);
  };

  return (
    <div className={styles.instagramInstructionsContainer}>
      <ul className={styles.instructions}>
        {INSTAGRAM_INSTRUCTION_KEYS.map((key, index) => (
          <InstructionItem key={key} number={index + 1} translationKey={key} />
        ))}
      </ul>
      <div
        className={styles.learnToContainer}
        onClick={handleOpenVideo}
        role="button"
        tabIndex={0}
      >
        <Body size="xl">
          <TranslateComponent
            value="Learn how to connect Instagram<br/><u>Watch the steps</u></u>"
            components={{u: <u />}}
          />
        </Body>
        <div className={styles.playIcon}>
          <Icon name="Actions-Play" size="medium" appearance="neutral" />
        </div>
      </div>

      {isVideoModalOpen && (
        <div className={styles.videoContainer}>
          <div className={styles.closeButton} onClick={handleCloseVideo}>
            <Icon
              name="Actions-Close-Normal"
              size="large"
              appearance="inverse"
            />
          </div>
          <iframe
            title="Instagram instructions video"
            src="https://player.vimeo.com/video/1048991525"
            width="100%"
            height="100%"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      )}
    </div>
  );
}

export function InstagramInstructionsMedia() {
  return (
    <Player
      autoplay
      loop
      src={instagramFacebookConnectAnimation}
      className={styles.animation}
    />
  );
}
