const SCREEN_PRESENTED_SCHEMA = {
  name: 'screen_presented',
  doc: 'Sent when any screen within the platform is presented to the user',
  namespace: 'com.lightricks.pp.creators.analytics',
  type: 'record',
  fields: [
    {
      name: 'screen_presentation_id',
      doc: 'A unique id of the screen_presentation, links between presented and dismissed events',
      type: 'string',
    },
    {
      name: 'source',
      doc: 'Where the screen was launched from',
      type: 'string',
    },
    {
      name: 'screen_name',
      doc: 'Name of the screen presented',
      type: 'string',
    },
    {
      name: 'trigger_flow_id',
      doc: 'ID of the event that triggered the screen presentation. Null if irrelevant',
      type: 'string',
    },
    {
      name: 'trigger_flow_name',
      doc: 'Name of the flow of the trigger event id',
      type: 'string',
    },
    {
      name: 'tab',
      default: null,
      doc: 'Name of the tab presented',
      type: ['null', 'string'],
    },
    {
      name: 'pp_id',
      default: null,
      doc: 'The user Popular Pays (Creator|Brand) ID',
      type: ['null', 'string'],
    },

    {
      name: 'creator_id',
      default: null,
      doc: 'The Creator ID (set by PP Suite) in context',
      type: ['null', 'string'],
    },
    {
      name: 'app_bundle_id',
      doc: 'Unique application name of the software that produced the event',
      type: 'string',
    },
    {
      name: 'app_version_code',
      default: null,
      doc: 'The version of the software that produced the event',
      type: ['null', 'string'],
    },
    {
      name: 'app_build_number',
      default: null,
      doc: 'The event producer software CI build number',
      type: ['null', 'string'],
    },
    {
      name: 'event_timestamp',
      doc: 'The epoch time (UTC) that this event happened',
      type: {
        logicalType: 'timestamp-millis',
        type: 'long',
      },
    },
    {
      name: 'device_timestamp',
      doc: 'The local wall clock time as seen by the event producer the moment the event was created',
      type: {
        logicalType: 'timestamp-millis',
        type: 'long',
      },
    },
    {
      name: 'event_id',
      doc: 'Unique UUID for the specific event instance',
      type: 'string',
    },
    {
      name: 'anonymous_id',
      default: null,
      doc: 'The user anonymous ID, should be sent even after login',
      type: ['null', 'string'],
    },
    {
      name: 'lt_id',
      default: null,
      doc: 'The user Lightricks ID (generated by Fortress)',
      type: ['null', 'string'],
    },
    {
      name: 'is_subscriber',
      default: null,
      doc: 'Is the active user a subscriber',
      type: ['null', 'boolean'],
    },
    {
      name: 'platform_subscription_id',
      default: null,
      doc: 'The user subscription ID (based on PX-Griffin payment source id)',
      type: ['null', 'string'],
    },
    {
      name: 'subscription_product',
      default: null,
      doc: 'The user subscription product category',
      type: ['null', 'string'],
    },
    {
      name: 'session_id',
      doc: 'A unique identifier per app session',
      type: 'string',
    },
    {
      name: 'context',
      default: null,
      doc: 'The general context for the event',
      type: [
        'null',
        {
          name: 'context',
          doc: 'The general context properties for the event',
          type: 'record',
          fields: [
            {
              name: 'locale',
              default: null,
              doc: 'Locale for the current user',
              type: ['null', 'string'],
            },
            {
              name: 'page',
              default: null,
              doc: 'Current page properties',
              type: [
                'null',
                {
                  name: 'Page',
                  doc: 'Current web page properties',
                  type: 'record',
                  fields: [
                    {
                      name: 'path',
                      default: null,
                      doc: 'Path portion of the URL of the page',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'referrer',
                      default: null,
                      doc: 'Full referrer URL (the user previous page)',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'search',
                      default: null,
                      doc: 'Query string portion of the URL of the page',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'title',
                      default: null,
                      doc: 'Title of the page',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'url',
                      default: null,
                      doc: 'Full URL of the current page',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'language',
                      default: null,
                      doc: 'Page language',
                      type: ['null', 'string'],
                    },
                  ],
                },
              ],
            },
            {
              name: 'user_agent',
              default: null,
              doc: 'User agent of the device making the request',
              type: [
                'null',
                {
                  name: 'userAgent',
                  doc: 'User agent properties of the device making the request',
                  type: 'record',
                  fields: [
                    {
                      name: 'line',
                      default: null,
                      doc: 'Full user agent line',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'os',
                      default: null,
                      doc: 'Operating system',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'os_version',
                      default: null,
                      doc: 'Operating system version',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'software',
                      default: null,
                      doc: 'Software used (Browser)',
                      type: ['null', 'string'],
                    },
                    {
                      name: 'software_version',
                      default: null,
                      doc: 'Software version used (Browser)',
                      type: ['null', 'string'],
                    },
                  ],
                },
              ],
            },
            {
              name: 'screen',
              default: null,
              doc: 'Current screen resolution',
              type: [
                'null',
                {
                  name: 'Screen',
                  doc: 'Current screen resolution',
                  type: 'record',
                  fields: [
                    {
                      name: 'width',
                      default: null,
                      doc: 'Screen width (pixels)',
                      type: ['null', 'int'],
                    },
                    {
                      name: 'height',
                      default: null,
                      doc: 'Screen height (pixels)',
                      type: ['null', 'int'],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'is_lt_team',
      default: null,
      doc: 'Is this event being triggered by an internal LTX-PP team user action',
      type: ['null', 'boolean'],
    },
    {
      name: 'is_sign_up_session',
      default: null,
      doc: 'Is this event being triggered in the session where the user signed up',
      type: ['null', 'boolean'],
    },
    {
      name: 'attribution',
      default: null,
      doc: 'The attribution data sourced from the UTM',
      type: [
        'null',
        {
          name: 'attribution',
          doc: 'The attribution properties for the event sourced from the UTM',
          type: 'record',
          fields: [
            {
              name: 'utm_line',
              default: null,
              doc: 'The full utm unparsed line',
              type: ['null', 'string'],
            },
            {
              name: 'utm_source',
              default: null,
              doc: 'The Source attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_medium',
              default: null,
              doc: 'The Medium attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_campaign',
              default: null,
              doc: 'The Campaign attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_placement',
              default: null,
              doc: 'The Placement attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_campaign_name',
              default: null,
              doc: 'The Campaign name attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_adset_name',
              default: null,
              doc: 'The Adset name attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_ad_name',
              default: null,
              doc: 'The Ad name attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_ad_id',
              default: null,
              doc: 'The Ad id attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_adset_id',
              default: null,
              doc: 'The Adset id attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'utm_search',
              default: null,
              doc: 'The Search attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'gclid',
              default: null,
              doc: 'The GCLID attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'fbid',
              default: null,
              doc: 'The FBID attribute of the UTM',
              type: ['null', 'string'],
            },
            {
              name: 'ttclid',
              default: null,
              doc: 'The TTCLID attribute of the UTM',
              type: ['null', 'string'],
            },
          ],
        },
      ],
    },
    {
      name: 'subscription_plan',
      default: null,
      doc: 'The user subscription plan',
      type: ['null', 'string'],
    },
  ],
};

export default SCREEN_PRESENTED_SCHEMA;
