import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import {Label} from '@lightricks/react-design-system';
import useAuthenticate from '../../../hooks/use-authenticate';
import useToken from '../../../hooks/use-token';
import useNavigation from '../../../hooks/use-navigation';
import translate from '@/utils/translate';
import authRequests from '../../../api/auth';
import emailValidator from '@/utils/validators/email';
import EmailConfirmation from './components/EmailConfirmation';
import OtpVerification from './components/OtpVerification';
import BrandIntro from './components/BrandIntro';
import {flowNames} from '../../../lib/delta/deltaConstants';
import DeltaHelper from '../../../services/delta/DeltaHelper';
import Delta, {navigateWithAnalytics} from '@/utils/wrappers/Delta';
import SIGNUP_FLOW_STARTED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupFlowStarted';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';
import sendSignupButtonPressedEvent from '../../../components/brand-safety/utils/sendSignupButtonPressedEvent';
import Sentry from '../../../services/sentry/Sentry';
import brandIntroStyles from '../brand-safety/styles/BrandIntro.module.scss';

const STEPS = {
  BRAND_INTRO: 1,
  EMAIL_CONFIRMATION: 2,
  OTP_VERIFICATION: 3,
} as const;

type Step = (typeof STEPS)[keyof typeof STEPS];

// TS knows that all cases are covered, but eslint doesn't, so no risk of forgetting a case
/* eslint-disable consistent-return */
function mapStepToScreenName(step: Step): string {
  switch (step) {
    case STEPS.BRAND_INTRO:
      return 'BrandIntro';
    case STEPS.EMAIL_CONFIRMATION:
      return 'EmailConfirmation';
    case STEPS.OTP_VERIFICATION:
      return 'OtpVerification';
  }
}
/* eslint-enable consistent-return */

function BrandSafetySignUpFlow() {
  useEffect(() => {
    const flow = DeltaHelper.createFlow(flowNames.brandsSafetyConnectPlatforms);
    const inviteCode = scInviteCode ?? 'in-platform';
    Delta.sendEvent(SIGNUP_FLOW_STARTED_SCHEMA.name, {
      flow_id: flow.flow_id,
      flow_name: flow.flow_name,
      source: `inviteCode: ${inviteCode}`,
    });
  }, []);
  const [searchParams] = useSearchParams();
  const {onSubmit} = useAuthenticate();
  const {setToken} = useToken();
  const navigation = useNavigation();

  const [currentStep, setCurrentStep] = useState<Step>(STEPS.BRAND_INTRO);
  const screenUUID = useRef<string | null>(null);
  useEffect(() => {
    const flow = DeltaHelper.getOrCreateFlow(
      flowNames.brandsSafetyConnectPlatforms
    );
    if (screenUUID.current) {
      Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
        flow_id: flow.flow_id,
        screen_presentation_id: screenUUID.current,
      });
    }
    const newScreenUUID = uuid();
    Delta.sendEvent(SIGNUP_SCREEN_PRESENTED_SCHEMA.name, {
      flow_id: flow.flow_id,
      screen_presentation_id: newScreenUUID,
      signup_screen_name: mapStepToScreenName(currentStep),
    });

    screenUUID.current = newScreenUUID;
  }, [currentStep]);

  useEffect(() => {
    return () => {
      const flow = DeltaHelper.getOrCreateFlow(
        flowNames.brandsSafetyConnectPlatforms
      );
      if (screenUUID.current) {
        Delta.sendEvent(SIGNUP_SCREEN_DISMISSED_SCHEMA.name, {
          flow_id: flow.flow_id,
          screen_presentation_id: screenUUID.current,
        });
      }
    };
  }, []);

  const [form, setForm] = useState({
    email: searchParams.get('email') || '',
    isTermsChecked: false,
  });
  const [formValidation, setFormValidation] = useState({
    email: false,
    isTermsChecked: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    isTermsChecked: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const brandName = searchParams.get('brand') || undefined;
  const scInviteCode = searchParams.get('sc_invite_code') || undefined;

  const updateForm = useCallback((field: string, value: any) => {
    setForm((prevForm) => ({...prevForm, [field]: value}));
    if (field === 'email') {
      setValidationErrors((prev) => ({...prev, email: ''}));
      setEmailErrorMessage('');
    }
  }, []);

  const validateForm = useCallback(() => {
    let isValid = true;
    if (!formValidation.email) {
      setEmailErrorMessage(translate('components.register-form.errors.format'));
      isValid = false;
    }
    if (!form.isTermsChecked) {
      setValidationErrors((prev) => ({
        ...prev,
        isTermsChecked: 'You must agree to the terms and privacy policy.',
      }));
      isValid = false;
    }
    return isValid;
  }, [formValidation.email, form.isTermsChecked]);

  const validateEmail = useCallback(() => {
    const isValid = emailValidator(form.email);
    setValidationErrors((prev) => ({
      ...prev,
      email: isValid ? '' : translate('components.register-form.errors.format'),
    }));
    setFormValidation((prev) => ({...prev, email: isValid}));
    return isValid;
  }, [form.email]);

  useEffect(() => {
    if (form.email) {
      validateEmail();
    }
  }, [form.email, validateEmail]);

  const handleJoinNow = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    const success = await onSubmit(
      'register',
      form.email,
      setEmailErrorMessage
    );
    setIsSubmitting(false);

    if (success) {
      setIsOtpModalOpen(true);
      setCurrentStep(STEPS.OTP_VERIFICATION);
    }
  };

  const handleOtpSuccess = async (otp: string) => {
    sendSignupButtonPressedEvent(
      screenUUID.current ?? 'N/A',
      mapStepToScreenName(currentStep),
      'Continue'
    );

    try {
      const {sessionData} = await authRequests.register({
        ...form,
        password: otp,
        sc_invite_code: scInviteCode,
      });
      const tokenSaved = await setToken(sessionData);
      if (!tokenSaved) throw new Error('Token not saved');

      const url = scInviteCode
        ? `/connect-platforms?sc_invite_code=${scInviteCode}&brand=${brandName}`
        : '/';

      navigation.navigate(url);
      setIsOtpModalOpen(false);
    } catch (error) {
      setEmailErrorMessage(
        translate('components.register-form.errors.default')
      );
      setIsOtpModalOpen(false);
    }
  };

  const setEmailValidation = useCallback((isValid: boolean) => {
    setFormValidation((prev) => ({...prev, email: isValid}));
  }, []);

  const setEmailValue = useCallback((value: string) => {
    setForm((prev) => ({...prev, email: value}));
    setEmailErrorMessage('');
  }, []);

  const emailValidators = [
    {
      validator: (email: string) => email !== '',
      errorMessage: translate('components.register-form.errors.empty'),
    },
    {
      validator: emailValidator,
      errorMessage: translate('components.register-form.errors.format'),
    },
  ];

  // ESLint doesn't understand that the switch statement is exhaustive
  /* eslint-disable consistent-return */
  const renderStepContent = (): JSX.Element => {
    const commonProps = {
      form,
      updateForm,
      validationErrors,
      isSubmitting,
      setCurrentStep,
      validateEmail,
    };

    const handleBack = (goToStep: Step) => {
      sendSignupButtonPressedEvent(
        screenUUID.current ?? 'N/A',
        mapStepToScreenName(currentStep),
        'Back'
      );
      setCurrentStep(goToStep);
    };

    const sendButtonPressedEvent = (buttonName: string) => {
      const screenName = mapStepToScreenName(STEPS.EMAIL_CONFIRMATION);
      if (!screenUUID.current) {
        Sentry.captureMessage(`screenUUID.current is null in ${screenName}`);
      }

      sendSignupButtonPressedEvent(
        screenUUID.current ?? 'N/A',
        screenName,
        `${screenName}-${buttonName}`
      );
    };

    switch (currentStep) {
      case STEPS.BRAND_INTRO: {
        const signupElement = (
          <Label size="lg" className={brandIntroStyles.signInLabel}>
            {translate(
              'components.register-form.brand-safety.already-have-account'
            )}{' '}
            &nbsp;
            <span
              onClick={() => {
                sendButtonPressedEvent('login');
                navigateWithAnalytics('/auth/login');
              }}
              className={brandIntroStyles.signInLink}
              data-testid="login-button"
              role="button"
              tabIndex={0}
            >
              {translate('components.register-form.brand-safety.login')}
            </span>
          </Label>
        );
        return (
          <BrandIntro
            brandName={brandName}
            mainButtonClick={() => {
              sendButtonPressedEvent('join');
              setCurrentStep(STEPS.EMAIL_CONFIRMATION);
            }}
            mainButtonText={translate(
              'components.register-form.brand-safety.join'
            )}
            secondaryElement={signupElement}
          />
        );
      }
      case STEPS.EMAIL_CONFIRMATION:
        return (
          <EmailConfirmation
            {...commonProps}
            handleJoinNow={handleJoinNow}
            handleBack={() => handleBack(STEPS.BRAND_INTRO)}
            setEmailValidation={setEmailValidation}
            emailValidators={emailValidators}
            emailErrorMessage={emailErrorMessage}
            setEmailValue={setEmailValue}
          />
        );
      case STEPS.OTP_VERIFICATION:
        return (
          <OtpVerification
            isOpen={isOtpModalOpen}
            setIsOpen={setIsOtpModalOpen}
            email={form.email}
            onSuccess={handleOtpSuccess}
            onGoBack={() => handleBack(STEPS.EMAIL_CONFIRMATION)}
          />
        );
    }
  };
  /* eslint-enable consistent-return */

  return <>{renderStepContent()}</>;
}

export default BrandSafetySignUpFlow;
