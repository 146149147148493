import {getEnv} from '@/config/environment';
import constructRedirectUrls from '@/utils/redirectUrlUtils';
import {navigateWithAnalytics} from '@/utils/wrappers/Delta';

export default async function authenticateYouTube(
  userId: string | null,
  redirectQueryParams: Record<string, string>
) {
  const authUrl = getYouTubeAuthUrl(
    window.location,
    userId,
    redirectQueryParams
  );
  await navigateWithAnalytics(authUrl);
}

const getYouTubeAuthUrl = (
  currentLocation: Location,
  userId: string | null,
  redirectQueryParams: Record<string, string>
): string => {
  const {successRedirectUrl, failureRedirectUrl} = constructRedirectUrls({
    provider: 'youtube',
    additionalParams: redirectQueryParams,
    pathname: currentLocation.pathname,
  });

  const serverCallbackUri = encodeURIComponent(
    getEnv().YOUTUBE_SERVER_CALLBACK_URI
  );
  const clientId = getEnv().YOUTUBE_CLIENT_ID;
  const scope = encodeURIComponent(
    'https://www.googleapis.com/auth/youtube.readonly'
  );

  const state = encodeURIComponent(
    JSON.stringify({
      success_redirect_url: successRedirectUrl,
      failure_redirect_url: failureRedirectUrl,
      user_id: userId,
    })
  );

  return `https://accounts.google.com/o/oauth2/auth?response_type=code&include_granted_scopes=true&access_type=offline&approval_prompt=force&redirect_uri=${serverCallbackUri}&scope=${scope}&client_id=${clientId}&state=${state}`;
};
