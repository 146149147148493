import React from 'react';
import {Label, Icon} from '@lightricks/react-design-system';
import MultiSelect from '../../../components/multi-select';
import styles from './GenderSelect.module.scss';
import {GenderOption} from '../OnboardingStore';
import {SelectItem} from './selectItem';
import translate from '@/utils/translate';

interface GenderItemProps {
  label: string;
  icon?: React.ReactNode;
}

interface GenderSelectProps {
  selectedGender: GenderOption | undefined;
  onGenderChange: (gender: GenderOption) => void;
}

export const GENDER_OPTIONS: SelectItem<GenderOption>[] = [
  {
    label: translate('Female'),
    image: <Icon name="Features-Hair" size="medium" appearance="neutral" />,
    value: 'Female',
  },
  {
    label: translate('Male'),
    image: (
      <Icon name="Features-Face-Main" size="medium" appearance="neutral" />
    ),
    value: 'Male',
  },
  {
    label: translate('Other'),
    image: <Icon name="Features-Profile" size="medium" appearance="neutral" />,
    value: 'Other',
  },
  {label: translate('Prefer not to say'), value: 'Prefer not to say'},
];

function GenderItem({label, icon}: GenderItemProps) {
  return (
    <div className={styles.genderItem}>
      {icon}
      <Label size="lg">{label}</Label>
    </div>
  );
}

export default function GenderSelect({
  selectedGender,
  onGenderChange,
}: GenderSelectProps) {
  const selectedItem = GENDER_OPTIONS.find(
    (option) => option.value === selectedGender
  );

  return (
    <MultiSelect
      items={GENDER_OPTIONS}
      selectedItems={selectedItem ? [selectedItem] : []}
      onSelectionChange={(items) => onGenderChange(items[0]?.value)}
      renderItem={({label, image}) => <GenderItem label={label} icon={image} />}
      singleSelect
    />
  );
}
