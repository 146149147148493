import React, {useEffect, useState} from 'react';
import {Route, Routes, matchPath, useLocation} from 'react-router-dom';
import ConnectPlatformsWizard from 'src/components/brand-safety/connect-platform-wizard/ConnectPlatformsWizard';
import OnboardingView from 'src/views/onboarding';
import useUserQuery from '../../hooks/queries/use-user-query';
import EmberIframe from '../../components/ember-iframe';
import Menu from '../../components/menu';

import styles from './MainRoutes.module.scss';
import Banner from '../../components/banner';
import Delta from '@/utils/wrappers/Delta';
import {useSaveUtmAttributionToSessionStorage} from '@/utils/utmAttributionTracker';

const STANDALONE_ROUTES = ['/onboarding/*'];

function MainRoutes() {
  const location = useLocation();
  const [emberModalIsOpen, setEmberModalIsOpen] = useState(false);
  const {user: userData, isLoading: isLoadingUser} = useUserQuery({});

  useSaveUtmAttributionToSessionStorage();

  useEffect(() => {
    const removeModalListener = EmberIframe.listen(
      'modal',
      setEmberModalIsOpen
    );
    return removeModalListener;
  }, []);

  useEffect(() => {
    if (userData?.id) {
      Delta.setCreatorId(userData.id);
    }
  }, [userData]);

  const showLayout = !STANDALONE_ROUTES.some((route: string) =>
    matchPath(route, location.pathname)
  );

  return (
    <>
      {showLayout && (
        <Banner
          emberModalIsOpen={emberModalIsOpen}
          userData={userData}
          isLoadingUser={isLoadingUser}
        />
      )}
      <div className={styles.appWrapper}>
        {showLayout && <Menu emberModalIsOpen={emberModalIsOpen} />}
        <Routes>
          <Route path="*" element={<EmberIframe />} />
          <Route
            path="/connect-platforms/*"
            element={<ConnectPlatformsWizard />}
          />
          <Route path="/onboarding/*" element={<OnboardingView />} />
          {/* <Route path="/new-page" element={<NewPage />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default MainRoutes;
