import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@/utils/reactQueryClient';
import profileUpdaters from '@/api/updaters/profile';

type UploadPortfolioMediaItemArgs = {
  onError?: (error: string) => void;
};

function useUploadPortfolioMediaItem({
  onError,
}: UploadPortfolioMediaItemArgs = {}) {
  return useMutation({
    mutationKey: ['uploadPortfolioMediaItem'],
    mutationFn: profileUpdaters.profilePortfolioMediaItem,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['profile']});
    },
    onError,
  });
}

export default useUploadPortfolioMediaItem;
