export const SUPPORT_LINK =
  'https://help-popularpays.lightricks.com/hc/en-us/categories/24969753771282-Creator-Experience';

export const ERRORS = {
  USER_SUSPENDED: 'USER_SUSPENDED',
};

export const DEPRECATED_NETWORK_IDS = ['tiktok', 'twitter'];

export default {};
