import {stepsUuidMapGenerator} from '@/utils/stepsUuidMapGenerator';

export const STEPS = {
  NAME_AND_BIRTHDATE: 'name-and-birthdate',
  CREATOR_SEGMENT: 'creator-segment',
  LOCATION: 'location',
  GENDER: 'gender',
  SOCIAL_APPS: 'social-apps',
  BEST_COLLABS: 'best-collabs',
  SPECIALTIES: 'specialties',
  CAPABILITIES: 'capabilities',
  SYNC_PLATFORMS: 'sync-platforms',
  PORTFOLIO: 'portfolio',
  BIO: 'bio',
  COMPLETION: 'completion',
  INSTAGRAM_INSTRUCTIONS: 'instagram-instructions',
  REONBOARDING: 'reonboarding',
} as const;

export type Step = (typeof STEPS)[keyof typeof STEPS];

export const ONBOARDING_STEPS_UUID_MAP = stepsUuidMapGenerator<Step>(STEPS);

export const DEFAULT_STEP_ORDER: Step[] = [
  STEPS.NAME_AND_BIRTHDATE,
  STEPS.CREATOR_SEGMENT,
  STEPS.LOCATION,
  STEPS.GENDER,
  STEPS.SOCIAL_APPS,
  STEPS.BEST_COLLABS,
  STEPS.SPECIALTIES,
  STEPS.CAPABILITIES,
  STEPS.SYNC_PLATFORMS,
  STEPS.PORTFOLIO,
  STEPS.BIO,
  STEPS.COMPLETION,
];
