import {FilterPopupParameterOptions} from './filters-popup-canvas/FilterPopupParametersType';

export interface ParamSpecType {
  paramDataFrom: keyof FilterPopupParameterOptions;
  paramKey: string;
  paramType: string;
}
const queryParamsSpecs: ParamSpecType[] = [
  {
    paramDataFrom: 'deliverables',
    paramKey: 'deliverable_type',
    paramType: 'object',
  },
  {
    paramDataFrom: 'socialNetworks',
    paramKey: 'network',
    paramType: 'array',
  },
  {
    paramDataFrom: 'socialFollowersRange',
    paramKey: 'followers_min,followers_max',
    paramType: 'range',
  },
  {
    paramDataFrom: 'compensationType',
    paramKey: 'compensation_type',
    paramType: 'object',
  },
  {
    paramDataFrom: 'compensationSum',
    paramKey: 'price_min,price_max',
    paramType: 'range',
  },
  {
    paramDataFrom: 'tags',
    paramKey: 'tags',
    paramType: 'array',
  },
];

export default queryParamsSpecs;
