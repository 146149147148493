import React from 'react';
import {useShallow} from 'zustand/shallow';
import {designSystemToken} from '@lightricks/react-design-system';
import assertUnreachable from '@/utils/assertUnreachable';
import {STEPS} from './onboardingSteps';
import {useOnboardingStore} from './OnboardingStore';

function useStepBackgroundColor(): string {
  const step = useOnboardingStore(useShallow((state) => state.step));

  return React.useMemo(() => {
    switch (step) {
      case STEPS.NAME_AND_BIRTHDATE:
        return '#ED99A9';
      case STEPS.CREATOR_SEGMENT:
        return '#9B7ACA';
      case STEPS.LOCATION:
        return '#9499E6';
      case STEPS.GENDER:
        return '#89CCD9';
      case STEPS.SOCIAL_APPS:
        return '#83BBC7';
      case STEPS.BEST_COLLABS:
      case STEPS.REONBOARDING:
        return '#90C4B8';
      case STEPS.SPECIALTIES:
        return '#B5CBB7';
      case STEPS.CAPABILITIES:
        return '#C1DAC0';
      case STEPS.SYNC_PLATFORMS:
      case STEPS.INSTAGRAM_INSTRUCTIONS:
        return '#EACEB2';
      case STEPS.PORTFOLIO:
        return '#B69F92';
      case STEPS.BIO:
        return '#D2C8C0';
      case STEPS.COMPLETION:
        return designSystemToken('semantic.bg.tertiary');
      default:
        return assertUnreachable(step);
    }
  }, [step]);
}
export default useStepBackgroundColor;
