import {createAsyncStoragePersister} from '@tanstack/query-async-storage-persister';
import {QueryClient} from '@tanstack/react-query';
import Storage from './wrappers/Storage';
import {getEnv} from '../config/environment';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      select: (result: any) => result,
      retry: getEnv().MODE === 'test' ? false : 3,
    },
  },
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: Storage,
  serialize: JSON.stringify,
  deserialize: (data) => (typeof data === 'string' ? JSON.parse(data) : data),
});

export {queryClient, asyncStoragePersister};
