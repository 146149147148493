import {getEnv} from '@/config/environment';

interface RedirectUrlOptions {
  provider: string;
  additionalParams?: Record<string, string>;
  pathname?: string;
}

export default function constructRedirectUrls(options: RedirectUrlOptions) {
  const {
    provider,
    additionalParams = {},
    pathname = window.location.pathname,
  } = options;

  const urlParams = new URLSearchParams();
  Object.entries(additionalParams).forEach(([key, value]) => {
    urlParams.set(key, value);
  });
  urlParams.set('provider', provider);

  const redirectUrlBase = `${getEnv().DOMAIN_HOST}${pathname}`;
  const queryString = `?${urlParams.toString()}`;

  const baseCallbackUrl = redirectUrlBase.endsWith('/callback')
    ? `${redirectUrlBase}${queryString}`
    : `${redirectUrlBase}/callback${queryString}`;

  const successRedirectUrl = `${baseCallbackUrl}&success=true`;
  const failureRedirectUrl = `${baseCallbackUrl}&success=false`;

  return {
    successRedirectUrl,
    failureRedirectUrl,
  };
}
