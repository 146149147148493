import {snakeCase} from 'lodash';
import mapKeysToCase from '@/utils/mapKeysToCase';
import api from '../index';

type ProfileFormData = Partial<{
  contentCreator: boolean;
  influencer: boolean;
  locale: {
    id: string;
    longitude: number;
    latitude: number;
    city: string;
    state: string;
    country: string;
  };
  contentPreferences: Array<{id: string; kind: string}>;
  gender: string | null;
  bio: string;
  portfolioLink: string;
  tags: string[];
  s3ImageUrl: string;
}>;

type ContentPreferenceIncluded = {
  attributes: {kind: string};
  type: 'creator_profile_content_preferences';
  id: string;
};

type LocaleIncluded = {
  type: 'locales';
  id: string;
  attributes: {
    api_uid: string;
    longitude: number;
    latitude: number;
    city: string;
    state: string;
    country: string;
  };
};

type PortfolioMediaItemFormData = {
  kind: 'photo' | 'video';
  sourceUrl: string;
};

const profileUpdaters = {
  profile: async (formData: ProfileFormData) => {
    const payload = {
      ...formData,
      tags: formData.tags?.map((id) => ({type: 'tags', id})),
    };

    const included: (ContentPreferenceIncluded | LocaleIncluded)[] = [];

    if (formData.contentPreferences?.length) {
      const contentPreferenceIncludes = formData.contentPreferences.map(
        (contentPreference: {kind: string; id: string}) => ({
          attributes: {kind: contentPreference.kind},
          type: 'creator_profile_content_preferences' as const,
          id: contentPreference.id,
        })
      );
      included.push(...contentPreferenceIncludes);
    }

    if (formData.locale) {
      const localeInclude: LocaleIncluded = {
        type: 'locales',
        id: formData.locale.id,
        attributes: {...formData.locale, api_uid: formData.locale.id},
      };
      included.push(localeInclude);
    }

    const config = {
      customSerializer: (data: any) => ({
        ...data,
        included: included.length
          ? mapKeysToCase(included, snakeCase)
          : undefined,
      }),
    };

    return api.put('/user/profile', payload, {
      ...config,
      params: {
        include: [
          'content_preferences',
          'profile_progress_report',
          'tags',
          'locale',
        ],
      },
    });
  },

  profilePortfolioMediaItem: async (formData: PortfolioMediaItemFormData) => {
    return api.post('/user/profile/portfolio_media_items', formData);
  },

  completeOnboarding: async () => {
    return api.post('/user/profile/complete_onboarding', {});
  },
};

export default profileUpdaters;
