import React, {useState} from 'react';
import {useShallow} from 'zustand/shallow';
import {debounce} from 'lodash';
import {Input, Icon} from '@lightricks/react-design-system';
import isValidUrl from '@/utils/validators/url';
import {useOnboardingStore} from '@/views/onboarding/OnboardingStore';

function PortfolioLink() {
  type IconAppearance = 'secondary' | 'neutral';

  const [portfolioLink, setPortfolioLink] = useOnboardingStore(
    useShallow((state) => [state.portfolioLink, state.setPortfolioLink])
  );
  const [startIconAppearance, setStartIconAppearance] =
    useState<IconAppearance>('secondary');
  const [endIconName, setEndIconName] = useState(
    portfolioLink
      ? ('Actions-Patch-Accept-Fill' as const)
      : ('Actions-Patch-Accept-Line' as const)
  );
  const [endIconAppearance, setEndIconAppearance] = useState(
    portfolioLink ? ('brand' as const) : ('disabled' as const)
  );

  const onChange = debounce((url: string) => {
    if (isValidUrl(url)) {
      setEndIconName('Actions-Patch-Accept-Fill' as const);
      setEndIconAppearance('brand' as const);
      setPortfolioLink(url);
    } else {
      setEndIconName('Actions-Patch-Accept-Line' as const);
      setEndIconAppearance('disabled' as const);
    }
  }, 1000);

  return (
    <div>
      <Input
        value={portfolioLink}
        onInputChange={onChange}
        onInputBlur={() => {
          setStartIconAppearance('secondary');
        }}
        onFocus={() => {
          setStartIconAppearance('neutral');
        }}
        placeholder="www.yourname.com"
        startIcon={
          <Icon
            name="Navigation-Link"
            size="medium"
            appearance={startIconAppearance}
          />
        }
        endIcon={
          <Icon
            name={endIconName}
            size="medium"
            appearance={endIconAppearance}
          />
        }
        sx={{
          '& .MuiInputBase-root': {
            'borderRadius': '16px',
            'marginBottom': '0',
            '& input': {
              textAlign: 'center',
            },
          },
        }}
      />
    </div>
  );
}

export default PortfolioLink;
