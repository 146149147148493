import {useEffect, useMemo} from 'react';
import {useShallow} from 'zustand/shallow';
import {z} from 'zod';
import useUserQuery from '@/hooks/queries/use-user-query';
import {STEPS, DEFAULT_STEP_ORDER} from './onboardingSteps';
import {useUserData} from '@/hooks/useUserData';
import {UserSchema} from '@/schema/User';
import Sentry from '@/services/sentry/Sentry';
import type {PortfolioMediaItem} from '@/types/portfolioMediaItem';
import {useOnboardingStore, type GenderOption} from './OnboardingStore';
import {isStepValid} from './useStepValidation';

function handleUserParseError(error: unknown) {
  if (error instanceof z.ZodError) {
    Sentry.captureException(error, {
      title: 'Onboarding User Parse Error',
    });
  } else {
    Sentry.captureException(error as any, {
      title: 'Unexpected Onboarding Parse Error',
    });
  }
}

function useStepOrder() {
  const {user} = useUserQuery({
    include: ['profile.portfolio_media_items'],
  });

  const {connectedPlatforms: rawConnectedPlatforms} = useUserData({});

  // Memoize connectedPlatforms to prevent unnecessary effect reruns
  const connectedPlatforms = useMemo(
    () => rawConnectedPlatforms,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(rawConnectedPlatforms)]
  );

  // Only subscribe to hydration-related state and step order state
  const {hydrate, isHydrated, isStepOrderPersisted, setStepOrder, setStep} =
    useOnboardingStore(
      useShallow((state) => ({
        hydrate: state.hydrate,
        isHydrated: state.isHydrated,
        isStepOrderPersisted: state.isStepOrderPersisted,
        setStepOrder: state.setStepOrder,
        setStep: state.setStep,
      }))
    );

  // Handle initial hydration from user data and calculate step order
  useEffect(() => {
    if (!user || isHydrated) return;

    let validUser;
    try {
      validUser = UserSchema.parse(user);
    } catch (e) {
      handleUserParseError(e);
    }

    if (!validUser) return;

    let segment;
    if (validUser.profile?.influencer) {
      segment = 'influencer' as const;
    } else if (validUser.profile?.contentCreator) {
      segment = 'creator' as const;
    }

    let gender: GenderOption | undefined;
    if (validUser.profile?.gender === 'Male') {
      gender = 'Male';
    } else if (validUser.profile?.gender === 'Female') {
      gender = 'Female';
    } else if (validUser.profile?.gender === 'Prefer not to say') {
      gender = 'Prefer not to say';
    } else if (typeof validUser.profile?.gender === 'string') {
      gender = 'Other';
    }

    const profilePictureUrl: string | undefined = (validUser.profile
      ?.imageUrl ||
      validUser.identities?.find((identity) => identity.cachedProfileImageUrl)
        ?.cachedProfileImageUrl) as string | undefined;

    hydrate({
      fullName: validUser.firstName
        ? [validUser.firstName, validUser.lastName].join(' ')
        : undefined,
      birthdate: validUser.birthDate
        ? new Date(validUser.birthDate)
        : undefined,
      segment,
      gender,
      specialties: new Set(validUser.profile?.tags.map((tag) => tag.name)),
      capabilities: new Set(
        validUser.profile?.contentPreferences?.map(
          (capability) => capability.kind
        )
      ),
      bio: validUser.profile?.bio ? validUser.profile?.bio : undefined,
      profilePictureUrl,
      portfolioLink: validUser.profile?.portfolioLink ?? undefined,
      portfolioMediaItems: validUser.profile?.portfolioMediaItems
        .filter((item) => item.state !== 'processing')
        .map(
          (item) =>
            ({
              type: item.kind,
              url: item.url,
            } as PortfolioMediaItem)
        ),
      location: validUser.profile?.location,
      locale: validUser.profile?.locale,
    });
  }, [user, isHydrated, hydrate]);

  // Calculate step order once after hydration
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAllSteps = urlParams.get('allSteps') === 'true';

    // If we have a persisted step order and we're not forcing all steps, do nothing
    if (isStepOrderPersisted && !isAllSteps) {
      return;
    }

    const state = useOnboardingStore.getState();
    const {step} = state;

    // If not hydrated yet or forcing all steps, use the default order
    if (!isHydrated || isAllSteps) {
      setStepOrder(DEFAULT_STEP_ORDER);
      if (!DEFAULT_STEP_ORDER.includes(step)) {
        setStep(DEFAULT_STEP_ORDER[0]);
      }
      return;
    }

    // Otherwise, calculate the step order based on completed steps
    const incompleteSteps = DEFAULT_STEP_ORDER.filter(
      (currentStep) => !isStepValid(currentStep, state, connectedPlatforms)
    );

    const newStepOrder = [...incompleteSteps, STEPS.COMPLETION];
    setStepOrder(newStepOrder);
    if (!newStepOrder.includes(step)) {
      setStep(newStepOrder[0]);
    }
  }, [
    isHydrated,
    connectedPlatforms,
    isStepOrderPersisted,
    setStepOrder,
    setStep,
  ]);
}

export default useStepOrder;
