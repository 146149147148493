import {navigateWithAnalytics} from '@/utils/wrappers/Delta';
import {getEnv} from '../../config/environment';
import constructRedirectUrls from '@/utils/redirectUrlUtils';

interface StateData {
  success_redirect_url: string;
  failure_redirect_url: string;
  user_id: string | null;
}

export default async function authenticateTiktok(
  userId: string | null,
  redirectQueryParams: Record<string, string>
) {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const stateParam = urlParams.get('state');

  if (code && stateParam) {
    try {
      const decodedState = JSON.parse(
        decodeURIComponent(stateParam)
      ) as StateData;
      const redirectUrl = getTiktokPageSelectionUri(
        decodedState.user_id,
        code,
        window.location,
        redirectQueryParams
      );
      await navigateWithAnalytics(redirectUrl);
    } catch (error) {
      console.error('Error handling TikTok redirect:', error);
    }
  }

  const authUrl = getTiktokAuthUrl(
    window.location,
    userId,
    redirectQueryParams
  );
  await navigateWithAnalytics(authUrl);
}

const getTiktokAuthUrl = (
  currentLocation: Location,
  userId: string | null,
  redirectQueryParams: Record<string, string>
) => {
  const {successRedirectUrl, failureRedirectUrl} = constructRedirectUrls({
    provider: 'tiktok_creator_marketplace',
    additionalParams: redirectQueryParams,
    pathname: currentLocation.pathname,
  });

  const state = JSON.stringify({
    success_redirect_url: successRedirectUrl,
    failure_redirect_url: failureRedirectUrl,
    user_id: userId,
  });

  const authUrl = new URL('https://www.tiktok.com/v2/auth/authorize');
  authUrl.searchParams.set('client_key', getEnv().TIKTOK_APP_ID);
  authUrl.searchParams.set(
    'scope',
    'user.info.basic,user.insights.creator,biz.creator.info,biz.creator.insights,video.list,tcm.order.update'
  );
  authUrl.searchParams.set('response_type', 'code');
  authUrl.searchParams.set(
    'redirect_uri',
    getEnv().TIKTOK_CREATOR_MARKETPLACE_REDIRECT_URL
  );
  authUrl.searchParams.set('rid', 'hrka4fo7yu9');
  authUrl.searchParams.set('state', state);

  return authUrl.toString();
};

const getTiktokPageSelectionUri = (
  userId: string | null,
  accessToken: string,
  currentLocation: Location,
  redirectQueryParams: Record<string, string>
) => {
  const additionalParams = {
    ...redirectQueryParams,
    redirectPath: currentLocation.pathname,
  };

  const {successRedirectUrl, failureRedirectUrl} = constructRedirectUrls({
    provider: 'tiktok_creator_marketplace',
    additionalParams,
    pathname: currentLocation.pathname,
  });

  const url = new URL(
    `${
      getEnv().SWAPS_BASE_URI
    }/social-verification/instagram-business-accounts/${userId}`
  );
  url.searchParams.set('access_token', accessToken);
  url.searchParams.set('success_redirect_url', successRedirectUrl);
  url.searchParams.set('failure_redirect_url', failureRedirectUrl);

  return url.toString();
};

export {getTiktokAuthUrl, getTiktokPageSelectionUri};
