import React, {useState} from 'react';
import {Button, Headline, Body} from '@lightricks/react-design-system';
import DateInputWithPicker from 'src/components/DateInputWithPicker/DateInputWithPicker';
import translate from '@/utils/translate';
import styles from './BirthdayInput.module.scss';

type BirthdayInputProps = {
  onSubmit: (date: Date) => void;
  isLoading: boolean;
  errorMessage: string | null;
};

function BirthdayInput({
  onSubmit,
  isLoading,
  errorMessage,
}: BirthdayInputProps) {
  const [birthdate, setBirthdate] = useState<Date | undefined>(undefined);
  const [error, setError] = useState(errorMessage);

  const validateDate = (date: Date) => {
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(today.getFullYear() - 100);
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() - 13);

    if (date > maxDate) {
      setError(
        translate('components.brand-safety.sign-up.birthday-input.age-error')
      );
      return false;
    }
    if (date < minDate) {
      setError(
        translate(
          'components.brand-safety.sign-up.birthday-input.valid-date-error'
        )
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!birthdate) {
      setError(
        translate('components.brand-safety.sign-up.birthday-input.date-error')
      );
      return;
    }

    if (validateDate(birthdate)) {
      setError('');
      onSubmit(birthdate);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <Headline size="lg" className={styles.headline}>
            {translate(
              'components.brand-safety.sign-up.birthday-input.headline'
            )}
          </Headline>

          <Body size="md" className={styles.body}>
            {translate(
              'components.brand-safety.sign-up.birthday-input.description'
            )}
          </Body>

          <div className={styles.inputContainer}>
            <DateInputWithPicker
              label={translate(
                'components.brand-safety.sign-up.birthday-input.date-label'
              )}
              date={birthdate}
              errorMessage={error ?? undefined}
              onChange={(date) => setBirthdate(date ?? undefined)}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={handleSubmit}
              disabled={!birthdate || isLoading}
              appearance="neutral"
              size="large"
              mode="filled"
              className={styles.nextButton}
            >
              {translate('components.brand-safety.sign-up.birthday-input.next')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BirthdayInput;
