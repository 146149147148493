export const PLATFORM_TYPES = [
  'Instagram',
  'TikTok',
  'YouTube',
  'Facebook',
  'Email',
] as const;

export const VETTABLE_PLATFORMS = PLATFORM_TYPES.filter((platform) =>
  ['Instagram', 'TikTok'].includes(platform)
);

export const SOCIAL_PLATFORMS = PLATFORM_TYPES.filter(
  (platform) => platform !== 'Email'
);

export type Platform = (typeof PLATFORM_TYPES)[number];
export type SocialPlatform = (typeof SOCIAL_PLATFORMS)[number];

export const isVettablePlatform = (platform: Platform): boolean =>
  VETTABLE_PLATFORMS.includes(platform);

export function calculateVettablePlatformsCompletion(
  connectedPlatforms: Platform[],
  selectedPlatforms: Platform[]
): number {
  if (selectedPlatforms.length === 0) {
    return 0;
  }
  if (!selectedPlatforms.some((platform) => isVettablePlatform(platform))) {
    return 100;
  }

  const vettableConnected = connectedPlatforms.filter((platform) =>
    isVettablePlatform(platform)
  );
  const vettableSelected = selectedPlatforms.filter((platform) =>
    isVettablePlatform(platform)
  );

  const vettableSet = new Set([...vettableConnected, ...vettableSelected]);

  return vettableSelected.length
    ? (vettableConnected.length / vettableSet.size) * 100
    : 0;
}
