import {
  Checkbox as CheckboxMui,
  FormControl,
  FormControlLabel,
  FormHelperText,
  styled,
} from '@mui/material';
import React from 'react';
import {designSystemToken} from '@lightricks/react-design-system';

import {FiltersCheckboxChecked, FiltersCheckboxUnChecked} from '../icons';
import {CheckboxProps} from './CheckboxProps';

// TODO: change color codes to design system tokens
const StyledFormControlLabel = styled(FormControlLabel)`
  color: #404040;
  font-size: 16px;

  ${(props) => props.theme.breakpoints.down('mobile')} {
    margin-left: 0;
    width: 100%;
    display: flex;
  }
`;

const StyledFormHelperText = styled(FormHelperText)`
  margin-top: 0;
  height: 40px;
  margin-right: 0;
  margin-left: 0;
  color: ${() => designSystemToken('semantic.bg.danger')};

  ${(props) => props.theme.breakpoints.down('mobile')} {
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
  }
`;

function Checkbox(props: CheckboxProps) {
  const {label, checked, onChange, error, ...rest} = props;

  return (
    <FormControl>
      <StyledFormControlLabel
        control={
          <CheckboxMui
            {...rest}
            disableRipple
            icon={<FiltersCheckboxUnChecked />}
            checkedIcon={<FiltersCheckboxChecked />}
            onChange={onChange}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 25,
              },
              '&.Mui-checked': {
                color: '#000000',
              },
            }}
          />
        }
        label={label}
        checked={checked}
      />
      <StyledFormHelperText>{error}</StyledFormHelperText>
    </FormControl>
  );
}

export default Checkbox;
