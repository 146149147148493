/* eslint-disable no-param-reassign */
import {create} from 'zustand';
import {produce} from 'immer';
import {Platform, SOCIAL_PLATFORMS, SocialPlatform} from '@/types/platforms';
import {User, ConnectedPlatformsState} from '@/types/user';
import {Step, STEPS} from '../wizardSteps';
import {sendSignUpScreenEvent} from '@/hooks/use-wizard-signup-events/useWizardSignupEvents';
import {CONNECT_PLATFORMS_STEPS_UUID_MAP} from '@/components/brand-safety/constants/constants';
import {flowNames} from '@/lib/delta/deltaConstants';

type ConnectPlatformsState = ConnectedPlatformsState & {
  step: Step;
  previousStep: Step;
  selectedPlatforms: SocialPlatform[];
  errorMessage?: string;
};

type ConnectPlatformsActions = {
  setStep: (step: Step) => void;
  setUser: (user: User) => void;
  setSelectedPlatforms: (platforms: SocialPlatform[]) => void;
  setConnectedPlatforms: (platforms: Platform[]) => void;
  setError: (error?: string) => void;
  setAvatarUrl: (avatarUrl?: string) => void;
  setDistinctConnectedPlatforms: (platforms: Platform[]) => void;
  setBrandName: (brandName?: string) => void;
};

const getScreenEventPresentedDetails = (step: Step) => {
  const presentedDetails: any = {};

  if (step === STEPS.SELECT_PLATFORMS) {
    presentedDetails.selectedPlatforms =
      useConnectPlatformsStore.getState().selectedPlatforms;
    presentedDetails.connectedPlatforms =
      useConnectPlatformsStore.getState().connectedPlatforms;
    presentedDetails.SOCIAL_PLATFORMS = SOCIAL_PLATFORMS;
  }

  return presentedDetails;
};

export const sendSignUpScreenPresentedEvent = (step: Step) => {
  const flowName = flowNames.brandsSafetyConnectPlatforms;
  const presentedDetails = getScreenEventPresentedDetails(step);
  sendSignUpScreenEvent(
    flowName,
    step,
    'presented',
    CONNECT_PLATFORMS_STEPS_UUID_MAP[step],
    presentedDetails
  );
};

export const sendSignUpScreenDismissedEvent = (step: Step) => {
  const flowName = flowNames.brandsSafetyConnectPlatforms;
  const dismissedDetails = getScreenEventPresentedDetails(step);
  sendSignUpScreenEvent(
    flowName,
    step,
    'dismissed',
    CONNECT_PLATFORMS_STEPS_UUID_MAP[step],
    dismissedDetails
  );
};

export const useConnectPlatformsStore = create<
  ConnectPlatformsState & ConnectPlatformsActions
>((set) => ({
  step: STEPS.SELECT_PLATFORMS,
  previousStep: STEPS.SELECT_PLATFORMS,
  selectedPlatforms: [],
  connectedPlatforms: [],
  distinctConnectedPlatforms: [],
  setStep: (newStep: Step) =>
    set((state) => {
      if (state.step === newStep) {
        return state;
      }

      sendSignUpScreenDismissedEvent(state.step);
      sendSignUpScreenPresentedEvent(newStep);

      return produce(state, (draft) => {
        draft.previousStep = state.step;
        draft.step = newStep;
      });
    }),
  setUser: (user) =>
    set(
      produce((state) => {
        state.user = user;
      })
    ),
  setSelectedPlatforms: (platforms) =>
    set(
      produce((state) => {
        state.selectedPlatforms = platforms;
      })
    ),
  setConnectedPlatforms: (platforms) =>
    set(
      produce((state) => {
        state.connectedPlatforms = platforms;
        const distinctConnectedPlatforms = platforms.filter(
          (platform): platform is NonNullable<SocialPlatform> =>
            platform !== 'Facebook' && platforms.includes(platform)
        );
        state.distinctConnectedPlatforms = distinctConnectedPlatforms;
      })
    ),
  setDistinctConnectedPlatforms: (platforms) =>
    set(
      produce((state) => {
        state.distinctConnectedPlatforms = platforms;
      })
    ),
  setError: (error) =>
    set(
      produce((state) => {
        state.errorMessage = error;
      })
    ),
  setAvatarUrl: (avatarUrl) =>
    set(
      produce((state) => {
        state.avatarUrl = avatarUrl;
      })
    ),
  setBrandName: (brandName) =>
    set(
      produce((state) => {
        state.brandName = brandName;
      })
    ),
}));
