import {v4 as uuid} from 'uuid';
import Delta from '@/utils/wrappers/Delta';
import momentSecondsSince from '@/utils/momentSecondsSince';
import {
  DELTA_SCREEN_ROUTE_NAME_MAPPER,
  deltaClientEvents,
} from '../../lib/delta/deltaConstants';

const DEFAULTS = {
  ACTIVE_SCREEN_PRESENTED: () => ({
    screen_presentation_id: uuid(),
    presented_at_timestamp: new Date(),
    source: '',
    tab: '',
    trigger_flow_id: '',
    trigger_flow_name: '',
  }),
  FLOW: () => ({
    flow_id: uuid(),
    flow_name: '',
    reason: '',
    result: '',
    error: '',
    campaign_id: '',
    creator_id: '',
    screen_presentation_id: '',
    source: '',
  }),
};

export type ActiveScreenPresented = {
  screen_presentation_id: string;
  presented_at_timestamp?: Date;
  source?: string;
  tab?: string;
  trigger_flow_id?: string;
  trigger_flow_name?: string;
  screen_name?: string;
};

export type Flow = {
  flow_id: string;
  flow_name: string;
  reason?: string;
  result?: string;
  error?: string;
  campaign_id?: string;
  creator_id?: string;
  screen_presentation_id: string;
  source?: string;
};

class DeltaHelper {
  activeScreenPresented: ActiveScreenPresented | null = null;

  flows: {[key: string]: Flow} = {};

  /**
   * maps routeName to a { screen_name, tab } object
   * returns screen_name as the given routeName and an empty string tab if no mapping found
   * @param routeName
   * @returns {*|{tab: string, screen_name}}
   */
  static getScreenNameAndTab(routeName: string): {
    screen_name: string;
    tab: string;
  } {
    return (
      DELTA_SCREEN_ROUTE_NAME_MAPPER[routeName] || {
        screen_name: routeName,
        tab: '',
      }
    );
  }

  /**
   * creates an activeScreenPresented object and sends a screen_presented event to delta
   * @param routeName - given route name
   * @param source - previous page source
   * @param data - additional data to pass to the event (overrides default data)
   * @returns {Promise<void>}
   */
  screenPresented = async (routeName: string, source: string, data = {}) => {
    const screenNameAndTab = DeltaHelper.getScreenNameAndTab(routeName);

    const eventData = {
      screen_presentation_id: uuid(),
      ...screenNameAndTab,
      source: source || '',
      trigger_flow_id: '',
      trigger_flow_name: '',
      ...data,
    };

    Delta.sendEvent(deltaClientEvents.generic.screen_presented, {
      ...eventData,
    });

    this.activeScreenPresented = {
      ...eventData,
      presented_at_timestamp: new Date(),
    };
  };

  /**
   * sends a screen_dismissed event to delta using the activeScreenPresented
   * @param routeName - given route name
   * @param destination - next page destination
   * @param data - additional data to pass to the event (overrides default data)
   * @returns {Promise<void>}
   */
  screenDismissed = async (
    routeName: string,
    destination: string,
    data = {}
  ) => {
    if (!this.activeScreenPresented) {
      return;
    }

    const screenNameAndTab = DeltaHelper.getScreenNameAndTab(routeName);

    const {screen_presentation_id, presented_at_timestamp} =
      this.activeScreenPresented;

    Delta.sendEvent(deltaClientEvents.generic.screen_dismissed, {
      screen_presentation_id,
      screen_name:
        this.activeScreenPresented.screen_name || screenNameAndTab.screen_name,
      tab: screenNameAndTab.tab,
      session_duration_since_presented: presented_at_timestamp
        ? momentSecondsSince(presented_at_timestamp).toString()
        : '',
      destination: destination || '',
      ...data,
    });
  };

  /**
   * returns the active screen
   * @returns {{trigger_flow_name: string, screen_presentation_id: *, tab: string, presented_at_timestamp: Date, source: string, trigger_flow_id: string}}
   */
  getActiveScreenPresented = () => {
    return this.activeScreenPresented || DEFAULTS.ACTIVE_SCREEN_PRESENTED();
  };

  /**
   * creates a new flow and stores it in-memory
   * @param flowName - name of the flow which also serves as an identifier for the flow in the flows object
   * @param data - additional data to pass to the flow (overrides default data)
   * @returns {*}
   */
  createFlow = (flowName: string, data = {}) => {
    this.flows[flowName] = {
      ...DEFAULTS.FLOW(),
      screen_presentation_id:
        this.getActiveScreenPresented().screen_presentation_id,
      flow_name: flowName,
      ...data,
    };

    return this.flows[flowName];
  };

  /**
   * returns a flow
   * if the flow does not exist, it will be created and returned
   * @param flowName
   */
  getOrCreateFlow = (flowName: string) => {
    return this.getFlow(flowName) || this.createFlow(flowName);
  };

  /**
   * returns a flow
   * @param flowName
   * @returns {*}
   */
  getFlow = (flowName: string) => {
    return this.flows[flowName];
  };

  /**
   *  return base attributes for GENERIC.BUTTON_PRESSED event that is triggering a flow
   * @param flowName
   * @returns {{screen_presentation_id: string, triggered_flow_id: string, creator_id: string, campaign_id: string, triggered_flow_name: string}}
   */
  getButtonPressedBaseAttributesFromFlow = (flowName: string) => {
    const flow = this.getOrCreateFlow(flowName);
    return {
      triggered_flow_id: flow.flow_id,
      triggered_flow_name: flow.flow_name,
      campaign_id: flow.campaign_id,
      creator_id: flow.creator_id,
      screen_presentation_id: flow.screen_presentation_id,
    };
  };

  /**
   * updates a flow with new given data
   * @param flowName
   * @param data - data to pass to the flow (overrides previous data)
   * @returns {*}
   */
  updateFlow = (flowName: string, data = {}) => {
    const flow = this.getOrCreateFlow(flowName);
    this.flows[flowName] = {
      ...flow,
      ...data,
    };

    return this.flows[flowName];
  };

  /**
   * check if a flow exists in-memory
   * @param flowName
   * @returns {boolean}
   */
  flowExists = (flowName: string) => {
    return !!this.flows[flowName];
  };

  /**
   * deletes flow from memory
   * @param flowName
   */
  deleteFlow = (flowName: string) => {
    delete this.flows[flowName];
  };

  /**
   * sends a flow_started event constructed from an in-memory flow
   * @param flowName
   * @param data - additional data to pass to the event (overrides default data)
   */
  startFlow = (flowName: string, data = {}) => {
    const flow = this.getOrCreateFlow(flowName);
    Delta.sendEvent(deltaClientEvents.generic.flow_started, {
      flow_id: flow.flow_id,
      flow_name: flow.flow_name || '',
      reason: flow.reason || '',
      campaign_id: flow.campaign_id || '',
      creator_id: flow.creator_id || '',
      screen_presentation_id: flow.screen_presentation_id || '',
      source: flow.source || '',
      ...data,
    });
  };

  /**
   * sends a flow_ended event constructed from an in-memory flow
   * @param flowName
   * @param data - additional data to pass to the event (overrides default data)
   * @param deleteFlowAfterSending - whether or not to delete the flow from the memory after sending - defaults to true
   */
  endFlow = (flowName: string, data = {}, deleteFlowAfterSending = true) => {
    const flow = this.getOrCreateFlow(flowName);
    Delta.sendEvent(deltaClientEvents.generic.flow_ended, {
      flow_id: flow.flow_id,
      flow_name: flow.flow_name || '',
      result: flow.result || '',
      error: flow.error || '',
      campaign_id: flow.campaign_id || '',
      creator_id: flow.creator_id || '',
      screen_presentation_id: flow.screen_presentation_id || '',
      source: flow.source || '',
      ...data,
    });

    if (deleteFlowAfterSending) {
      this.deleteFlow(flowName);
    }
  };
}

export default new DeltaHelper();
