import React, {useState} from 'react';
import {Button, Headline} from '@lightricks/react-design-system';
import styles from './VettingOptOut.module.scss';
import useParams from '@/hooks/use-params';
import useVettingOptOut from '@/hooks/queries/mutations/use-vetting-opt-out';
import translate from '@/utils/translate';

const logo = '/assets/svg/logo-popular-pays-by-lightricks.svg';

function OptOutMessage({
  message,
  ctaButton,
}: {
  message: string;
  ctaButton?: React.ReactNode;
}) {
  return (
    <div className={styles.optOutMessageContainer}>
      <Headline size="lg" className={styles.message}>
        {message}
      </Headline>
      {ctaButton ? <div>{ctaButton}</div> : undefined}
    </div>
  );
}

function VettingOptOut() {
  const {token} = useParams();
  const [optedOut, setOptedOut] = useState(false);
  const vettingOptOut = useVettingOptOut();

  const onOptOut = async () => {
    if (token) {
      setOptedOut(true);
      await vettingOptOut.mutateAsync(token);
    }
  };

  const getOptOutMessage = () => {
    if (vettingOptOut.isLoading) {
      return null;
    }
    if (!optedOut) {
      return (
        <OptOutMessage
          message={translate(
            'Opt out of data collection of Popular pays by Lightricks'
          )}
          ctaButton={
            <Button
              appearance="neutral"
              mode="filled"
              size="medium"
              onClick={onOptOut}
            >
              {translate('Opt out')}
            </Button>
          }
        />
      );
    }
    return (
      <OptOutMessage
        message={
          vettingOptOut.isSuccess
            ? translate('Opt out success')
            : translate('Opt out failed')
        }
      />
    );
  };

  return (
    <div className={styles.vettingOptOutContainer}>
      <img
        className={styles.logo}
        src={logo}
        alt="Popular Pays by Lightricks"
      />
      {getOptOutMessage()}
    </div>
  );
}

export default VettingOptOut;
