import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';

import {tokenSerializer} from './token';
import {getEnv} from '../config/environment';
import {BASE_CONFIG} from '../config/http';

const instance = axios.create({
  baseURL: getEnv().VITE_API_HOST || 'http://api.test.popularpays.com:3000',
});

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const headers = AxiosHeaders.from({
      ...(await tokenSerializer()),
      ...(config.headers || {}),
      ...BASE_CONFIG.headers,
    }) as AxiosRequestHeaders;

    return {
      ...config,
      headers,
    };
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

export default instance;
