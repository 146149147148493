import React from 'react';
import {useShallow} from 'zustand/shallow';
import useUpdateProfile from '@/hooks/queries/mutations/use-update-profile';
import useUploadPortfolioMediaItem from '@/hooks/queries/mutations/use-upload-portfolio-media-item';
import FileUploadService from '@/services/file-upload';
import type {PortfolioMediaItem} from '@/types/portfolioMediaItem';
import kindFromFileType from '@/utils/kindFromFileType';
import assertUnreachable from '@/utils/assertUnreachable';
import {useOnboardingStore} from '../OnboardingStore';
import PortfolioCard from '../components/portfolio-card/PortfolioCard';

type PortfolioProps = {
  fullName: string;
  birthdate: Date | undefined;
  location: string | undefined;
  specialties: string[];
  profilePictureUrl: string | undefined;
  portfolioMediaItems: PortfolioMediaItem[];
  setProfilePictureUrl: (url: string) => void;
  setPortfolioMediaItems: (mediaItems: PortfolioMediaItem[]) => void;
};

function Portfolio(props: PortfolioProps) {
  const {
    fullName,
    birthdate,
    location,
    specialties,
    profilePictureUrl,
    setProfilePictureUrl,
    portfolioMediaItems,
    setPortfolioMediaItems,
  } = props;

  const updateProfile = useUpdateProfile();
  const uploadPortfolioMediaItem = useUploadPortfolioMediaItem();
  const [openBanner] = useOnboardingStore(
    useShallow((state) => [state.openBanner, state.setStepValidity])
  );

  const attachAssetToProfile = async (
    sourceUrl: string,
    publicUrl: string,
    kind: 'photo' | 'video',
    uploadType: 'portfolio' | 'profile'
  ) => {
    switch (uploadType) {
      case 'portfolio':
        setPortfolioMediaItems([
          {
            url: publicUrl,
            type: kind,
          },
        ]);

        await uploadPortfolioMediaItem.mutateAsync({
          kind,
          sourceUrl,
        });
        break;
      case 'profile':
        setProfilePictureUrl(publicUrl);

        await updateProfile.mutateAsync({
          s3ImageUrl: sourceUrl,
        });
        break;
      default:
        assertUnreachable(uploadType);
    }
  };

  const uploadAsset = async (
    file: File,
    uploadType: 'portfolio' | 'profile'
  ) => {
    const kind = kindFromFileType(file.type);
    if (!kind) return; // we can't upload this file

    try {
      const {key: sourceUrl, url: presignedUploadUrl} =
        await FileUploadService.getPresignedUploadUrl({
          bucket: 'photo_submissions',
          extension: file.type.split('/')[1],
        });

      await FileUploadService.upload({
        file,
        presignedUploadUrl,
      }).then(async () => {
        const publicUrl = presignedUploadUrl.split('?')[0];
        await attachAssetToProfile(sourceUrl, publicUrl, kind, uploadType);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading file:', error);
    }
  };

  const updatePortfolioMediaItems = async (files: Set<File>) => {
    const uploadPromises = Array.from(files).map((file) =>
      uploadAsset(file, 'portfolio')
    );
    await Promise.all(uploadPromises);
  };

  const updateProfilePicture = async (file: File) => {
    await uploadAsset(file, 'profile');
  };

  return (
    <PortfolioCard
      fullName={fullName}
      birthdate={birthdate}
      location={location}
      specialties={specialties}
      profilePictureUrl={profilePictureUrl}
      portfolioMediaItems={portfolioMediaItems}
      onChangeProfilePicture={updateProfilePicture}
      onChangePortfolioMediaItems={updatePortfolioMediaItems}
      onError={(title: string, children: string) => {
        openBanner({
          title,
          children,
          severity: 'error',
        });
      }}
    />
  );
}

export default Portfolio;
