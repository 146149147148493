import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@/utils/reactQueryClient';
import userUpdaters from '@/api/updaters/user';

type UpdateNameArgs = {
  onError?: (error: string) => void;
};

function useUpdateName({onError}: UpdateNameArgs = {}) {
  return useMutation({
    mutationKey: ['updateName'],
    mutationFn: userUpdaters.updateName,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['user']});
    },
    onError,
  });
}

export default useUpdateName;
