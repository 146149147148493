import {styled} from '@mui/material/styles';
import {ToggleButton} from '@mui/material';

const VerticalTagsToggleButton = styled(ToggleButton)`
  &.MuiButtonBase-root {
    &.MuiToggleButton-root {
      &.MuiToggleButton-sizeMedium {
        &.MuiToggleButton-standard {
          color: #404040;
          border: 1px solid #d9d9d9;
          border-radius: 32px;
          font-size: 15px;
          font-weight: 300;
          padding: 4px 18px;
          text-transform: none;
          line-height: 23px;
          margin-top: 14px;
          margin-right: 15px;
          &.Mui-selected {
            background-color: #404040;
            color: #fff;
          }
        }
      }
    }
  }
`;

export default VerticalTagsToggleButton;
