import React from 'react';
import {useShallow} from 'zustand/shallow';
import translate from '@/utils/translate';
import assertUnreachable from '@/utils/assertUnreachable';
import {STEPS} from './onboardingSteps';
import {useOnboardingStore} from './OnboardingStore';
import {WizardLayoutProps} from '@/components/wizard-layout/WizardLayout';
import {InstagramInstructionsMedia} from './components/connect-platforms/InstagramInstructions';
import PortfolioLink from '@/views/onboarding/components/portfolio-link';
import {OnboardingCompletionTopHeader} from './steps/OnboardingCompletion';

type StepData = Partial<WizardLayoutProps>;

function useStepData(): StepData {
  const [step, fullName] = useOnboardingStore(
    useShallow((state) => [state.step, state.fullName])
  );

  return React.useMemo(() => {
    switch (step) {
      case STEPS.NAME_AND_BIRTHDATE:
        return {
          heading: translate('Tell us a bit about you'),
        };
      case STEPS.CREATOR_SEGMENT:
        return {
          heading: translate('{name}, what best describes what you do?', {
            name: fullName.split(' ')[0],
          }),
          wizardFooterButtonProps: {
            hideNextButton: true,
          },
        };
      case STEPS.LOCATION:
        return {
          heading: translate('Where do you live?'),
          subheading: translate('So we can find you local collaborations'),
        };
      case STEPS.GENDER:
        return {
          heading: translate('How do you identify?'),
          wizardFooterButtonProps: {
            hideNextButton: true,
          },
        };
      case STEPS.SOCIAL_APPS:
        return {
          heading: translate('Which socials are you on?'),
        };
      case STEPS.BEST_COLLABS:
        return {
          heading: translate('Let’s find your best collabs'),
          subheading: translate("Choose 3 brands you'd love to work with"),
        };
      case STEPS.SPECIALTIES:
        return {
          heading: translate('What kind of content do you create?'),
          subheading: translate('Select up to 3 tags'),
        };
      case STEPS.CAPABILITIES:
        return {
          heading: translate('Highlight your specialties'),
          subheading: translate('Select skills to show on your profile'),
        };
      case STEPS.SYNC_PLATFORMS:
        return {
          heading: translate('Ready to sync your socials?'),
        };
      case STEPS.PORTFOLIO:
        return {
          heading: translate('What do you want to highlight?'),
          subheading: translate(
            'Choose up to 3 for now and update them anytime you want'
          ),
          wizardFooterButtonProps: {
            label: translate('Save & continue'),
          },
          renderFooter: () => <PortfolioLink />,
        };
      case STEPS.BIO:
        return {
          heading: translate('Do you have a Bio already?'),
          subheading: translate(
            'If not, we’ve added one for you to get the most out of your profile.'
          ),
        };
      case STEPS.COMPLETION:
        return {
          wizardFooterButtonProps: {
            label: translate('Explore gigs'),
          },
          progress: null,
          renderTopHeader() {
            return <OnboardingCompletionTopHeader />;
          },
        };
      case STEPS.INSTAGRAM_INSTRUCTIONS:
        return {
          heading: translate('Connect Your Instagram Account'),
          progress: null,
          renderTopHeader: InstagramInstructionsMedia,
          wizardFooterButtonProps: {
            label: translate('Continue with Facebook'),
            iconName: 'Social-Facebook',
          },
        };
      case STEPS.REONBOARDING:
        return {
          wizardFooterButtonProps: {
            label: translate('Get started'),
          },
        };
      default:
        return assertUnreachable(step);
    }
  }, [fullName, step]);
}
export default useStepData;
