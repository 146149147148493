import {ApiClient, IConfig, IResult, QueryArg} from 'jsonapi-react';
import {snakeCase} from 'lodash';
import {tokenSerializer} from './token';
import mapKeysToCase from './mapKeysToCase';
import getCircularReplacer from './getCircularReplacer';
import {getEnv} from '../config/environment';
import {BASE_CONFIG} from '../config/http';
import schema from '../schema';

const OVERRIDE_KEYS = {
  s_3_image_url: 's3_image_url',
};

export class JsonApiError extends Error {
  status: number | null;

  code: number | null;

  constructor(error: any) {
    super(
      typeof error === 'object'
        ? error.message || error.detail || error.title
        : error
    );
    this.name = 'JsonApiError';
    this.status = typeof error === 'object' ? error.status : null;
    this.code = typeof error === 'object' ? error.status : null;

    Object.setPrototypeOf(this, JsonApiError.prototype);
  }
}

async function fetchWithToken(path: string, config: RequestInit) {
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...BASE_CONFIG.headers,
      ...(await tokenSerializer()),
    },
  };

  return fetchWithStatusCheck(path, newConfig);
}

// This is so the HTTP status code can be used in application logic. The promise returned by this function will be rejected
// if the HTTP status code is 400 or higher.
// See https://github.com/aribouius/jsonapi-react/blob/025e7f64eb106de2ab1d41cb9812e5cc1a4b294a/src/client.js#L377
async function fetchWithStatusCheck(path: string, config: RequestInit) {
  const r = await fetch(path, config);

  if (r.status >= 400) {
    const errorMessageBody = await r.text();
    // Extract error message from JSON response or plain text response
    let errorMessage;
    try {
      const errorMessageJson = JSON.parse(errorMessageBody);
      errorMessage = errorMessageJson.errors[0].detail;
    } catch (e) {
      errorMessage = errorMessageBody;
    }
    // Pass error message and status to JsonApiError, so it can be used in application logic
    throw new JsonApiError({status: r.status, message: errorMessage});
  }
  return r;
}

const jsonApiClient = new ApiClient({
  url: getEnv().VITE_API_HOST || 'http://api.test.popularpays.com:3000',
  schema,
  cacheTime: getEnv().MODE === 'test' ? 0 : 5 * 60,
  staleTime: 60,
  fetch: fetchWithToken,
});

export async function jsonApiFetch(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.fetch(queryArg, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  // remove circular references from the returned data + map the results data keys to camelCase
  return JSON.parse(
    JSON.stringify(mapKeysToCase(results), getCircularReplacer())
  );
}

export async function jsonApiMutate(
  queryArg: QueryArg,
  data: any,
  config = {}
) {
  const serializedData: any = mapKeysToCase(data, snakeCase);

  // Check serializedData against overrideKeys
  for (const [key, value] of Object.entries(OVERRIDE_KEYS)) {
    if (Object.prototype.hasOwnProperty.call(serializedData, key)) {
      serializedData[value] = serializedData[key];
      delete serializedData[key];
    }
  }

  const results = await jsonApiClient.mutate(queryArg, serializedData, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  return mapKeysToCase(results);
}

export async function jsonApiDelete(queryArg: QueryArg, config = {}) {
  const results = await jsonApiClient.delete(queryArg, config);
  if (results?.error) {
    throw new JsonApiError(results.error);
  }
  return JSON.parse(
    JSON.stringify(mapKeysToCase(results), getCircularReplacer())
  );
}

export default jsonApiClient;
