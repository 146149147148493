import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@/utils/reactQueryClient';
import profileUpdaters from '@/api/updaters/profile';

type UpdateProfileArgs = {
  onError?: (error: string) => void;
};

function useUpdateProfile({onError}: UpdateProfileArgs = {}) {
  return useMutation({
    mutationKey: ['updateProfile'],
    mutationFn: profileUpdaters.profile,
    onSuccess: async () => {
      // profile is included in the user fetcher
      await queryClient.invalidateQueries({queryKey: ['user']});
    },
    onError,
  });
}

export default useUpdateProfile;
