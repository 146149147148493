import api from '../index';
import QueryContext from '../QueryContext';

export const DEFAULT_USER_INCLUDE = [
  'identities',
  'profile',
  'profile.tags',
  'profile.locale',
  'profile.content_preferences',
];

const userFetchers = {
  user: (context: QueryContext) =>
    api.get('/user', {
      params: {
        include: context?.meta?.include || DEFAULT_USER_INCLUDE,
      },
    }),
  collaborations: () =>
    api.get('/user/collaborations', {
      params: {
        per_page: 1,
      },
    }),
};

export default userFetchers;
