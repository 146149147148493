/* eslint-disable import/prefer-default-export */
import {useCallback} from 'react';
import {
  useWizardSignupEvents,
  sendSignUpScreenEvent,
} from '@/hooks/use-wizard-signup-events/useWizardSignupEvents';
import {flowNames} from '@/lib/delta/deltaConstants';
import {useUserData} from '@/hooks/useUserData';
import {Step, ONBOARDING_STEPS_UUID_MAP} from './onboardingSteps';

export type ScreenEventProps = {
  step: Step;
  source?: string;
  details: Record<string, unknown>;
  options?: Record<string, unknown>;
  dismissedReason?: string;
};

export const useOnboardingAnalytics = (flowId: string) => {
  const {user} = useUserData({});
  const userId = user?.id;
  const flowName = flowNames.onboarding;

  const {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
    sendSignUpFlowEndedEvent,
    sendSignUpFlowStartedEvent,
    useSendNewConnectedPlatformEvent,
    clearStoredProcessIds,
  } = useWizardSignupEvents(
    ONBOARDING_STEPS_UUID_MAP,
    flowName,
    userId,
    flowId
  );

  const sendScreenEvent = useCallback(
    (
      step: Step,
      source: string | undefined,
      action: 'presented' | 'dismissed',
      details: Record<string, unknown>,
      options?: Record<string, unknown>,
      dismissedReason?: string
    ) => {
      const screenPresentationId = ONBOARDING_STEPS_UUID_MAP[step];

      sendSignUpScreenEvent(
        flowId ?? '',
        step,
        action,
        screenPresentationId,
        details,
        options,
        dismissedReason,
        source ?? 'unknown'
      );
    },
    [flowName, sendSignUpScreenEvent]
  );

  const sendSignUpScreenPresentedEvent = useCallback(
    (props: ScreenEventProps) =>
      sendScreenEvent(
        props.step,
        props.source,
        'presented',
        props.details,
        props.options,
        props.dismissedReason
      ),
    [sendScreenEvent]
  );

  const sendSignUpScreenDismissedEvent = useCallback(
    (props: ScreenEventProps) =>
      sendScreenEvent(
        props.step,
        props.source,
        'dismissed',
        props.details,
        props.options,
        props.dismissedReason
      ),
    [sendScreenEvent]
  );

  return {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
    sendSignUpFlowEndedEvent,
    sendSignUpFlowStartedEvent,
    useSendNewConnectedPlatformEvent,
    sendSignUpScreenPresentedEvent,
    sendSignUpScreenDismissedEvent,
    clearStoredProcessIds,
  };
};
