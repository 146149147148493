import axios from '@/utils/axios';

interface PresignedUploadUrlOptions {
  bucket: string;
  extension: string;
  file_name?: string;
  uri?: string;
}

interface UploadFileOptions {
  file: File;
  presignedUploadUrl: string;
}

class FileUpload {
  static async getPresignedUploadUrl(options: PresignedUploadUrlOptions) {
    const response = await axios.get('/uploads/presigned-url', {
      params: options,
    });
    return response.data;
  }

  static async upload(options: UploadFileOptions) {
    const response = await axios.put(options.presignedUploadUrl, options.file, {
      headers: {
        'Content-Type': options.file.type,
      },
    });
    return response.data;
  }
}

export default FileUpload;
