import React, {useEffect, useMemo} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Analytics from '@popularpays/analytics';
import AppProviders from './src/AppProviders';
import EmberIframe from './src/components/ember-iframe';
import AuthRoutes from './src/routes/auth';
import './src/index.scss';
import {getEnv} from './src/config/environment';
import MainRoutes from './src/routes/main';
import PublicAccessRoutes from './src/routes/public-access';
import useLogout from './src/hooks/use-logout';
import useLtxToken from './src/hooks/use-ltx-token';
import useTokenSessionInvalidated from './src/hooks/use-token-session-invalidated';
import useSetLaunchDarklyContext from './src/hooks/use-set-launch-darkly-context';
import NotFound from './src/views/not-found';
import ModalTermsOfService from './src/components/modal-terms-of-service';
import FlashMessagesService from './src/components/flash-messages-service';
import useNavigation from './src/hooks/use-navigation';
import raiseFlashMessage from '@/utils/raiseFlashMessage';
import useToken from './src/hooks/use-token';
import useZendeskScript from './src/hooks/use-zendesk-script';

EmberIframe.listen('changePageTitle', (title: string) => {
  document.title = title;
});

export const SC_INVITE_CODE_KEY = 'sc_invite_code';
export const ON_PLATFORM_SYNC = 'on_platform_sync';

const ZENDESK_INCLUDED_ROUTES = ['/account'];

function App() {
  const {token, initialized} = useToken(true);
  const {ltxToken, ltxTokenInitialized} = useLtxToken(true);
  const navigation = useNavigation();
  const {toast_message} = useParams();
  const {logout} = useLogout();
  const location = useLocation();
  const {isLoading: isSessionInvalidatedLoading} = useTokenSessionInvalidated();
  const {flags, launchDarklyInitialized} = useSetLaunchDarklyContext();
  const [searchParams] = useSearchParams();

  const scInviteCode = searchParams.get(SC_INVITE_CODE_KEY);
  const onPlatformSync = searchParams.get(ON_PLATFORM_SYNC);
  const isBrandSafetyFlow = scInviteCode || onPlatformSync;

  const shouldLoadZendesk = useMemo(
    () =>
      !isBrandSafetyFlow &&
      ZENDESK_INCLUDED_ROUTES.some((route) =>
        location.pathname.startsWith(route)
      ),
    [scInviteCode, location.pathname]
  );

  useZendeskScript(getEnv().VITE_ZENDESK_KEY, shouldLoadZendesk);

  useEffect(() => {
    if (token && searchParams.get('redirect') === 'social-networks') {
      navigation.navigate('/account/social-networks');
    }
  }, [token]);

  EmberIframe.listen('logOut', () => {
    logout();
    navigation.navigate('/auth/login');
  });

  useEffect(() => {
    if (toast_message) {
      raiseFlashMessage({message: toast_message});
    }
  }, []);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    Analytics.dispatchPageView(pagePath);
  }, [location]);

  if (!initialized && !ltxTokenInitialized) {
    return null;
  }

  if (ltxToken && !token && isSessionInvalidatedLoading) {
    return null;
  }

  if (!launchDarklyInitialized) {
    return null;
  }

  return token && ltxToken ? <MainRoutes /> : <AuthRoutes />;
}

function WrappedApp() {
  // all of various providers should be wrapping the <App /> component in here
  return (
    <AppProviders>
      <>
        <Router>
          <Routes>
            {PublicAccessRoutes()}
            <Route path="/*" element={<App />} />
            <Route path="/not/found" element={<NotFound />} />
          </Routes>
          <ModalTermsOfService />
        </Router>
        <FlashMessagesService />
      </>
    </AppProviders>
  );
}

export default WrappedApp;
