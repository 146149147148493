import useToken from '../use-token';
import useLtxToken from '../use-ltx-token/useLtxToken';
import Fortress from '../../services/fortress/Fortress';
import EmberIframe from '../../components/ember-iframe';

const useLogout = () => {
  const {setToken} = useToken(true);
  const {setLtxToken} = useLtxToken();

  const logout = () => {
    EmberIframe.send('logOut', null);
    setTimeout(() => {
      setToken(null);
      setLtxToken(null);
      Fortress.clearCookieTokens();
      // Refresh The page on logout so that react-query will delete the old user data.
      window.location.reload();
    });
  };

  return {logout};
};

export default useLogout;
