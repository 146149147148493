import React, {useCallback, useEffect, useMemo} from 'react';
import {useShallow} from 'zustand/shallow';
import WizardLayout from 'src/components/wizard-layout/WizardLayout';
import {v4 as uuid} from 'uuid';
import {Step, STEPS} from './onboardingSteps';
import useRenderStepContent from './useRenderStepContent';
import useStepData from './useStepData';
import useStepMutation from './useStepMutation';
import {useOnboardingStore} from './OnboardingStore';
import useStepValidation from './useStepValidation';
import useStepOrder from './useStepOrder';
import {useOnboardingAnalytics} from './useOnboardingAnalytics';
import {useUserData} from '@/hooks/useUserData';
import useScreenPresentationDetails from './useScreenPresentationDetails';
import authenticateInstagram from '@/services/instagram/Instagram';
import {navigateWithAnalytics} from '@/utils/wrappers/Delta';
import {Platform} from '@/types/platforms';
import useAnalyticsScreenOptions from './useAnalyticsScreenOptions';
import useStepBackgroundColor from './useStepBackgroundColor';
import usePrefetchAssets from '@/hooks/use-prefetch-assets/usePrefetchAssets';
import {
  COMPLETION_MOBILE_VIDEO_URL,
  COMPLETION_VIDEO_URL,
} from './steps/OnboardingCompletion';
import {Asset} from '@/hooks/use-prefetch-assets';

const ONBOARDING_ASSETS: Asset[] = [
  ...Object.values(STEPS)
    .filter((step) => step !== STEPS.COMPLETION)
    .map((step) => {
      let imageName = step;
      if (step === STEPS.INSTAGRAM_INSTRUCTIONS) {
        imageName = STEPS.SYNC_PLATFORMS;
      } else if (step === STEPS.REONBOARDING) {
        imageName = STEPS.BEST_COLLABS;
      }

      return {
        url: `/assets/images/onboarding/${imageName}.jpg`,
        type: 'image' as const,
      };
    }),
  {
    url: COMPLETION_MOBILE_VIDEO_URL,
    type: 'video' as const,
  },
  {
    url: COMPLETION_VIDEO_URL,
    type: 'video' as const,
  },
];

function OnboardingView() {
  useStepValidation();
  useStepOrder();

  usePrefetchAssets(ONBOARDING_ASSETS);

  const [
    step,
    isCurrentStepValid,
    banner,
    previousStep,
    stepOrder,
    isStepUploading,
  ] = useOnboardingStore(
    useShallow((state) => [
      state.step,
      state.isCurrentStepValid,
      state.banner,
      state.previousStep,
      state.stepOrder,
      state.isStepUploading,
    ])
  );

  const [
    setStep,
    closeBanner,
    clearPersistentData,
    setAnalyticsFlowId,
    analyticsFlowId,
  ] = useOnboardingStore(
    useShallow((state) => [
      state.setStep,
      state.closeBanner,
      state.clearPersistentData,
      state.setAnalyticsFlowId,
      state.analyticsFlowId,
    ])
  );

  const flowId = analyticsFlowId || uuid();
  const {
    sendSignUpFlowStartedEvent,
    sendSignUpScreenPresentedEvent,
    sendSignUpScreenDismissedEvent,
    useSendNewConnectedPlatformEvent,
    sendSignUpFlowEndedEvent,
    sendSignUpScreenButtonPressedEvent,
    clearStoredProcessIds,
  } = useOnboardingAnalytics(flowId);
  const {connectedPlatforms, user} = useUserData({});
  useSendNewConnectedPlatformEvent(connectedPlatforms);

  useEffect(() => {
    // If analyticsFlowId is set it means the flow has already started
    // so we don't need to send the flow started event
    if (!analyticsFlowId) {
      sendSignUpFlowStartedEvent();
    }
  }, [analyticsFlowId, sendSignUpFlowStartedEvent]);

  if (!analyticsFlowId) {
    setAnalyticsFlowId(flowId);
  }

  useStepFromUrl(setStep, stepOrder);
  useHandleErrorFromUrl();

  const stepIndex = React.useMemo(() => {
    return stepOrder.indexOf(step);
  }, [step, stepOrder]);

  const stepDataProps = useStepData();
  const stepMutation = useStepMutation();

  const progress = (stepIndex + 1) / stepOrder.length;
  useEffect(() => {
    if (progress >= 1) {
      sendSignUpFlowEndedEvent('success', connectedPlatforms);
    }
  }, [progress, sendSignUpFlowEndedEvent, connectedPlatforms]);

  const getScreenPresentationDetails = useScreenPresentationDetails();
  const analyticsScreenOptions = useAnalyticsScreenOptions();

  const sendStepTransitionAnalytics = useCallback(
    (currentStep: Step, nextStep?: Step) => {
      const dismissalDetails = getScreenPresentationDetails(currentStep);
      const dismissedScreenOptions = analyticsScreenOptions(currentStep);
      const reason = `moved from ${currentStep} to ${nextStep}`;
      const source = previousStep ?? 'unknown';
      sendSignUpScreenDismissedEvent({
        step: currentStep,
        source,
        details: dismissalDetails,
        options: dismissedScreenOptions,
        dismissedReason: reason,
      });
      if (nextStep) {
        const presentationDetails = getScreenPresentationDetails(nextStep);
        const presentedScreenOptions = analyticsScreenOptions(nextStep);
        sendSignUpScreenPresentedEvent({
          step: nextStep,
          source: currentStep,
          details: presentationDetails,
          options: presentedScreenOptions,
          dismissedReason: reason,
        });
      }
    },
    [
      getScreenPresentationDetails,
      sendSignUpScreenDismissedEvent,
      sendSignUpScreenPresentedEvent,
      analyticsScreenOptions,
      previousStep,
    ]
  );

  const onBack = useMemo(() => {
    if (step === STEPS.COMPLETION) {
      return undefined;
    }
    if (previousStep && stepIndex !== 0) {
      return () => {
        // fallback if the step isn't in stepOrder (index -1),
        // such as when the ?step= query param is used
        const targetStep =
          stepIndex > 0 ? stepOrder[stepIndex - 1] : previousStep;
        setStep(targetStep);
        sendSignUpScreenButtonPressedEvent('Back', step);
        sendStepTransitionAnalytics(step, targetStep);
      };
    }
    return undefined;
  }, [
    previousStep,
    setStep,
    sendStepTransitionAnalytics,
    step,
    stepIndex,
    stepOrder,
    sendSignUpScreenButtonPressedEvent,
  ]);

  const onNext = useCallback(async () => {
    if (step === STEPS.COMPLETION) {
      clearPersistentData();
      clearStoredProcessIds();
      await navigateWithAnalytics('/');
      return;
    }
    if (step === STEPS.INSTAGRAM_INSTRUCTIONS) {
      sendSignUpScreenButtonPressedEvent('Connect Instagram', step);
      const redirectQueryParams = {
        step: STEPS.SYNC_PLATFORMS,
      };
      authenticateInstagram(user?.id ?? null, redirectQueryParams);
      return;
    }
    await stepMutation();
    if (stepIndex < stepOrder.length - 1) {
      const nextStep = stepOrder[stepIndex + 1];
      sendSignUpScreenButtonPressedEvent('Next', step);
      sendStepTransitionAnalytics(step, nextStep);
      setStep(nextStep);
    }
  }, [
    step,
    stepMutation,
    stepIndex,
    stepOrder,
    sendSignUpScreenButtonPressedEvent,
    user?.id,
    sendStepTransitionAnalytics,
    setStep,
  ]);

  const stepContent = useRenderStepContent(onNext);
  const desktopVisualBackgroundColor = useStepBackgroundColor();

  const renderDesktopVisual = () => {
    let imageName = step;
    if (step === STEPS.INSTAGRAM_INSTRUCTIONS) imageName = STEPS.SYNC_PLATFORMS;
    if (step === STEPS.REONBOARDING) imageName = STEPS.BEST_COLLABS;
    if (step === STEPS.COMPLETION) {
      return (
        <video
          src={COMPLETION_VIDEO_URL}
          preload="auto"
          playsInline
          muted
          autoPlay
        />
      );
    }

    return (
      <img
        src={`/assets/images/onboarding/${imageName}.jpg`}
        alt="Step decoration"
      />
    );
  };

  const onSkip = undefined;
  // const onSkip = async () => {
  //   await navigateWithAnalytics('/');
  // };

  return (
    <WizardLayout
      banner={banner}
      onCloseBanner={closeBanner}
      progress={progress}
      renderDesktopVisual={renderDesktopVisual}
      desktopVisualBackgroundColor={desktopVisualBackgroundColor}
      renderContent={() => stepContent}
      onBack={onBack}
      onNext={onNext}
      onSkip={onSkip}
      isNextDisabled={!isCurrentStepValid}
      isUploading={isStepUploading}
      {...stepDataProps}
    />
  );
}

function isValidStep(step: string | null): step is Step {
  return Boolean(step && Object.values(STEPS).includes(step as Step));
}

function useStepFromUrl(setStep: (step: Step) => void, stepOrder: Step[]) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const stepParam = urlParams.get('step');
    if (isValidStep(stepParam)) {
      setStep(stepParam);
    }
  }, [setStep, stepOrder]);
}

function getPlatformFromString(provider?: string): Platform | null {
  if (!provider) {
    return null;
  }
  if (provider.toLowerCase().includes('instagram')) {
    return 'Instagram';
  }
  if (provider.toLowerCase().includes('tiktok')) {
    return 'TikTok';
  }
  if (provider.toLowerCase().includes('youtube')) {
    return 'YouTube';
  }
  if (provider.toLowerCase().includes('facebook')) {
    return 'Facebook';
  }
  return null;
}

function useHandleErrorFromUrl() {
  const [setPlatformError, analyticsFlowId] = useOnboardingStore(
    useShallow((state) => [state.setPlatformError, state.analyticsFlowId])
  );

  const {sendSignUpStepEndedEvent} = useOnboardingAnalytics(
    analyticsFlowId ?? ''
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const message = urlParams.get('message');
    const providerInput = urlParams.get('provider');
    const provider = getPlatformFromString(providerInput ?? '');
    const success = urlParams.get('success') === 'true';
    if (!success) {
      if (provider) {
        sendSignUpStepEndedEvent(provider, 'error', message ?? '');
      }
      setPlatformError(message ?? undefined);
    }
  }, [setPlatformError, sendSignUpStepEndedEvent, analyticsFlowId]);
}

export default OnboardingView;
