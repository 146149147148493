import React from 'react';
import {Platform, SOCIAL_PLATFORMS} from '@/types/platforms';
import PlatformList from '@/components/platform-list/PlatformList';
import {useOnboardingStore} from '../../OnboardingStore';
import {useUserData} from '@/hooks/useUserData';
import InfoBox, {type InfoBoxProps} from '@/components/info-box/InfoBox';
import styles from './ConnectPlatformsContent.module.scss';
import translate from '@/utils/translate';

interface ConnectPlatformsContentProps {
  onConnectPlatform: (platform: Platform) => Promise<void>;
  onCloseError: () => void;
}

type DynamicInfoBoxProps = {
  boxStyle: InfoBoxProps['boxStyle'];
  infoMessage: InfoBoxProps['infoMessage'];
  infoIcon: InfoBoxProps['infoIcon'];
};

const getInfoBoxProps = (connectedCount: number): DynamicInfoBoxProps => {
  switch (connectedCount) {
    case 1:
      return {
        boxStyle: 'blue',
        infoMessage: translate(
          'Connect one more social to boost your partnership chances by 57%.'
        ),
        infoIcon: 'Actions-Trending',
      };
    case 2:
      return {
        boxStyle: 'green',
        infoMessage: translate(
          'Maximize your odds by connecting {social} now.',
          {social: 'more socials'}
        ),
        infoIcon: 'Navigation-Stats',
      };
    default:
      return {
        boxStyle: 'purple',
        infoMessage: translate(
          'Your data is secure. It’s used to confirm your account and check basic stats.'
        ),
        infoIcon: 'Navigation-Shopbag-Shield-Stroke',
      };
  }
};

export default function ConnectPlatformsContent({
  onConnectPlatform,
  onCloseError,
}: ConnectPlatformsContentProps) {
  const {platformError, selectedSocialApps} = useOnboardingStore();
  const {connectedPlatforms} = useUserData({});
  return (
    <div className={styles.connectPlatformsContentContainer}>
      {platformError ? (
        <InfoBox
          className={styles.infoBox}
          boxStyle="error"
          infoMessage={platformError}
          handleCloseBox={onCloseError}
        />
      ) : (
        <InfoBox
          className={styles.infoBox}
          {...getInfoBoxProps(connectedPlatforms.length)}
        />
      )}
      <PlatformList
        selectedPlatforms={Array.from(selectedSocialApps)}
        connectedPlatforms={connectedPlatforms}
        onConnectPlatform={onConnectPlatform}
        isConnectablePlatform={() => true}
        showEmailPlatform={false}
        sortFunction={(a: Platform, b: Platform) => {
          const isAConnected = connectedPlatforms.includes(a);
          const isBConnected = connectedPlatforms.includes(b);

          if (isAConnected && !isBConnected) return -1;
          if (!isAConnected && isBConnected) return 1;
          return 0;
        }}
      />
    </div>
  );
}
