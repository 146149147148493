import React from 'react';
import {capitalize} from 'lodash';
import {SyncWhiteIcon} from '../icons';
import translate from '@/utils/translate';

export enum BannerContentOptions {
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  NEEDS_TO_LINK_PAYMENT_PROCESSOR = 'NEEDS_TO_LINK_PAYMENT_PROCESSOR',
  NEEDS_PROVIDER_RELINK = 'NEEDS_PROVIDER_RELINK',
}
interface BannerData {
  canDismiss: boolean;
  text: (input?: string) => string;
  linkUrl: string | null;
  linkText: () => string | null;
  icon: React.ReactNode | null;
}

export const BANNER_NOTIFICATION_DATA: {
  [keys in BannerContentOptions]: BannerData;
} = {
  MAINTENANCE_MODE: {
    canDismiss: false,
    text: () =>
      translate(
        'templates.protected.verified.banners.maintenance-mode.message'
      ),
    linkUrl: null,
    linkText: () => null,
    icon: null,
  },
  NEEDS_TO_LINK_PAYMENT_PROCESSOR: {
    canDismiss: false,
    text: () =>
      translate(
        'templates.protected.verified.banners.needs-link-payment-processor.message'
      ),
    linkUrl: '/account/banking',
    linkText: () =>
      translate(
        'templates.protected.verified.banners.needs-link-payment-processor.link-copy'
      ),
    icon: null,
  },
  NEEDS_PROVIDER_RELINK: {
    canDismiss: false,
    text: (provider?: string) =>
      translate('global.relink-account.text', {
        network: provider ? capitalize(provider) : null,
      }),
    linkUrl: '/account/social-networks',
    linkText: () => translate('global.relink-account.relink'),
    icon: <SyncWhiteIcon />,
  },
};
