import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {STEPS} from './wizardSteps';
import {useConnectPlatformsStore} from './store/ConnectPlatformStore';
import {
  calculateVettablePlatformsCompletion,
  SocialPlatform,
} from '@/types/platforms';
import {useConnectPlatformsAnalytics} from './useConnectPlatformsAnalytics';

const parsePlatformsFromQuery = (
  queryParam: string | null,
  availablePlatforms: SocialPlatform[]
): SocialPlatform[] => {
  return (
    queryParam
      ?.split(',')
      .filter((platform): platform is SocialPlatform =>
        availablePlatforms.some((p) => p === platform)
      ) || []
  );
};

const parseErrorMessageFromQuery = (
  searchParams: URLSearchParams
): string | undefined => {
  const errorToastMessage =
    searchParams.get('toast') || searchParams.get('message');
  const hasSuccess = searchParams.get('success=true');

  if (!hasSuccess && errorToastMessage) {
    return errorToastMessage;
  }
  return undefined;
};

export default (socialPlatforms: SocialPlatform[]) => {
  const {
    setSelectedPlatforms,
    setError,
    setBrandName,
    connectedPlatforms,
    setStep,
    selectedPlatforms,
  } = useConnectPlatformsStore();
  const {sendSignUpFlowStartedEvent} = useConnectPlatformsAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const platformsFromQuery = parsePlatformsFromQuery(
      searchParams.get('selectedPlatforms'),
      socialPlatforms
    );
    setSelectedPlatforms(platformsFromQuery);

    const errorMessage = parseErrorMessageFromQuery(searchParams);
    if (errorMessage) {
      setError(errorMessage);
    }

    const brandName = searchParams.get('brand');
    if (brandName) {
      setBrandName(brandName);
    }

    const providerRedirected = searchParams.get('provider');
    const completion = calculateVettablePlatformsCompletion(
      connectedPlatforms,
      selectedPlatforms
    );
    // This assumes that a user which was redirected by the platform after connecting already submitted his age.
    if (completion === 100) {
      setStep(STEPS.FINISHED);
    } else if (providerRedirected) {
      setStep(STEPS.SYNC_PLATFORMS);
    } else if (platformsFromQuery.length > 0) {
      // In case the user refresh after selecting platforms
      setStep(STEPS.SYNC_PLATFORMS);
    }

    const scInviteCode = searchParams.get('sc_invite_code') ?? undefined;
    sendSignUpFlowStartedEvent(scInviteCode);
  }, []);

  return (params: Record<string, string>) => {
    setSearchParams(params);
  };
};
