import React, {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Typography} from '@mui/material';
import CheckboxMui from '../checkbox-mui';
import FormGroup from '../form-group';
import Stack from '../stack';
import ButtonMui from '../button-mui';
import FilterRangeSlider from '../filter-range-slider';
import {
  RangeSliderValues,
  SingleSocialNetwork,
  SingleTag,
} from './FilterPopupParametersType';
import {
  DELIVERABLES_CHECKBOXES_SECTION,
  COMPENSATION_CHECKBOXES_SECTION,
  SOCIAL_NETWORKS_SECTION,
  VERTICAL_TAGS_SECTION,
} from './FilterPopupStructure';
import ToggleButtonMui from '../toggle-button';
import style from './style';
import verticalTagsFetchers from '../../../api/fetchers/verticalTags';
import translate from '@/utils/translate';

function CanvasFilters({
  updateFilters,
  filters,
  verticalTags,
  isLoadingVerticalTags,
}: any) {
  const [isVerticalTagsOpen, setIsVerticalTagsOpen] = useState(false);
  const matches = useMediaQuery('(max-width:780px)');

  return (
    <>
      <FormGroup style={style.section.withBorder}>
        <Typography
          component="div"
          style={style.section.header}
          data-testid="deliverables"
        >
          <Typography component="span" style={style.section.header.title}>
            {translate(DELIVERABLES_CHECKBOXES_SECTION.titleLocaleKey)}
          </Typography>
        </Typography>
        <Stack direction={matches ? 'column' : 'row'} spacing={2}>
          {DELIVERABLES_CHECKBOXES_SECTION.list.map((item) => (
            <CheckboxMui
              testID={item.dataKey}
              key={item.id}
              value={item.value}
              label={translate(item.labelLocaleKey)}
              checked={filters?.deliverables?.[item.dataKey]}
              onChange={(event) => {
                const value = {
                  ...(filters?.deliverables || {}),
                  [item.dataKey]: event.target.checked,
                };
                updateFilters('deliverables', value);
              }}
            />
          ))}
        </Stack>
      </FormGroup>

      <FormGroup style={style.section.withBorder}>
        <Typography component="div" style={style.section.header}>
          <Typography component="span" style={style.section.header.title}>
            {translate(SOCIAL_NETWORKS_SECTION.titleLocaleKey)}
          </Typography>
          {SOCIAL_NETWORKS_SECTION.subtitleLocaleKey !== '' && (
            <Typography component="p" style={style.section.header.subtitle}>
              {translate(SOCIAL_NETWORKS_SECTION.subtitleLocaleKey)}
            </Typography>
          )}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          style={{flexWrap: 'wrap', gap: '16px'}}
        >
          {SOCIAL_NETWORKS_SECTION.list.map((network) => (
            <ToggleButtonMui
              testID={`network-toggle-${network.name}`}
              type="socials"
              key={network.name}
              value={network.name}
              aria-label={network.name}
              selected={filters?.socialNetworks?.includes(network.name)}
              onChange={(event, key) => {
                const value = filters?.socialNetworks?.includes(network.name)
                  ? filters?.socialNetworks?.filter(
                      (item: SingleSocialNetwork) => item !== network.name
                    )
                  : [...(filters?.socialNetworks || []), network.name];

                updateFilters('socialNetworks', value);
              }}
            >
              {network.icon && <network.icon />}
              {network.title && <span>{network.title}</span>}
            </ToggleButtonMui>
          ))}
        </Stack>

        <Stack direction="column" spacing={2}>
          <FilterRangeSlider
            showOnMax
            maxSign="+"
            label={translate(
              'components.filters-popup.sections.social-networks.sliders.title'
            )}
            name="followers"
            curMin={filters.socialFollowersRange?.curMin}
            curMax={filters.socialFollowersRange?.curMax}
            value={[
              filters.socialFollowersRange?.curMin,
              filters.socialFollowersRange?.curMax,
            ]}
            min={filters.socialFollowersRange?.min}
            max={filters.socialFollowersRange?.max}
            onChange={(minMaxObject) => {
              updateFilters('socialFollowersRange', minMaxObject);
            }}
          />
        </Stack>
      </FormGroup>

      <FormGroup style={style.section.withBorder}>
        <Typography component="div" style={style.section.header}>
          <Typography component="span" style={style.section.header.title}>
            {translate(COMPENSATION_CHECKBOXES_SECTION.titleLocaleKey)}
          </Typography>
        </Typography>
        <Stack direction={matches ? 'column' : 'row'} spacing={2}>
          {COMPENSATION_CHECKBOXES_SECTION.list.map((item) => (
            <CheckboxMui
              testID={item.dataKey}
              key={item.id}
              value={item.value}
              label={translate(item.labelLocaleKey)}
              checked={filters?.compensationType?.[item.dataKey]}
              onChange={(event) => {
                const value = {
                  ...(filters?.compensationType || {}),
                  [item.dataKey]: event.target.checked,
                };
                updateFilters('compensationType', value);
              }}
            />
          ))}
        </Stack>

        <Stack direction="column" spacing={2}>
          <FilterRangeSlider
            showOnMax
            maxSign="+"
            label={translate(
              'components.filters-popup.sections.compensation.sliders.title'
            )}
            name="compensationSum"
            curMin={filters.compensationSum?.curMin}
            curMax={filters.compensationSum?.curMax}
            value={[
              filters.compensationSum?.curMin,
              filters.compensationSum?.curMax,
            ]}
            min={filters.compensationSum?.min}
            max={filters.compensationSum?.max}
            onChange={(minMaxObject) => {
              updateFilters('compensationSum', minMaxObject);
            }}
            prefix="$"
          />
        </Stack>
      </FormGroup>

      <FormGroup style={style.section.withoutBottomMargin}>
        <Typography
          component="span"
          style={
            isVerticalTagsOpen ? style.sectionTagsExtended : style.sectionTags
          }
        >
          <Typography component="div" style={style.section.header}>
            <Typography component="span" style={style.section.header.title}>
              {translate(VERTICAL_TAGS_SECTION.titleLocaleKey)}
            </Typography>
            {VERTICAL_TAGS_SECTION.subtitleLocaleKey === '' && (
              <Typography component="p">
                {translate(VERTICAL_TAGS_SECTION.subtitleLocaleKey)}
              </Typography>
            )}
          </Typography>

          <Typography component="div" style={style.sectionTags.innerTags}>
            {isLoadingVerticalTags && <div>Loading...</div>}
            {verticalTags?.map((tag: any) => {
              return (
                <ToggleButtonMui
                  type="tags"
                  testID={`tags-${tag.name}`}
                  key={tag.name}
                  value={tag.name}
                  aria-label={tag.name}
                  selected={filters?.tags?.includes(tag.name)}
                  onChange={(event, key) => {
                    const value = filters?.tags?.includes(tag.name)
                      ? filters?.tags?.filter((item: any) => item !== tag.name)
                      : [...(filters?.tags || []), tag.name];

                    updateFilters('tags', value);
                  }}
                >
                  {tag.displayText}
                </ToggleButtonMui>
              );
            })}
          </Typography>
        </Typography>
        <Stack direction="row" style={style.sectionShowMoreButton}>
          <ButtonMui
            onClick={() => setIsVerticalTagsOpen(!isVerticalTagsOpen)}
            testID="toggle_tags_show"
          >
            {isVerticalTagsOpen
              ? translate(
                  'components.filters-popup.sections.vertical-tags.show-less'
                )
              : translate(
                  'components.filters-popup.sections.vertical-tags.show-more'
                )}
          </ButtonMui>
        </Stack>
      </FormGroup>
    </>
  );
}

function FiltersPopupCanvas(props: any) {
  const {updateFilters, filters} = props;
  const verticalTagsQuery = useQuery({
    queryKey: ['verticalTags'],
    queryFn: verticalTagsFetchers.verticalTags,
  });

  const {data, isLoading: isLoadingTags} = verticalTagsQuery;
  const verticalTagsData = data?.data;

  return (
    <CanvasFilters
      updateFilters={updateFilters}
      filters={filters}
      verticalTags={verticalTagsData}
      isLoadingVerticalTags={isLoadingTags}
    />
  );
}

export default FiltersPopupCanvas;
