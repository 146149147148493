import FortressWebClient from '@lightricks/fortress-web-client';
import Fortress from '../services/fortress/Fortress';
import {getEnv} from './environment';

const fortressConfig = {
  fortressAppName: getEnv().VITE_FORTRESS_APP_NAME,
  fortressVersion: getEnv().VITE_FORTRESS_VERSION,
  fortressApiBaseUri: getEnv().VITE_FORTRESS_API_BASE_URI,
};

Fortress.setDriver(
  new FortressWebClient(fortressConfig, `cwa_${getEnv().MODE}`)
);
