import {v4 as uuid} from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export const stepsUuidMapGenerator = <T extends string>(
  steps: Record<string, T>
): Record<T, string> => {
  return Object.values(steps).reduce((map, key) => {
    return {
      ...map,
      [key]: uuid(),
    };
  }, {} as Record<T, string>);
};
