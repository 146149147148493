import React, {useState} from 'react';
import {debounce} from 'lodash';
import Select, {SingleValue} from 'react-select';
import {getEnv} from 'src/config/environment';
import {MapboxFeature} from 'src/types/mapbox';
import clsx from 'clsx';
import styles from './MapboxSearchInput.module.scss';

type Option = MapboxFeature | null;

type MapboxSearchInputProps = {
  placeholder?: string;
  onSelect: (value: SingleValue<Option>) => void;
};

function getMapboxRequestURL(searchValue: string, accessToken: string) {
  const url = new URL('https://api.mapbox.com/geocoding/v5/mapbox.places/');
  url.pathname += `${encodeURIComponent(searchValue)}.json`;
  url.searchParams.append('limit', '5');
  url.searchParams.append('types', 'place');
  url.searchParams.append('language', 'en');
  url.searchParams.append('access_token', accessToken);

  return url;
}

function MapboxSearchInput(props: MapboxSearchInputProps) {
  const {placeholder, onSelect} = props;
  const [suggestions, setSuggestions] = useState<MapboxFeature[]>([]);
  const accessToken = getEnv().VITE_MAPBOX_API_KEY;

  const search = debounce(async (searchValue) => {
    if (!searchValue) {
      setSuggestions([]);
      return;
    }

    const response = await fetch(getMapboxRequestURL(searchValue, accessToken));
    const {features: items} = await response.json();
    setSuggestions(items ?? []);
  }, 500);

  return (
    <Select<Option, false>
      placeholder={placeholder}
      options={suggestions}
      onInputChange={search}
      onChange={onSelect}
      getOptionLabel={(option: Option) => option?.place_name ?? ''}
      getOptionValue={(option: Option) => option?.id ?? ''}
      isClearable
      backspaceRemovesValue
      components={{
        DropdownIndicator: null,
        ClearIndicator: undefined,
      }}
      noOptionsMessage={() => null}
      loadingMessage={() => null}
      escapeClearsValue={false}
      filterOption={null}
      classNames={{
        control: () => styles.control,
        menu: () => styles.menu,
        menuList: () => styles.menuList,
        option: (state) =>
          clsx(styles.option, state.isFocused && styles.option__focused),
      }}
    />
  );
}

export default MapboxSearchInput;
