import React from 'react';
import {Headline} from '@lightricks/react-design-system';
import MultiSelect from '../../../components/multi-select';
import styles from './BrandsSelect.module.scss';
import {SelectItem} from './selectItem';
import {Brand} from '../OnboardingStore';
import translate from '@/utils/translate';
import {MAX_PREFERRED_COLLABS} from '../useStepValidation';

interface BrandItemProps {
  label: string;
  image: React.ReactNode;
}

interface BrandSelectProps {
  selectedBrands: Set<Brand>;
  onBrandChange: (brands: Set<Brand>) => void;
}

type BrandOption = {
  value: string;
  labelLocaleKey: string;
  filename: string;
};

const BRAND_IMAGES_PATH = '/assets/images/brands/';

export const BRANDS: Array<BrandOption> = [
  {
    value: 'Dunkin',
    labelLocaleKey: 'Dunkin',
    filename: 'dunkin.png',
  },
  {
    value: 'Adidas',
    labelLocaleKey: 'Adidas',
    filename: 'adidas.png',
  },
  {
    value: 'Walmart',
    labelLocaleKey: 'Walmart',
    filename: 'walmart.png',
  },
  {
    value: 'Amazon Home',
    labelLocaleKey: 'Amazon Home',
    filename: 'amazon_home.png',
  },
  {
    value: 'Monica + Andy',
    labelLocaleKey: 'Monica + Andy',
    filename: 'monica_andy.png',
  },
  {
    value: 'Welly',
    labelLocaleKey: 'Welly',
    filename: 'welly.png',
  },
  {
    value: 'Hyatt',
    labelLocaleKey: 'Hyatt',
    filename: 'hyatt.png',
  },
  {
    value: 'Herradura',
    labelLocaleKey: 'Herradura',
    filename: 'herradura.png',
  },
  {
    value: 'Delta',
    labelLocaleKey: 'Delta',
    filename: 'delta.png',
  },
  {
    value: 'Pizza Hut',
    labelLocaleKey: 'Pizza Hut',
    filename: 'pizza_hut.png',
  },
  {
    value: 'Velveeta',
    labelLocaleKey: 'Velveeta',
    filename: 'velveeta.png',
  },
  {
    value: 'Southwest',
    labelLocaleKey: 'Southwest',
    filename: 'southwest.png',
  },
];

const brandToSelectItem = (
  brand: BrandOption
): SelectItem<{id: string; name: string}> => {
  return {
    label: translate(brand.labelLocaleKey),
    image: (
      <img
        src={[BRAND_IMAGES_PATH, brand.filename].join('')}
        alt={brand.value}
        className={styles.brandImage}
      />
    ),
    value: {id: brand.value, name: brand.value},
  };
};

function BrandItem({label, image}: BrandItemProps) {
  return (
    <div className={styles.brandItem}>
      {image}
      <Headline size="xs">{label}</Headline>
    </div>
  );
}

export default function BrandSelect({
  selectedBrands,
  onBrandChange,
}: BrandSelectProps) {
  const options = BRANDS.map(brandToSelectItem);

  const selectedItems = options.filter((option) =>
    Array.from(selectedBrands).some((brand) => brand.id === option.value.id)
  );

  return (
    <MultiSelect
      items={options}
      numberOfColumns={2}
      maxSelectedItems={MAX_PREFERRED_COLLABS}
      selectedItems={selectedItems}
      onSelectionChange={(items) =>
        onBrandChange(new Set(items.map((item) => item.value)))
      }
      renderItem={({label, image}) => <BrandItem label={label} image={image} />}
      getItemKey={(item) => item.value.id}
      itemClassName={styles.brandItem}
    />
  );
}
