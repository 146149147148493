import {useCallback} from 'react';
import {useOnboardingStore} from './OnboardingStore';
import {Step, STEPS} from './onboardingSteps';
import {useUserData} from '@/hooks/useUserData';

export default function useScreenPresentationDetails() {
  const {connectedPlatforms} = useUserData({});

  return useCallback(
    (stepForAnalytics: Step) => {
      const state = useOnboardingStore.getState();
      switch (stepForAnalytics) {
        case STEPS.NAME_AND_BIRTHDATE:
          return {
            fullName: state.fullName,
            birthDate: state.birthdate,
          };
        case STEPS.CREATOR_SEGMENT:
          return {
            segment: state.segment,
          };
        case STEPS.LOCATION:
          return {
            location: state.location,
          };
        case STEPS.GENDER:
          return {
            gender: state.gender,
          };
        case STEPS.SOCIAL_APPS:
          return {
            socialApps: Array.from(state.selectedSocialApps),
          };
        case STEPS.BEST_COLLABS:
          return {
            preferredCollabs: Array.from(state.preferredCollabs),
          };
        case STEPS.SPECIALTIES:
          return {
            specialties: Array.from(state.specialties),
          };
        case STEPS.CAPABILITIES:
          return {
            capabilities: Array.from(state.capabilities),
          };
        case STEPS.PORTFOLIO:
          return {
            profilePictureAvailable: !!state.profilePictureUrl,
            portfolioMediaItemsCount: state.portfolioMediaItems.length,
          };
        case STEPS.BIO:
          return {
            bio: state.bio,
          };
        case STEPS.SYNC_PLATFORMS:
          return {
            connectedPlatforms,
          };
        case STEPS.COMPLETION:
        case STEPS.REONBOARDING:
        default:
          return {};
      }
    },
    [connectedPlatforms]
  );
}
