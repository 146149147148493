import React, {useCallback} from 'react';
import clsx from 'clsx';
import {Button} from '@lightricks/react-design-system';
import styles from './MultiSelect.module.scss';

export interface MultiSelectProps<T> {
  items: T[];
  selectedItems: T[];
  onSelectionChange: (items: T[]) => void;
  renderItem: (
    item: T,
    isSelected: boolean,
    isDisabled: boolean
  ) => React.ReactNode;
  numberOfColumns?: number;
  className?: string;
  itemClassName?: string;
  singleSelect?: boolean;
  getItemKey?: (item: T) => string | number;
  maxSelectedItems?: number;
}

export default function MultiSelect<T>({
  items,
  selectedItems,
  onSelectionChange,
  renderItem,
  numberOfColumns = 1,
  className,
  itemClassName,
  singleSelect = false,
  getItemKey,
  maxSelectedItems = Infinity,
}: MultiSelectProps<T>) {
  const handleItemClick = useCallback(
    (item: T, isSelected: boolean) => {
      let newSelection: T[];
      if (singleSelect) {
        newSelection = isSelected ? [] : [item];
      } else {
        newSelection = isSelected
          ? selectedItems.filter((i) => i !== item)
          : [...selectedItems, item];
      }
      onSelectionChange(newSelection);
    },
    [selectedItems, singleSelect, onSelectionChange]
  );

  const isSelected = useCallback(
    (item: T) => selectedItems.includes(item),
    [selectedItems]
  );

  const isDisabled = useCallback(
    (item: T) => selectedItems.length >= maxSelectedItems && !isSelected(item),
    [selectedItems, maxSelectedItems, isSelected]
  );

  return (
    <div
      className={clsx(styles.container, className)}
      style={{'--columns': numberOfColumns} as React.CSSProperties}
    >
      {items.map((item, index) => {
        const itemIsDisabled = isDisabled(item);
        const itemIsSelected = isSelected(item);

        return (
          <Button
            appearance="neutral"
            mode="plain"
            size="large"
            key={getItemKey ? getItemKey(item) : index}
            className={clsx(
              styles.item,
              itemIsSelected && styles.selected,
              itemIsDisabled && styles.disabled,
              itemClassName
            )}
            disabled={itemIsDisabled}
            onClick={() => handleItemClick(item, itemIsSelected)}
          >
            {renderItem(item, itemIsSelected, itemIsDisabled)}
          </Button>
        );
      })}
    </div>
  );
}
