import {z} from 'zod';

export const PlatformSchema = z.object({}).passthrough();
export const IdentitySchema = z.object({}).passthrough();
export const PortfolioMediaItemSchema = z.object({}).passthrough();

export const GenderSchema = z.string().nullable();

const SPECIALTIES = [
  'Lifestyle',
  'Fashion',
  'Pop Art',
  'Outdoors',
  'Travel',
  'Healthy Living',
  'Fitness',
  'Tech',
  'Music',
  'Beauty',
  'DIY',
  'Family',
  'Pets',
  'Interior Design',
  'Entertainment',
  'Financial Planning',
  'Food and Beverage',
] as const;
export const SpecialtiesSchema = z.enum(SPECIALTIES);

const CAPABILITIES = [
  'animated gif',
  'blog writing',
  'copywriting',
  'graphic design',
  'illustration',
  'infographics',
  'long-form video',
  'photo/video editing',
  'professional photography',
  'short-form video',
  'stop-motion video',
] as const;
export const CapabilitySchema = z.enum(CAPABILITIES);

const TagSchema = z.object({
  id: z.string(),
  name: SpecialtiesSchema,
});

const ContentPreferenceSchema = z.object({
  id: z.string(),
  kind: CapabilitySchema,
});

export const LocaleSchema = z.object({
  id: z.string(),
  apiUid: z.string(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});

export const ProfileSchema = z.object({
  id: z.string(),
  bio: z.string().nullable(),
  birthDate: z.string().nullable(),
  displayName: z.string().optional(),
  gender: GenderSchema.optional(),
  influencer: z.boolean().nullable(),
  contentCreator: z.boolean().nullable(),
  tags: z.array(TagSchema),
  contentPreferences: z.array(ContentPreferenceSchema).nullable(),
  imageUrl: z.string().url().optional(),
  location: z.string().optional(),
  locale: LocaleSchema.optional(),
  portfolioMediaItems: z.array(PortfolioMediaItemSchema),
  portfolioLink: z.string().optional().nullable(),
});

export const UserSchema = z.object({
  id: z.string(),
  platforms: z.array(PlatformSchema).optional(),
  birthDate: z.string().date().nullable(),
  email: z.string().email(),
  identities: z.array(IdentitySchema).optional(),
  profile: ProfileSchema.optional(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
});

export const ConnectedPlatformsStateSchema = z.object({
  user: UserSchema.optional(),
  avatarUrl: z.string().url().optional(),
  connectedPlatforms: z.array(PlatformSchema),
  distinctConnectedPlatforms: z.array(PlatformSchema),
  brandName: z.string().optional(),
});

export type User = z.infer<typeof UserSchema>;
export type Specialty = z.infer<typeof SpecialtiesSchema>;
export type Capability = z.infer<typeof CapabilitySchema>;
export type Locale = z.infer<typeof LocaleSchema>;
export type Gender = z.infer<typeof GenderSchema>;
