import {useEffect, useState} from 'react';
import {useShallow} from 'zustand/shallow';
import {useOnboardingStore} from './OnboardingStore';
import {STEPS} from './onboardingSteps';

export default function useAutoBio() {
  const [
    step,
    bio,
    fullName,
    location,
    segment,
    specialties,
    capabilities,
    portfolioLink,
    setBio,
  ] = useOnboardingStore(
    useShallow((store) => [
      store.step,
      store.bio,
      store.fullName,
      store.location,
      store.segment,
      store.specialties,
      store.capabilities,
      store.portfolioLink,
      store.setBio,
    ])
  );

  useEffect(() => {
    if (step === STEPS.BIO && bio === undefined) {
      const bioSections = [
        fullName?.trim(),
        location ? `📍  ${location}` : null,
        segment ? `🎯  ${segment}` : null,
        specialties?.size > 0
          ? `✨  ${Array.from(specialties).join(', ')}`
          : null,
        capabilities?.size > 0
          ? `🌟  ${Array.from(capabilities).join(', ')}`
          : null,
        portfolioLink ? `🔗  ${portfolioLink}` : null,
      ].filter(Boolean);

      const defaultBio = bioSections.join('\n');
      setBio(defaultBio);
    }
  }, [
    step,
    bio,
    fullName,
    location,
    segment,
    specialties,
    capabilities,
    portfolioLink,
    setBio,
  ]);
}
