import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@/utils/reactQueryClient';
import userUpdaters from '@/api/updaters/user';

type UpdateBirthdateArgs = {
  onError?: (error: string) => void;
};

function useUpdateBirthdate({onError}: UpdateBirthdateArgs = {}) {
  return useMutation({
    mutationKey: ['updateBirthdate'],
    mutationFn: userUpdaters.updateBirthdate,
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['user']});
    },
    onError,
  });
}

export default useUpdateBirthdate;
