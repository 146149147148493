import {useCallback} from 'react';
import {Step, STEPS} from './onboardingSteps';
import {useOnboardingStore} from './OnboardingStore';
import {SEGMENT_OPTIONS} from './steps/SegmentSelect';
import {GENDER_OPTIONS} from './steps/GenderSelect';
import {SOCIAL_OPTIONS} from './steps/SocialSelect';
import {BRANDS} from './steps/BrandsSelect';
import {SPECIALTIES} from './steps/SpecialtiesSelect';
import {CAPABILITIES} from './steps/CapabilitiesSelect';

const INPUT_OPTIONS = {
  TEXT_INPUT: 'text_input',
  BUTTON_TOGGLE: 'button_toggle',
  DATE_PICKER: 'date_picker',
  ASSET_UPLOAD: 'asset_upload',
};

function mapValueLabel(mapItem: {value: string; label?: string}) {
  const result: {value: string; label?: string; type: string} = {
    value: mapItem.value,
    type: INPUT_OPTIONS.BUTTON_TOGGLE,
  };

  if (mapItem.label !== undefined) {
    result.label = mapItem.label;
  }

  return result;
}

export default function useAnalyticsScreenOptions() {
  const {selectedSocialApps} = useOnboardingStore();
  return useCallback(
    (stepForAnalytics: Step) => {
      switch (stepForAnalytics) {
        case STEPS.NAME_AND_BIRTHDATE:
          return {
            fullName: INPUT_OPTIONS.TEXT_INPUT,
            birthDate: INPUT_OPTIONS.DATE_PICKER,
          };
        case STEPS.CREATOR_SEGMENT:
          return {
            segment: SEGMENT_OPTIONS.map(mapValueLabel),
          };
        case STEPS.LOCATION:
          return {
            location: INPUT_OPTIONS.DATE_PICKER,
          };
        case STEPS.GENDER:
          return {
            gender: GENDER_OPTIONS.map(mapValueLabel),
          };
        case STEPS.SOCIAL_APPS:
          return {
            socialApps: SOCIAL_OPTIONS.map(mapValueLabel),
          };
        case STEPS.BEST_COLLABS:
          return {
            preferredCollabs: BRANDS.map(mapValueLabel),
          };
        case STEPS.SPECIALTIES:
          return {
            specialties: SPECIALTIES.map(mapValueLabel),
          };
        case STEPS.CAPABILITIES:
          return {
            capabilities: CAPABILITIES.map(mapValueLabel),
          };
        case STEPS.PORTFOLIO:
          return {
            profilePicture: INPUT_OPTIONS.ASSET_UPLOAD,
            portfolioMediaItems: INPUT_OPTIONS.ASSET_UPLOAD,
            link: INPUT_OPTIONS.TEXT_INPUT,
          };
        case STEPS.BIO:
          return {
            bio: INPUT_OPTIONS.TEXT_INPUT,
          };
        case STEPS.SYNC_PLATFORMS:
          return {
            selectedSocialApps,
          };
        default:
          return {};
      }
    },
    [selectedSocialApps]
  );
}
