const styles = {
  DialogTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '68px',
    color: '#404040',
    padding: '0 24px',
    height: '68px',
    alignItems: 'center',
    justifyContent: 'left',
    display: 'flex',
  },
  DialogActions: {
    justifyContent: 'space-between',
  },
};

export default styles;
