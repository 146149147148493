import React from 'react';
import {Input} from '@lightricks/react-design-system';
import DateInputWithPicker from 'src/components/DateInputWithPicker/DateInputWithPicker';
import translate from '@/utils/translate';
import styles from './NameAndBirthdate.module.scss';

type NameAndBirthdateProps = {
  nameValue: string;
  onChangeName: (fullName: string) => void;
  birthdateValue: Date | undefined;
  onChangeBirthdate: (date: Date) => void;
};

function NameAndBirthdate(props: NameAndBirthdateProps) {
  const {nameValue, onChangeName, birthdateValue, onChangeBirthdate} = props;

  const handleBirthdateChange = (date: Date | null) => {
    if (date) onChangeBirthdate(date);
  };

  return (
    <div className={styles.nameAndBirthdateContainer}>
      <Input
        placeholder={translate('Full name')}
        value={nameValue}
        onInputChange={onChangeName}
      />
      <DateInputWithPicker
        label=""
        onChange={handleBirthdateChange}
        date={birthdateValue}
        placeholder={translate('Date of birth')}
      />
    </div>
  );
}

export default NameAndBirthdate;
