import api from '../index';

const userNotifications = {
  sendFinishedFlowEmail: async (userId: string, brandName: string) => {
    return api.post('/user/finished_flow_email', {
      userId,
      brandName,
    });
  },

  sendUnfinishedFlowEmail: async (userId: string, brandName: string) => {
    return api.post('/user/unfinished_flow_email', {
      userId,
      brandName,
    });
  },
};

export default userNotifications;
