import React from 'react';
import {Label, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import MultiSelect from '@/components/multi-select';
import styles from './SpecialtiesSelect.module.scss';
import {Specialty} from '@/schema/User';
import {SelectItem} from './selectItem';
import translate from '@/utils/translate';
import {MAX_SPECIALTIES} from '../useStepValidation';

interface SpecialtiesItemProps {
  label: string;
  icon?: React.ReactNode;
}

interface SpecialtiesSelectProps {
  selectedSpecialties: Set<Specialty>;
  onSpecialtiesChange: (segment: Set<Specialty>) => void;
}

export const SPECIALTIES: Array<{
  value: Specialty;
  label: string;
  icon: IconName;
}> = [
  {
    value: 'Lifestyle',
    label: translate('Lifestyle'),
    icon: 'Features-Colorfy',
  },
  {
    value: 'Pop Art',
    label: translate('Pop Art'),
    icon: 'Navigation-Diamond',
  },
  {
    value: 'Healthy Living',
    label: translate('Healthy Living'),
    icon: 'Navigation-Heart-Line',
  },
  {
    value: 'Fitness',
    label: translate('Fitness'),
    icon: 'Navigation-Shopbag-Regular',
  },
  {
    value: 'Outdoors',
    label: translate('Outdoors'),
    icon: 'Features-Tree',
  },
  {
    value: 'Travel',
    label: translate('Travel'),
    icon: 'Navigation-Book',
  },
  {
    value: 'Fashion',
    label: translate('Fashion'),
    icon: 'Features-Clothes-Main',
  },
  {
    value: 'Tech',
    label: translate('Tech & Gadgets'),
    icon: 'Navigation-Phone',
  },
  {
    value: 'Food and Beverage',
    label: translate('Food & Beverage'),
    icon: 'Features-Food',
  },
  {
    value: 'Beauty',
    label: translate('Beauty & Skincare'),
    icon: 'Features-Makeup-Lipstick',
  },
  {
    value: 'Family',
    label: translate('Parenting & Family'),
    icon: 'Features-Face-Main',
  },
  {
    value: 'Music',
    label: translate('Music'),
    icon: 'Features-Music-Music1',
  },
  {
    value: 'DIY',
    label: translate('DIY & Crafts'),
    icon: 'Features-CurrentLook',
  },
  {
    value: 'Pets',
    label: translate('Pets'),
    icon: 'Features-Pets',
  },
  {
    value: 'Interior Design',
    label: translate('Interior Design'),
    icon: 'Features-Rooms',
  },
  {
    value: 'Entertainment',
    label: translate('Entertainment'),
    icon: 'Features-FilmGrain',
  },
  {
    value: 'Financial Planning',
    label: translate('Financial Planning'),
    icon: 'Actions-Trending',
  },
];

const SPECIALTY_OPTIONS: Array<SelectItem<{name: Specialty; id: Specialty}>> =
  SPECIALTIES.map((specialty) => ({
    label: specialty.label,
    image: <Icon name={specialty.icon} size="medium" appearance="neutral" />,
    value: {id: specialty.value, name: specialty.value},
  }));

function SpecialtiesItem({label, icon}: SpecialtiesItemProps) {
  return (
    <div className={styles.specialtiesItem}>
      {icon}
      <div>
        <Label size="md">{label}</Label>
      </div>
    </div>
  );
}

export default function SpecialtiesSelect({
  selectedSpecialties,
  onSpecialtiesChange,
}: SpecialtiesSelectProps) {
  const selectedItems = SPECIALTY_OPTIONS.filter((option) =>
    Array.from(selectedSpecialties).some(
      (specialty) => specialty === option.value.name
    )
  );

  return (
    <MultiSelect
      items={SPECIALTY_OPTIONS}
      selectedItems={selectedItems}
      numberOfColumns={2}
      maxSelectedItems={MAX_SPECIALTIES}
      onSelectionChange={(items) =>
        onSpecialtiesChange(new Set(items.map((item) => item.value.name)))
      }
      renderItem={({label, image}) => (
        <SpecialtiesItem label={label} icon={image} />
      )}
      itemClassName={styles.specialtiesItem}
    />
  );
}
