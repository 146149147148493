import React from 'react';
import Skeleton from '@/components/skeleton';
import styles from './PortfolioCard.module.scss';

type RenderPlaceholderProps = {
  isLoading: boolean;
};

function RenderPlaceholder(props: RenderPlaceholderProps) {
  const {isLoading} = props;
  return isLoading ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height="100%"
      animation="pulse"
    />
  ) : (
    <div className={styles.placeholder} />
  );
}

export default RenderPlaceholder;
