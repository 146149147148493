import {Player} from '@lottiefiles/react-lottie-player';
import React from 'react';
import {Avatar, Body, Headline} from '@lightricks/react-design-system';
import {useShallow} from 'zustand/shallow';
import clsx from 'clsx';
import onboardingLottieAnimation from '@/assets/lotties/rolling_brands.json';
import onboardingLottieAnimationMobile from '@/assets/lotties/rolling_brands_mobile.json';
import {useOnboardingStore} from '../OnboardingStore';
import styles from './OnboardingCompletion.module.scss';
import translate from '@/utils/translate';
import isMobile from '@/utils/identifyDevice';

export const COMPLETION_VIDEO_URL =
  'https://popularpays-ember-assets-production.s3.us-east-1.amazonaws.com/assets/onboarding-completion.mp4';

export const COMPLETION_MOBILE_VIDEO_URL =
  'https://popularpays-ember-assets-production.s3.us-east-1.amazonaws.com/assets/onboarding-completion-mobile.mp4';

export default function OnboardingCompletion() {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.avatarContainer}>
        <OnboardingCompletionAvatar />
      </div>
      <CompletionText />
      <Player
        autoplay
        loop
        src={
          isMobile()
            ? onboardingLottieAnimationMobile
            : onboardingLottieAnimation
        }
      />
    </div>
  );
}

function CompletionText() {
  return (
    <div className={styles.completionText}>
      <Headline size="xl">
        {translate('Discover your next gig with top brands')}
      </Headline>
      <Body size="lg">
        {translate(
          'We’re thrilled to have you onboard. Start collaborating on paid gigs tailored to you.'
        )}
      </Body>
    </div>
  );
}

export function OnboardingCompletionTopHeader() {
  return (
    <div className={clsx(styles.headerContainer, styles.hideOnDesktop)}>
      <video
        src={COMPLETION_MOBILE_VIDEO_URL}
        preload="auto"
        playsInline
        muted
        autoPlay
      />
    </div>
  );
}

export function OnboardingCompletionAvatar() {
  const profilePictureUrl = useOnboardingStore(
    useShallow((state) => state.profilePictureUrl)
  );
  return (
    <Avatar
      size="2xl"
      type="picture"
      src={profilePictureUrl}
      variant="circular"
      className={styles.avatar}
    />
  );
}
