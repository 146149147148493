import {v4 as uuid} from 'uuid';
import {makeAutoObservable} from 'mobx';

import {RootStore} from './RootStore.d';
import {
  FlashMessageData,
  FlashMessageOptions,
} from '../components/flash-messages-service/flashMessageTypes';

type FlashMessageStoreInitialState = {
  flashMessages: FlashMessageData[];
};

const flashMessageStoreInitialState: FlashMessageStoreInitialState = {
  flashMessages: [],
};

class FlashMessageStore {
  root: RootStore;

  store = flashMessageStoreInitialState;

  constructor(root: RootStore) {
    makeAutoObservable(this);
    this.root = root;
  }

  resetStore = () => {
    this.store = flashMessageStoreInitialState;
  };

  addFlashMessage = (options: FlashMessageOptions) => {
    const {flashMessages} = this.store;
    const {
      status = 'success',
      message,
      duration = 6000,
      delay = 0,
      withProgressBar = false,
      closeOnClick = true,
    } = options;

    flashMessages.push({
      id: uuid(),
      status,
      message,
      duration,
      delay,
      withProgressBar,
      closeOnClick,
    });
  };

  removeFlashMessage = (flashMessage: FlashMessageData) => {
    this.store.flashMessages = this.store.flashMessages.filter(
      (fm) => fm.id !== flashMessage.id
    );
  };
}

export default FlashMessageStore;
