import api from '../index';

type PostResponse = ReturnType<typeof api.post>;

type UserUpdaters = {
  addCampaignCreatorMatch: (campaignId: string) => PostResponse;
  acceptTerms: (currentTosVersion: string) => PostResponse;
  updateName: ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) => PostResponse;
  updateBirthdate: (birthDate: Date) => PostResponse;
};

const userUpdaters: UserUpdaters = {
  addCampaignCreatorMatch: async (campaignId: string) => {
    return api.post(
      `/user/campaign_creator_matches`,
      {},
      {
        params: {campaign_invitation: campaignId},
      }
    );
  },

  acceptTerms: async (currentTosVersion: string) => {
    return api.put(`/user/`, {acceptedTosVersion: currentTosVersion});
  },

  updateName: async ({firstName, lastName}) => {
    return api.put(`/user/`, {firstName, lastName});
  },

  updateBirthdate: async (birthDate: Date) => {
    return api.put(`/user/`, {birthDate: birthDate.toISOString()});
  },
};

export default userUpdaters;
