import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import getStores from '@/utils/getStores';
import {FlashMessageData} from './flashMessageTypes';
import FlashMessage from '../flash-message/FlashMessage';

type FlashMessageProps = {
  onCloseCallback?: () => void;
} & FlashMessageData;

const FlashMessageItem = observer((props: FlashMessageProps) => {
  const {
    message,
    duration,
    delay,
    withProgressBar,
    closeOnClick,
    status,
    onCloseCallback,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const visibleTimeout = setTimeout(() => setIsVisible(true), delay);
    if (withProgressBar) {
      return undefined;
    }

    const removeTimeout = setTimeout(
      () => onCloseCallback?.(),
      duration + delay
    );

    return () => {
      clearTimeout(visibleTimeout);
      clearTimeout(removeTimeout);
    };
  }, []);

  const onPress = () => {
    if (closeOnClick) {
      onCloseCallback?.();
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
        cursor: closeOnClick ? 'pointer' : undefined,
      }}
      onClick={onPress}
    >
      <FlashMessage
        message={message}
        onCloseCallback={onCloseCallback}
        style={
          withProgressBar
            ? {
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              }
            : {}
        }
      />
    </div>
  );
});

const FlashMessagesService = observer(() => {
  const {flashMessageStore} = getStores('flashMessageStore');
  const {
    store: {flashMessages},
  } = flashMessageStore;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        zIndex: 100,
        top: 40,
        left: 0,
        right: 0,
        alignItems: 'center',
      }}
    >
      {flashMessages
        .slice()
        .sort((a: FlashMessageProps, b: FlashMessageProps) => a.delay - b.delay)
        .map((flashMessage: FlashMessageProps) => (
          <FlashMessageItem
            key={flashMessage.id}
            {...flashMessage}
            onCloseCallback={() =>
              flashMessageStore.removeFlashMessage(flashMessage)
            }
          />
        ))}
    </div>
  );
});

export default FlashMessagesService;
