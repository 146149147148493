import {useEffect} from 'react';

/**
 * Valid asset kinds for the preload link's "as" attribute
 * These values come directly from the HTML spec for the preload link type
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#as
 */
export type AssetKind =
  | 'audio'
  | 'document'
  | 'embed'
  | 'fetch'
  | 'font'
  | 'image'
  | 'object'
  | 'script'
  | 'style'
  | 'track'
  | 'worker'
  | 'video';

export interface Asset {
  url: string;
  type: AssetKind;
}

/**
 * Hook to prefetch assets (images, videos, etc.) to prevent lag when they're needed
 *
 * @param assets Array of assets to prefetch
 * @returns void
 *
 * @example
 * // Prefetch images and videos
 * usePrefetchAssets([
 *   { url: '/assets/hero.jpg', type: 'image' },
 *   { url: '/assets/intro.mp4', type: 'video' }
 * ]);
 */
const usePrefetchAssets = (assets: Asset[]) => {
  useEffect(() => {
    // Track all created link elements for proper cleanup
    const linkElements: HTMLLinkElement[] = [];

    assets.forEach((asset) => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = asset.type;
      link.href = asset.url;

      document.head.appendChild(link);
      linkElements.push(link);
    });

    // Cleanup: remove all link elements from the document head
    return () => {
      linkElements.forEach((link) => {
        if (document.head.contains(link)) {
          document.head.removeChild(link);
        }
      });
    };
  }, [assets]);
};

export default usePrefetchAssets;
