import React from 'react';
import {Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import {motion} from 'framer-motion';
import clsx from 'clsx';
import {upperFirst} from 'lodash';
import styles from './InfoBox.module.scss';

export type InfoBoxProps = {
  className?: string;
  infoIcon?: IconName;
  infoMessage: string;
  boxStyle: 'purple' | 'blue' | 'green' | 'error';
  handleCloseBox?: () => void;
};

function InfoBox(props: InfoBoxProps) {
  const {
    className,
    boxStyle,
    infoIcon = boxStyle === 'error' ? 'Actions-Patch-Cancel-Line' : undefined,
    infoMessage,
    handleCloseBox,
  } = props;

  return (
    <motion.div
      className={clsx(styles.infoBox, styles[upperFirst(boxStyle)], className)}
      initial={{opacity: 0, height: 0}}
      animate={{opacity: 1, height: 'auto'}}
      exit={{opacity: 0, height: 0}}
      transition={{duration: 0.3, ease: 'easeInOut'}}
    >
      {handleCloseBox && (
        <div
          className={styles.closeButton}
          onClick={handleCloseBox}
          role="button"
          tabIndex={0}
        >
          <Icon
            name="Actions-Close-Small"
            size="large"
            appearance="secondary"
          />
        </div>
      )}
      {infoIcon && (
        <Icon
          name={infoIcon}
          size="large"
          appearance={boxStyle === 'error' ? 'danger' : 'primary'}
        />
      )}
      <p>{infoMessage}</p>
    </motion.div>
  );
}

export default InfoBox;
