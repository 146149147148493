import SIGNUP_BUTTON_PRESSED_SCHEMA from '../../../lib/delta/delta-schemas/ppWebCreatorsSignupButtonPressed';
import {flowNames} from '../../../lib/delta/deltaConstants';
import DeltaHelper from '../../../services/delta/DeltaHelper';
import Delta from '@/utils/wrappers/Delta';

function sendSignupButtonPressedEvent(
  screenId: string,
  signupScreenName: string,
  buttonName: string
) {
  const flow = DeltaHelper.getOrCreateFlow(
    flowNames.brandsSafetyConnectPlatforms
  );
  Delta.sendEvent(SIGNUP_BUTTON_PRESSED_SCHEMA.name, {
    flow_id: flow.flow_id,
    screen_presentation_id: screenId,
    signup_screen_name: signupScreenName,
    button_name: buttonName,
  });
}

export default sendSignupButtonPressedEvent;
