export default {
  user_campaign_list_items: {
    type: 'user_campaign_list_items',
    relationships: {
      deliverables: {type: 'deliverables'},
      preferred_creator_profile: {type: 'preferred_creator_profile'},
      social_network: {type: 'social_network'},
    },
  },
  preferred_creator_profile: {
    type: 'preferred_creator_profile',
    relationships: {
      user_campaign_list_items: {type: 'user_campaign_list_items'},
    },
  },
  user: {
    type: 'users',
    relationships: {
      identities: {type: 'identity'},
      conversations: {type: 'conversations'},
    },
  },
  paid_gig: {
    type: 'paid_gig',
    relationships: {
      answers: {type: 'answers'},
      brand_negotiation: {type: 'brand_negotiation'},
      brief_items: {type: 'brief_items'},
      campaign: {type: 'campaign'},
      conversation: {type: 'conversation'},
      conversations: {type: 'conversations'},
      deliverables: {type: 'deliverables'},
      events: {type: 'events'},
      gift_code: {type: 'gift_code'},
      replaced_by: {type: 'replaced_by'},
      shipment: {type: 'shipment'},
      shipping_address: {type: 'shipping_address'},
      shopify_discount_code: {type: 'shopify_discount_code'},
      social_network: {type: 'social_network'},
    },
  },
  profile: {
    type: 'profile',
    relationships: {
      campaign_creator_match: {type: 'campaign_creator_matches'},
      content_preferences: {type: 'creator_profile_content_preferences'},
      current_brand_collaboration_conversation: {
        type: 'current_brand_collaboration_conversation',
      },
      current_brand_membership: {type: 'current_brand_membership'},
      identities: {type: 'identities'},
      locale: {type: 'locales'},
      memberships: {type: 'memberships'},
      portfolio_media_items: {type: 'portfolio_media_items'},
      profile_progress_report: {type: 'profile_progress_reports'},
      tags: {type: 'tags'},
      // user: { type: 'users' },
    },
  },
  social_network: {
    type: 'social_networks',
    relationships: {
      identity: {type: 'identities'},
    },
  },
  campaign_creator_matches: {
    type: 'campaign_creator_matches',
    relationships: {
      campaign: {type: 'campaign'},
      user_campaign_list_items: {type: 'user_campaign_list_items'},
    },
  },
};
