import React from 'react';
import {
  Icon,
  Headline,
  Label,
  Avatar,
  designSystemToken,
  Button,
} from '@lightricks/react-design-system';
import {AnimatePresence} from 'framer-motion';
import ProgressBar from 'src/components/progress-bar';
import translate from '@/utils/translate';
import InfoBox from '@/components/info-box/InfoBox';
import styles from './SyncPlatforms.module.scss';
import {Platform, isVettablePlatform} from '@/types/platforms';
import PlatformList from '../../../platform-list/PlatformList';

const INFO_MESSAGES = [
  {
    count: 1,
    message: 'components.brand-safety.sign-up.sync-platforms.default',
    icon: 'Navigation-Shopbag-Shield-Stroke' as const,
  },
  {
    count: 2,
    message: 'components.brand-safety.sign-up.sync-platforms.two-platforms',
    icon: 'Actions-Trending' as const,
  },
  {
    count: 3,
    message: 'components.brand-safety.sign-up.sync-platforms.three-platforms',
    icon: 'Navigation-Stats' as const,
  },
] as const;

type SyncPlatformsContentProps = {
  handleBack?: () => void;
  handleSkip: () => void;
  avatarUrl?: string;
  error?: string;
  onErrorDismiss: () => void;
  completionPercentage: number;
  PlatformListProps: {
    selectedPlatforms: Platform[];
    connectedPlatforms: Platform[];
    onConnectPlatform: (platform: Platform) => void;
  };
};

function SyncPlatformsContent({
  handleBack,
  handleSkip,
  avatarUrl,
  error,
  onErrorDismiss,
  PlatformListProps,
  completionPercentage,
}: SyncPlatformsContentProps) {
  const getInfoBoxStyle = (length: number) => {
    switch (length) {
      case 1:
        return 'purple';
      case 2:
        return 'blue';
      default:
        return 'green';
    }
  };

  const infoBoxStyle = getInfoBoxStyle(
    PlatformListProps.connectedPlatforms.length
  );

  return (
    <div className={styles.container} data-testid="sync-platforms">
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <Button
            onClick={handleBack}
            style={{visibility: handleBack ? 'visible' : 'hidden'}}
            data-testid="back-button"
            appearance="neutral"
            mode="plain"
            size="large"
            className={styles.backButton}
          >
            <Icon
              name="Actions-Arrow-Back-Small"
              size="large"
              appearance="primary"
            />
          </Button>
          <span
            onClick={handleSkip}
            role="button"
            tabIndex={0}
            className={styles.skipButton}
          >
            <Label size="lg">{translate('components.brand-safety.skip')}</Label>
          </span>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.progressBarWrapper}>
            <ProgressBar size="md" value={completionPercentage} />
          </div>
          {translate(
            'components.brand-safety.sign-up.sync-platforms.progress',
            {
              progress: completionPercentage.toFixed(0),
            }
          )}
          <div className={styles.titleSection}>
            {avatarUrl ? (
              <Avatar
                size="3xl"
                src={avatarUrl}
                type="picture"
                variant="circular"
                background={designSystemToken('semantic.bg.tertiary')}
              />
            ) : (
              <Headline size="xl" className={styles.avatarPlaceholder}>
                {translate(
                  'components.brand-safety.sign-up.sync-platforms.heading'
                )}
              </Headline>
            )}
            <AnimatePresence mode="wait">
              {error ? (
                <InfoBox
                  key="error-box"
                  className={styles.syncErrorBox}
                  infoMessage={error}
                  handleCloseBox={onErrorDismiss}
                  boxStyle="error"
                />
              ) : (
                <InfoBox
                  key="info-box"
                  infoIcon={INFO_MESSAGES[0].icon}
                  infoMessage={translate(INFO_MESSAGES[0].message)}
                  boxStyle={infoBoxStyle}
                />
              )}
            </AnimatePresence>
          </div>
          <PlatformList
            {...PlatformListProps}
            isConnectablePlatform={isVettablePlatform}
            sortFunction={(a, b) => (isVettablePlatform(a) ? -1 : 1)}
          />
        </div>
      </div>
    </div>
  );
}

export default SyncPlatformsContent;
