import {useEffect} from 'react';

export function saveUtmAttributionToSessionStorage() {
  const searchParams = window.location.search;
  if (searchParams.includes('utm')) {
    sessionStorage.setItem('utm_attribution', searchParams);
  }
}

export function getUtmAttributionFromSessionStorage() {
  return sessionStorage.getItem('utm_attribution');
}

export function useSaveUtmAttributionToSessionStorage() {
  useEffect(() => {
    saveUtmAttributionToSessionStorage();
  }, []);
}
