import React from 'react';
import MapboxSearchInput from 'src/components/mapbox-search-input/MapboxSearchInput';
import {MapboxFeature} from 'src/types/mapbox';
import translate from '@/utils/translate';

type LocationProps = {
  onChangeLocation: (location: MapboxFeature | null) => void;
};

function Location(props: LocationProps) {
  const {onChangeLocation} = props;

  return (
    <MapboxSearchInput
      onSelect={onChangeLocation}
      placeholder={translate('Add your current city')}
    />
  );
}

export default Location;
