import React, {useEffect, useRef, useState} from 'react';
import styles from './InputMui.module.scss';
import {InputMuiProps} from './InputMuiProps';
import Stack from '../stack';

function InputMui(props: InputMuiProps) {
  const {
    maxSign,
    max,
    showOnMax,
    label,
    prefix,
    suffix,
    value,
    name,
    onChange,
    ...rest
  } = props;
  const [showMaxSign, setShowMaxSign] = useState(false);
  const realInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (max && value >= max && showOnMax && maxSign) {
      setShowMaxSign(true);
    } else {
      setShowMaxSign(false);
    }
  }, [value]);

  useEffect(() => {
    const node = realInput.current;
    node?.style.setProperty('width', `${node.value.length * 9.5}px`);
  }, [value]);

  const handleInputChange = (e: any) => {
    if (onChange) {
      onChange(e);
    }
    e.target.style.width = `${e.target.value.length * 9.3}px`;
  };
  return (
    <div className={styles.inputFrame}>
      {label && (
        <label htmlFor={name} className={styles.labelFrame}>
          {label}
        </label>
      )}

      <Stack direction="row">
        {prefix && <div className={styles.inputPrePostFix}>{prefix}</div>}
        <input
          ref={realInput}
          id={name}
          type="number"
          value={value}
          onChange={handleInputChange}
          className={styles.realInput}
          data-testid={name}
        />
        {suffix && <div className={styles.inputPrePostFix}>{suffix}</div>}
        {showMaxSign && <div className={styles.inputPrePostFix}>{maxSign}</div>}
      </Stack>
    </div>
  );
}

export default InputMui;
