// eslint-disable-next-line import/prefer-default-export
export enum NETWORKS {
  INSTAGRAM = 'instagram',
  PINTEREST = 'pinterest',
  TIKTOK = 'tiktok',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export const MEDIA_FILE_TYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/quicktime',
  'video/x-m4v',
  'video/x-ms-wmv',
  'video/mpeg',
  'video/mpg',
  'video/x-msvideo',
  'video/avi',
  'video/x-flv',
  'video/webm',
];
