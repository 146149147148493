import React from 'react';

import {
  FacebookSoloIcon,
  InstagramSoloIcon,
  PinterestSoloIcon,
  TikTokSoloIcon,
  YouTubeSoloIcon,
} from '../../icons';

type SocialNetworkProp = {
  name: string;
  icon?: React.FC;
  title?: string;
};

const NETWORKS_LIST: SocialNetworkProp[] = [
  {
    name: 'facebook',
    icon: FacebookSoloIcon,
  },
  {
    name: 'instagram',
    icon: InstagramSoloIcon,
  },
  {
    name: 'youtube',
    icon: YouTubeSoloIcon,
  },
  {
    name: 'pinterest',
    icon: PinterestSoloIcon,
  },
  {
    name: 'tiktok_creator_marketplace,tiktok_v2', // This filter should show both TTCM and TikTok v2 campaigns
    icon: TikTokSoloIcon,
  },
  {
    name: 'content_creation',
    title: 'Content Creation',
  },
];

const DELIVERABLES_LIST = [
  {
    id: 'photo',
    value: 'photo',
    labelLocaleKey:
      'components.filters-popup.sections.deliverables.checkboxes.photos',
    dataKey: 'photos',
  },
  {
    id: 'video',
    value: 'video',
    labelLocaleKey:
      'components.filters-popup.sections.deliverables.checkboxes.videos',
    dataKey: 'videos',
  },
];

const COMPENSATION_CHECKBOXES_LIST = [
  {
    id: 'fixed',
    value: 'fixed',
    labelLocaleKey:
      'components.filters-popup.sections.compensation.checkboxes.fixed',
    dataKey: 'fixed',
  },
  {
    id: 'negotiable',
    value: 'negotiable',
    labelLocaleKey:
      'components.filters-popup.sections.compensation.checkboxes.negotiable',
    dataKey: 'negotiable',
  },
];

export const COMPENSATION_CHECKBOXES_SECTION = {
  titleLocaleKey: 'components.filters-popup.sections.compensation.title',
  subtitleLocaleKey: 'components.filters-popup.sections.compensation.subtitle',
  list: COMPENSATION_CHECKBOXES_LIST,
};

export const DELIVERABLES_CHECKBOXES_SECTION = {
  titleLocaleKey: 'components.filters-popup.sections.deliverables.title',
  subtitleLocaleKey: 'components.filters-popup.sections.deliverables.subtitle',
  list: DELIVERABLES_LIST,
};

export const SOCIAL_NETWORKS_SECTION = {
  titleLocaleKey: 'components.filters-popup.sections.social-networks.title',
  subtitleLocaleKey:
    'components.filters-popup.sections.social-networks.subtitle',
  list: NETWORKS_LIST,
};

export const VERTICAL_TAGS_SECTION = {
  titleLocaleKey: 'components.filters-popup.sections.vertical-tags.title',
  subtitleLocaleKey: 'components.filters-popup.sections.vertical-tags.subtitle',
};

export const FOLLOWERS_COUNT_SECTION = {
  titleLocaleKey: 'components.filters-popup.sections.followers-count.title',
  subtitleLocaleKey:
    'components.filters-popup.sections.followers-count.subtitle',
  name: 'followers',
};
