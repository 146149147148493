import {styled} from '@mui/material/styles';
import {ToggleButton} from '@mui/material';

const SocialNetworksToggleButton = styled(ToggleButton)`
  &.MuiButtonBase-root {
    &.MuiToggleButton-root {
      &.MuiToggleButton-sizeMedium {
        &.MuiToggleButton-standard {
          min-width: 46px;
          height: 36px;
          color: #404040;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          font-size: 16px;
          font-weight: 400;
          padding: 10px;
          text-transform: none;
          line-height: 23px;
          margin-top: 0;
          margin-left: 0;
          box-sizing: border-box;

          &.Mui-selected {
            background-color: #f4f4f4;
            border: 2px solid #404040;
            box-sizing: border-box;
          }
        }
      }
    }
  }
`;

export default SocialNetworksToggleButton;
