import React from 'react';
import {Headline, Body, Icon, Label} from '@lightricks/react-design-system';
import {Player} from '@lottiefiles/react-lottie-player';
import translate from '@/utils/translate';
import styles from './FinishedFlow.module.scss';
import sucessAnimation from '../../../../assets/lotties/brand-safety/success_animation.json';

type FinishedFlowProps = {
  brandName: string;
  redirectToHome: () => void;
};

function FinishedFlow({brandName, redirectToHome}: FinishedFlowProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Player
          autoplay
          loop={false}
          keepLastFrame
          src={sucessAnimation}
          className={styles.animation}
        />
        <div className={styles.text}>
          <Headline size="lg">
            {translate('components.brand-safety.sign-up.finished-flow.all-set')}
          </Headline>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate(
                'components.brand-safety.sign-up.finished-flow.in-the-running',
                {
                  brandName,
                }
              )}
            </Body>
          </div>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate(
                'components.brand-safety.sign-up.finished-flow.account-live'
              )}
            </Body>
          </div>
          <div className={styles.list}>
            <Icon name="Actions-Accept" size="large" appearance="primary" />
            <Body size="lg">
              {translate(
                'components.brand-safety.sign-up.finished-flow.check-email'
              )}
            </Body>
          </div>
        </div>
        <span
          onClick={redirectToHome}
          className={styles.finishFlowButton}
          role="button"
          tabIndex={0}
        >
          <Label size="lg">
            {translate(
              'components.brand-safety.sign-up.finished-flow.discover-more-campaigns'
            )}
          </Label>
        </span>
      </div>
    </div>
  );
}

export default FinishedFlow;
