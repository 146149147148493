import clsx from 'clsx';
import React, {useEffect, useMemo, useState} from 'react';
import styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
  className?: string;
  testID?: string;
  value: number;
  fillColor?: string;
  backgroundColor?: string;
  size?: 'sm' | 'md' | 'lg';
  useRelativeGradientPercentage?: boolean;
}

function ProgressBar(props: ProgressBarProps) {
  const {
    className,
    testID = 'progress-bar',
    value,
    size = 'sm',
    fillColor,
    backgroundColor,
    useRelativeGradientPercentage = false,
  } = props;

  const [valueProxy, setValueProxy] = useState(0);

  useEffect(
    function animateInitialValue() {
      const normalizedValue = value <= 1 ? value * 100 : value;
      const timeout = setTimeout(() =>
        setValueProxy(Math.min(100, normalizedValue))
      );
      return () => clearTimeout(timeout);
    },
    [value]
  );

  const fillerRelativePercentage =
    useRelativeGradientPercentage && valueProxy > 0
      ? (100 / valueProxy) * 100
      : 0;

  const fillColorStyle: React.CSSProperties = useMemo(() => {
    const style: React.CSSProperties = {};

    if (useRelativeGradientPercentage) {
      style.width = `${fillerRelativePercentage}%`;
    }

    if (fillColor) {
      style.background = fillColor;
    }

    return style;
  }, [fillColor, fillerRelativePercentage, useRelativeGradientPercentage]);

  const barContainerStyle: React.CSSProperties = useMemo(() => {
    if (!backgroundColor) {
      return {};
    }
    return {
      background: backgroundColor,
    };
  }, [backgroundColor]);

  return (
    <div
      data-testid={testID}
      className={clsx(className, styles.progressBarContainer)}
    >
      <div
        className={clsx(styles.barContainer, styles[size])}
        style={barContainerStyle}
      >
        <div
          data-testid="progress-bar-filler"
          className={styles.filler}
          style={{width: `${valueProxy}%`}}
        >
          <div className={styles.fillerBackground} style={fillColorStyle} />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
