import React, {useState, useEffect, useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import useAuthenticate from '../../../hooks/use-authenticate';
import useToken from '../../../hooks/use-token';
import useNavigation from '../../../hooks/use-navigation';
import translate from '@/utils/translate';
import authRequests from '../../../api/auth';
import emailValidator from '@/utils/validators/email';
import OtpVerification from '../brand-safety-sign-up/components/OtpVerification';
import BrandIntro from '../brand-safety-sign-up/components/BrandIntro';
import EmailConfirmation from '../brand-safety-sign-up/components/EmailConfirmation';

const STEPS = {
  BRAND_INTRO: 1,
  EMAIL_CONFIRMATION: 2,
  OTP_VERIFICATION: 3,
} as const;

type Step = (typeof STEPS)[keyof typeof STEPS];

const PLATFORMS = ['Instagram', 'TikTok', 'YouTube', 'Facebook'] as const;

function BrandSafetySignInFlow() {
  const [searchParams] = useSearchParams();
  const {onSubmit: onLogin} = useAuthenticate();
  const {setToken} = useToken();
  const navigation = useNavigation();
  const [currentStep, setCurrentStep] = useState<Step>(STEPS.BRAND_INTRO);
  const [form, setForm] = useState<{
    email: string;
    isTermsChecked: boolean;
  }>({
    email: searchParams.get('email') || '',
    isTermsChecked: true,
  });
  const [formValidation, setFormValidation] = useState({
    email: false,
    isTermsChecked: true,
  });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    isTermsChecked: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const brandName = searchParams.get('brand') || undefined;

  const updateForm = useCallback((field: string, value: any) => {
    setForm((prevForm) => ({...prevForm, [field]: value}));
    if (field === 'email') {
      setValidationErrors((prev) => ({...prev, email: ''}));
      setEmailErrorMessage('');
    }
  }, []);

  const validateEmail = useCallback(() => {
    const isValid = emailValidator(form.email);
    setValidationErrors((prev) => ({
      ...prev,
      email: isValid ? '' : translate('components.login-form.errors.format'),
    }));
    setFormValidation((prev) => ({...prev, email: isValid}));
    return isValid;
  }, [form.email]);

  useEffect(() => {
    if (form.email) {
      validateEmail();
    }
  }, [form.email, validateEmail]);

  const handleLogin = async () => {
    if (!formValidation.email || isSubmitting) {
      setEmailErrorMessage(
        translate('components.login-form.invalid-email-error')
      );
      return;
    }

    setIsSubmitting(true);
    const success = await onLogin('login', form.email, setEmailErrorMessage);
    setIsSubmitting(false);

    if (success) {
      setIsOtpModalOpen(true);
      setCurrentStep(STEPS.OTP_VERIFICATION);
    }
  };

  const handleOtpSuccess = async (otp: string) => {
    try {
      const {sessionData} = await authRequests.login({
        ...form,
        password: otp,
      });
      const tokenSaved = await setToken(sessionData);
      if (!tokenSaved) throw new Error('Token not saved');

      const url = `/connect-platforms?selectedPlatforms=${encodeURIComponent(
        PLATFORMS.join(',')
      )}&on_platform_sync=true`;

      navigation.navigate(url);
      setIsOtpModalOpen(false);
    } catch (error) {
      setEmailErrorMessage(translate('components.login-form.errors.default'));
      setIsOtpModalOpen(false);
    }
  };

  const setEmailValidation = useCallback((isValid: boolean) => {
    setFormValidation((prev) => ({...prev, email: isValid}));
  }, []);

  const setEmailValue = useCallback((value: string) => {
    setForm((prev) => ({...prev, email: value}));
    setEmailErrorMessage('');
  }, []);

  const emailValidators = [
    {
      validator: (email: string) => email !== '',
      errorMessage: translate('components.login-form.empty-email-error'),
    },
    {
      validator: emailValidator,
      errorMessage: translate('components.login-form.invalid-email-error'),
    },
  ];

  const renderStepContent = () => {
    const commonProps = {
      form,
      updateForm,
      validationErrors,
      isSubmitting,
      setCurrentStep,
      validateEmail,
      isTermsChecked: true,
    };

    switch (currentStep) {
      case STEPS.BRAND_INTRO:
        return (
          <BrandIntro
            brandName={brandName}
            mainButtonClick={() => setCurrentStep(STEPS.EMAIL_CONFIRMATION)}
            mainButtonText={translate(
              'components.login-form.brand-safety.login'
            )}
          />
        );
      case STEPS.EMAIL_CONFIRMATION:
        return (
          <EmailConfirmation
            {...commonProps}
            handleJoinNow={handleLogin}
            handleBack={() => setCurrentStep(STEPS.BRAND_INTRO)}
            setEmailValidation={setEmailValidation}
            emailValidators={emailValidators}
            emailErrorMessage={emailErrorMessage}
            setEmailValue={setEmailValue}
            hideTermsCheckbox
            hideLoginLink
            updateForm={() => {}}
          />
        );
      case STEPS.OTP_VERIFICATION:
        return (
          <OtpVerification
            isOpen={isOtpModalOpen}
            setIsOpen={setIsOtpModalOpen}
            email={form.email}
            onSuccess={handleOtpSuccess}
            onGoBack={() => setCurrentStep(STEPS.EMAIL_CONFIRMATION)}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderStepContent()}</>;
}

export default BrandSafetySignInFlow;
