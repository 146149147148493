import React, {useEffect, useState} from 'react';
import {Route, Routes, Navigate, useSearchParams} from 'react-router-dom';
import AppFooter from '@/components/app-footer';
import {getEnv} from '@/config/environment';
import getStores from '@/utils/getStores';
import useParams from '@/hooks/use-params';
import SignUp from '@/views/auth/sign-up/SignUp';
import BrandSafetySignUpFlow from '@/views/auth/brand-safety-sign-up/BrandSafetySignUpFlow';
import BrandSafetySignInFlow from '@/views/auth/brand-safety-sign-in/BrandSafetySignInFlow';
import Login from '@/views/auth/login/Login';
import styles from './AuthRoutes.module.scss';
import isMobile from '@/utils/identifyDevice';
import {PopularPaysBanner} from '@/components/icons';
import {useSaveUtmAttributionToSessionStorage} from '@/utils/utmAttributionTracker';

function AuthRoutes() {
  const {rootStore} = getStores('rootStore');
  const params = useParams();
  const [searchParams] = useSearchParams();
  const scInviteCode = searchParams.get('sc_invite_code');
  const onPlatformSync = searchParams.get('on_platform_sync');
  const isMobileDevice = isMobile();

  useSaveUtmAttributionToSessionStorage();

  const isBrandSafetyFlow = scInviteCode || onPlatformSync;

  const [preloadedTransparentIframe, setPreloadedTransparentIframe] =
    useState<React.ReactNode | null>(null);

  useEffect(() => {
    setPreloadedTransparentIframe(
      <div className={styles.iframeWrapper}>
        <iframe
          src={`${getEnv().VITE_DOMAIN_HOST_IFRAME}/not/found`}
          title="Ember iFrame"
        />
      </div>
    );
  }, []);

  useEffect(() => {
    if (params.campaignId) {
      rootStore.setRedirectUrlAfterAuth(
        `/campaigns/${params.campaignId}/apply`
      );
    }
  }, []);

  return (
    <div
      className={
        isBrandSafetyFlow
          ? styles.brandSafetyAuthNavigator
          : styles.authNavigator
      }
    >
      {!isBrandSafetyFlow && (
        <div className={styles.authMediaWrapper}>
          <img
            className={styles.authMedia}
            src="/assets/images/welcome.jpg"
            alt="auth page"
          />
        </div>
      )}
      <div
        className={
          isBrandSafetyFlow ? styles.brandSafetyAuthForm : styles.authForm
        }
      >
        {!isBrandSafetyFlow && !isMobileDevice && (
          <div className={styles.popularPaysLogo}>
            <PopularPaysBanner width={170} height={45} />
          </div>
        )}
        <div className={styles.authFormContent}>
          <Routes>
            <Route
              path="/auth/login"
              element={onPlatformSync ? <BrandSafetySignInFlow /> : <Login />}
            />
            <Route
              path="/auth/register"
              element={scInviteCode ? <BrandSafetySignUpFlow /> : <SignUp />}
            />
            <Route path="/auth/*" element={<Navigate to="/auth/login" />} />
            <Route path="/" element={<Navigate to="/auth/login" />} />
            <Route path="/campaigns/:campaignId/apply" element={<Login />} />
            <Route path="*" element={<Navigate to="/not/found" />} />
          </Routes>
          {!isBrandSafetyFlow && isMobileDevice && (
            <div className={styles.popularPaysLogo}>
              <PopularPaysBanner width={120} height={30} />
            </div>
          )}
          {!isBrandSafetyFlow && <AppFooter />}
        </div>
      </div>
      {preloadedTransparentIframe}
    </div>
  );
}

export default AuthRoutes;
