import {navigateWithAnalytics} from '@/utils/wrappers/Delta';
import {getEnv} from '../../config/environment';
import constructRedirectUrls from '@/utils/redirectUrlUtils';

export default async function authenticateInstagram(
  userId: string | null,
  redirectQueryParams: Record<string, string>
) {
  const authUrl = getInstagramAuthUrl(userId, redirectQueryParams);
  await navigateWithAnalytics(authUrl);
}

const getInstagramAuthUrl = (
  userId: string | null,
  redirectQueryParams: Record<string, string>
): string => {
  const {successRedirectUrl, failureRedirectUrl} = constructRedirectUrls({
    provider: 'instagram',
    additionalParams: redirectQueryParams,
  });

  const facebookAppId = getEnv().FACEBOOK_APP_ID;
  const serverCallbackUri = getEnv().META_SERVER_CALLBACK_URI;

  const state = {
    network: 'instagram',
    success_redirect_url: successRedirectUrl,
    failure_redirect_url: failureRedirectUrl,
    user_id: userId,
    redirect_uri: serverCallbackUri,
  };

  const scopes = [
    'business_management',
    'instagram_basic',
    'instagram_manage_insights',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_show_list',
  ];

  const authUrl = new URL('https://www.facebook.com/v16.0/dialog/oauth');
  authUrl.searchParams.set('client_id', facebookAppId);
  authUrl.searchParams.set('redirect_uri', serverCallbackUri);
  authUrl.searchParams.set('response_type', 'code,granted_scopes');
  authUrl.searchParams.set('scope', scopes.join(','));
  authUrl.searchParams.set('state', JSON.stringify(state));

  return authUrl.toString();
};
