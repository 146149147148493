import {useNavigate} from 'react-router-dom';
import {navigateWithAnalytics} from '@/utils/wrappers/Delta';

const isExternalUrl = (url: string): boolean => {
  return url.startsWith('http') || url.startsWith('www.');
};

function useNavigation() {
  const navigateProxy = useNavigate();

  const navigate = async (url: string, params?: Record<string, unknown>) => {
    if (isExternalUrl(url)) {
      await navigateWithAnalytics(url);
    }

    return navigateProxy(url, params);
  };

  const goBack = () => {
    return navigateProxy(-1);
  };

  return {navigate, goBack};
}

export default useNavigation;
