import React from 'react';
import {ToggleButtonProps} from './ToggleButtonProps';
import VerticalTagsToggleButton from './vertical-tags-toggle-button/VerticalTagsToggleButton';
import SocialNetworksToggleButton from './social-networks-toggle-button';

function ToggleButtonMui(props: ToggleButtonProps) {
  const {testID, type, children, selected, ...rest} = props;

  if (type === 'tags') {
    return (
      <VerticalTagsToggleButton
        {...rest}
        selected={selected}
        data-testid={testID}
      >
        {children}
      </VerticalTagsToggleButton>
    );
  }

  return (
    <SocialNetworksToggleButton
      {...rest}
      selected={selected}
      data-testid={testID}
    >
      {children}
    </SocialNetworksToggleButton>
  );
}

export default ToggleButtonMui;
