import {useShallow} from 'zustand/shallow';
import {useEffect} from 'react';
import {SocialPlatform} from '@/types/platforms';
import isValidDate from '@/utils/validators/date';
import calculateAge from '@/utils/calculateAge';
import assertUnreachable from '@/utils/assertUnreachable';
import {useOnboardingStore, OnboardingState} from './OnboardingStore';
import {STEPS, type Step} from './onboardingSteps';
import translate from '@/utils/translate';
import {useUserData} from '@/hooks/useUserData';

const MIN_AGE = 18;
export const MAX_SPECIALTIES = 3;
export const MAX_PREFERRED_COLLABS = 3;

const isBirthdateValid = (birthdate?: Date) => isValidDate(birthdate);
const meetsAgeRequirement = (birthdate?: Date) =>
  isBirthdateValid(birthdate) && calculateAge(birthdate) >= MIN_AGE;

export function isStepValid(
  step: Step,
  state: OnboardingState,
  connectedPlatforms: SocialPlatform[]
) {
  switch (step) {
    case STEPS.NAME_AND_BIRTHDATE: {
      const isNameValid = Boolean(state.fullName?.trim());

      return isNameValid && meetsAgeRequirement(state.birthdate);
    }
    case STEPS.CREATOR_SEGMENT:
      return Boolean(state.segment);
    case STEPS.LOCATION:
      return Boolean(state.location);
    case STEPS.GENDER:
      return state.gender !== undefined;
    case STEPS.SOCIAL_APPS:
      return state.selectedSocialApps.size > 0;
    case STEPS.BEST_COLLABS:
      return (
        state.preferredCollabs.size > 0 &&
        state.preferredCollabs.size <= MAX_PREFERRED_COLLABS
      );
    case STEPS.SPECIALTIES:
      return (
        state.specialties.size > 0 && state.specialties.size <= MAX_SPECIALTIES
      );
    case STEPS.CAPABILITIES:
      return state.capabilities.size > 0;
    case STEPS.BIO:
      return state.bio !== undefined;
    case STEPS.PORTFOLIO:
      return (
        (state.segment === 'creator' &&
          state.portfolioMediaItems.length > 0 &&
          state.profilePictureUrl !== undefined) ||
        state.segment === 'influencer'
      );
    case STEPS.COMPLETION:
    case STEPS.INSTAGRAM_INSTRUCTIONS:
    case STEPS.REONBOARDING:
      return true;
    case STEPS.SYNC_PLATFORMS:
      return (
        state.segment === 'creator' ||
        (state.segment === 'influencer' && connectedPlatforms.length > 0)
      );
    default:
      return assertUnreachable(step);
  }
}

function useStepValidation() {
  const [
    step,
    fullName,
    birthdate,
    segment,
    gender,
    selectedSocialApps,
    location,
    preferredCollabs,
    specialties,
    capabilities,
    portfolioMediaItems,
    profilePictureUrl,
    bio,
  ] = useOnboardingStore(
    useShallow((state) => [
      state.step,
      state.fullName,
      state.birthdate,
      state.segment,
      state.gender,
      state.selectedSocialApps,
      state.location,
      state.preferredCollabs,
      state.specialties,
      state.capabilities,
      state.portfolioMediaItems,
      state.profilePictureUrl,
      state.bio,
    ])
  );

  const {connectedPlatforms} = useUserData({});

  const [openBanner, closeBanner, setStepValidity] = useOnboardingStore(
    useShallow((state) => [
      state.openBanner,
      state.closeBanner,
      state.setStepValidity,
    ])
  );

  useEffect(() => {
    const state = useOnboardingStore.getState();

    if (step === STEPS.NAME_AND_BIRTHDATE) {
      if (isBirthdateValid(birthdate) && !meetsAgeRequirement(birthdate)) {
        openBanner({
          title: translate('Popular Pays is for creators 18+'),
          children: translate('Come back then—we can’t wait to have you!'),
          severity: 'error',
        });
      }

      if (meetsAgeRequirement(birthdate)) {
        closeBanner();
      }
    }

    setStepValidity(isStepValid(step, state, connectedPlatforms));
  }, [
    step,
    fullName,
    birthdate,
    segment,
    gender,
    selectedSocialApps,
    location,
    preferredCollabs,
    specialties,
    capabilities,
    setStepValidity,
    closeBanner,
    openBanner,
    connectedPlatforms,
    portfolioMediaItems,
    profilePictureUrl,
    bio,
  ]);
}

export default useStepValidation;
