import clsx from 'clsx';
import React, {useMemo, useRef, useState, useEffect} from 'react';
import {
  Avatar,
  Body,
  Button,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import {ButtonProps} from '@lightricks/react-design-system/dist/components/button/ButtonProps';
import type {PortfolioMediaItem} from '@/types/portfolioMediaItem';
import calculateAge from '@/utils/calculateAge';
import pluralize from '@/utils/pluralize';
import translate from '@/utils/translate';
import {MEDIA_FILE_TYPES} from '@/utils/constants';
import RenderPlaceholder from './RenderPlaceholder';
import styles from './PortfolioCard.module.scss';

export interface PortfolioCardProps {
  className?: string;
  testID?: string;
  fullName: string;
  birthdate?: Date;
  location?: string;
  specialties: string[];
  profilePictureUrl?: string;
  portfolioMediaItems?: PortfolioMediaItem[];
  onChangeProfilePicture: (file: File) => void;
  onChangePortfolioMediaItems: (files: Set<File>) => void;
  onError: (title: string, body: string) => void;
}

const MAX_MEDIA_ITEMS = 3;

function getAgeLocationText(age: number, locationName?: string): string {
  if (age > 0 && locationName) {
    return `${age} . ${locationName}`;
  }
  return age > 0 ? `${age} years old` : locationName || '';
}

function createMediaItemsWithPlaceholders(
  mediaItems: PortfolioMediaItem[],
  desiredLength: number,
  isLoading: boolean
): PortfolioMediaItem[] {
  const updatedMediaItems = [...mediaItems];

  while (updatedMediaItems.length < desiredLength) {
    updatedMediaItems.push({
      type: 'placeholder',
      isLoading,
      url: '',
    });
  }

  return updatedMediaItems;
}

function renderUploadButton(
  buttonClassName: string,
  iconName: IconName,
  appearance: ButtonProps['appearance'],
  mode: ButtonProps['mode'],
  inputRef: React.RefObject<HTMLInputElement>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  accept: string,
  multiple: boolean
) {
  return (
    <>
      <Button
        className={buttonClassName}
        appearance={appearance}
        mode={mode}
        size="small"
        icon={<Icon size="small" appearance="primary" name={iconName} />}
        onClick={() => inputRef.current?.click()}
      />
      <input
        type="file"
        ref={inputRef}
        style={{display: 'none'}}
        accept={accept}
        multiple={multiple}
        onChange={onChange}
      />
    </>
  );
}

function PortfolioCard(props: PortfolioCardProps) {
  const {
    testID = 'portfolio-card',
    className,
    fullName,
    birthdate,
    location,
    specialties,
    profilePictureUrl,
    portfolioMediaItems = [],
    onChangeProfilePicture,
    onChangePortfolioMediaItems,
    onError,
  } = props;

  const age = calculateAge(birthdate);
  const locationName = location || '';
  const [mediaItems, setMediaItems] = useState(portfolioMediaItems);

  const shouldScroll = useRef(false);
  const profilePictureInputRef = useRef<HTMLInputElement | null>(null);
  const mediaItemsInputRef = useRef<HTMLInputElement | null>(null);
  const mediaItemsContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const updatedMediaItems = createMediaItemsWithPlaceholders(
      portfolioMediaItems,
      mediaItems.length,
      true
    );

    setMediaItems(updatedMediaItems);

    if (shouldScroll.current) {
      mediaItemsContainerRef?.current?.scrollTo({
        left: 90000,
        behavior: 'smooth',
      });
    }
  }, [portfolioMediaItems, mediaItems.length]);

  const mediaItemsToShow = useMemo(() => {
    return createMediaItemsWithPlaceholders(
      mediaItems.slice(0, MAX_MEDIA_ITEMS),
      3,
      false
    );
  }, [mediaItems]);

  const handleProfilePictureChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      onChangeProfilePicture(file);
    }
  };

  const handleMediaItemsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {files} = event.target;
    const newFilesCount = files ? files.length : 0;
    const nonPlaceholderItemsCount = mediaItems.filter(
      (item) => item.type !== 'placeholder'
    ).length;
    if (nonPlaceholderItemsCount + newFilesCount > MAX_MEDIA_ITEMS) {
      const pluralizedTitle = pluralize(
        newFilesCount,
        translate('Error Saving Media Item'),
        translate('Error Saving Media Items')
      );
      onError(
        pluralizedTitle,
        translate('You can only upload {max} media items.', {
          max: MAX_MEDIA_ITEMS,
        })
      );
      return;
    }

    const desiredLength = nonPlaceholderItemsCount + newFilesCount;
    const updatedMediaItems = createMediaItemsWithPlaceholders(
      mediaItems,
      desiredLength,
      true
    );

    shouldScroll.current = true;
    setMediaItems(updatedMediaItems);
    onChangePortfolioMediaItems(new Set(Array.from(files || [])));
  };

  return (
    <div
      data-testid={testID}
      className={clsx(className, styles.portfolioCardContainer)}
    >
      <div className={styles.portfolioMediaItemsContainer}>
        <div
          className={styles.portfolioMediaItems}
          ref={mediaItemsContainerRef}
        >
          {mediaItemsToShow.map((mediaItem, index) => (
            <div
              key={mediaItem.url || index}
              className={styles.portfolioMediaItemWrapper}
            >
              {mediaItem.type === 'photo' && (
                <img src={mediaItem.url} alt={`Portfolio item ${index + 1}`} />
              )}
              {mediaItem.type === 'video' && (
                <video autoPlay loop muted>
                  <source src={mediaItem.url} type="video/mp4" />
                  <track kind="captions" />
                  Your browser does not support the video tag.
                </video>
              )}
              {mediaItem.type === 'placeholder' && (
                <RenderPlaceholder isLoading={!!mediaItem.isLoading} />
              )}
            </div>
          ))}
        </div>
        {renderUploadButton(
          styles.uploadPortfolioMediaItemsButton,
          'Actions-Upload2',
          'overlay',
          'filled',
          mediaItemsInputRef,
          handleMediaItemsChange,
          MEDIA_FILE_TYPES.join(','),
          true
        )}
      </div>
      <div className={styles.profileSection}>
        <div className={styles.avatarContainer}>
          <Avatar
            size="2xl"
            className={styles.avatar}
            type={profilePictureUrl ? 'picture' : 'placeholder'}
            src={profilePictureUrl}
            variant="circular"
          />
          {renderUploadButton(
            styles.uploadProfilePictureButton,
            'Features-MagicPen',
            'neutral',
            'elevated',
            profilePictureInputRef,
            handleProfilePictureChange,
            'image/*',
            false
          )}
        </div>
        <div className={styles.profileInfo}>
          <Headline size="sm" className={styles.fullName}>
            {fullName}
          </Headline>
          <Body size="md" className={styles.ageLocation}>
            {getAgeLocationText(age, locationName)}
          </Body>
          <Body size="lg" className={styles.specialties}>
            {specialties.join(' . ')}
          </Body>
        </div>
      </div>
    </div>
  );
}

export default PortfolioCard;
