import React from 'react';
import {Body, Headline, Icon} from '@lightricks/react-design-system';
import MultiSelect from '../../../components/multi-select';
import styles from './SegmentSelect.module.scss';
import {Segment} from '../OnboardingStore';
import {SelectItem} from './selectItem';
import translate from '@/utils/translate';

interface SegmentItemProps {
  label: string;
  description: string | undefined;
  icon?: React.ReactNode;
}

interface SegmentSelectProps {
  selectedSegment: Segment | undefined;
  onSegmentChange: (segment: Segment) => void;
}

export const SEGMENT_OPTIONS: SelectItem<Segment>[] = [
  {
    label: translate('Creator'),
    description: translate('Producing creatives for brands'),
    image: <Icon name="Features-Glitter" size="medium" appearance="neutral" />,
    value: 'creator',
  },
  {
    label: translate('Influencer'),
    description: translate(
      'Featuring brands in your content to share with your audience'
    ),
    image: (
      <Icon name="Navigation-Megaphone" size="medium" appearance="neutral" />
    ),
    value: 'influencer',
  },
];

function SegmentItem({label, description, icon}: SegmentItemProps) {
  return (
    <div className={styles.segmentItemInner}>
      {icon}
      <div className={styles.segmentText}>
        <Headline size="xs">{label}</Headline>
        <Body size="md" className={styles.segmentTextDescription}>
          {description}
        </Body>
      </div>
    </div>
  );
}

export default function SegmentSelect({
  selectedSegment,
  onSegmentChange,
}: SegmentSelectProps) {
  const selectedItem = SEGMENT_OPTIONS.find(
    (option) => option.value === selectedSegment
  );

  return (
    <MultiSelect
      items={SEGMENT_OPTIONS}
      selectedItems={selectedItem ? [selectedItem] : []}
      onSelectionChange={(items) => onSegmentChange(items[0]?.value)}
      renderItem={({label, description, image}) => (
        <SegmentItem label={label} description={description} icon={image} />
      )}
      itemClassName={styles.segmentItem}
      singleSelect
    />
  );
}
