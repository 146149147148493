import React from 'react';
import styles from './NotFound.module.scss';
import AppFooter from '../../components/app-footer';
import translate from '@/utils/translate';
import {PopularPaysLogo, Thumby} from '../../components/icons';
import LinkButton from '../../components/link-button';

function Content() {
  return (
    <div className={styles.content}>
      <div className={styles.thumby}>
        <Thumby />
      </div>
      <span className={styles.titleText}>
        {translate('components.not-found.error-code')}
      </span>
      <span className={styles.bodyText}>
        {translate('components.not-found.body')}
      </span>
      <LinkButton url="/" to={{screen: 'Home', url: '/'}}>
        <span className={styles.homeLink}>
          {translate('components.not-found.go-home')}
        </span>
      </LinkButton>
    </div>
  );
}

function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.logoContainer}>
          <PopularPaysLogo style={{transform: [{scale: 0.8}]}} />
        </div>
        <Content />
        <AppFooter />
      </div>
    </div>
  );
}

export default NotFound;
