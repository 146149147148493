/* eslint-disable import/prefer-default-export */
import {useWizardSignupEvents} from '@/hooks/use-wizard-signup-events/useWizardSignupEvents';
import {flowNames} from '@/lib/delta/deltaConstants';
import {useConnectPlatformsStore} from './store/ConnectPlatformStore';
import {CONNECT_PLATFORMS_STEPS_UUID_MAP} from '../constants/constants';

export const useConnectPlatformsAnalytics = () => {
  const {user} = useConnectPlatformsStore.getState();
  const userId = user?.id;
  const flowName = flowNames.brandsSafetyConnectPlatforms;

  const {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
    sendSignUpFlowEndedEvent,
    sendSignUpFlowStartedEvent,
    useSendNewConnectedPlatformEvent,
  } = useWizardSignupEvents(CONNECT_PLATFORMS_STEPS_UUID_MAP, flowName, userId);

  return {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
    sendSignUpFlowEndedEvent,
    sendSignUpFlowStartedEvent,
    useSendNewConnectedPlatformEvent,
  };
};
