import React from 'react';
import {Button, Headline, Icon, Label} from '@lightricks/react-design-system';
import {Player} from '@lottiefiles/react-lottie-player';
import {useSearchParams} from 'react-router-dom';
import authenticateInstagram from 'src/services/instagram/Instagram';
import translate from '@/utils/translate';
import styles from './InstagramInstructions.module.scss';
import {Platform} from '@/types/platforms';
import instagramFacebookConnectAnimation from '../../../../assets/lotties/brand-safety/instagram_facebook_connect_animation.json';

export type InstagramInstructionsProps = {
  handleBack: () => void;
  handleSkip: () => void;
  userId: string | null;
  selectedPlatforms: Platform[];
  instagramInstructionsLink?: string;
};

const defaultInstagramInstructionsLink =
  'https://help-popularpays.lightricks.com/hc/en-us/articles/24970278102930-How-to-link-Your-Instagram-Account';

function InstagramInstructions(props: InstagramInstructionsProps) {
  const {
    handleBack,
    handleSkip,
    userId,
    selectedPlatforms,
    instagramInstructionsLink = defaultInstagramInstructionsLink,
  } = props;
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brand') || '';

  const handleInstagramAuth = async () => {
    if (userId) {
      try {
        const redirectQueryParams = {
          brand: brandName,
          selectedPlatforms: selectedPlatforms.join(','),
        };
        await authenticateInstagram(userId, redirectQueryParams);
      } catch (error) {
        console.error('Failed to authenticate with Instagram', error);
      }
    } else {
      console.warn('User ID is missing');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.headerRow}>
          <Button
            onClick={handleBack}
            data-testid="back-button"
            appearance="neutral"
            mode="plain"
            size="large"
            className={styles.backButton}
          >
            <Icon
              name="Actions-Arrow-Back-Small"
              size="large"
              appearance="primary"
            />
          </Button>
          <span
            onClick={handleSkip}
            role="button"
            tabIndex={0}
            className={styles.skipButton}
          >
            <Label size="lg">{translate('components.brand-safety.skip')}</Label>
          </span>
        </div>
        <Player
          autoplay
          loop
          src={instagramFacebookConnectAnimation}
          className={styles.animation}
        />
        <div className={styles.instructions}>
          <Headline size="md" className={styles.headline}>
            {translate(
              'components.brand-safety.sign-up.instagram-instructions.headline'
            )}
          </Headline>
          <div className={styles.instructionItem}>
            <Label size="md">
              {translate(
                'components.brand-safety.sign-up.instagram-instructions.instruction'
              )}
            </Label>
          </div>
          <div className={styles.infoBox}>
            <Icon name="Actions-Info-Line" size="large" appearance="primary" />
            <Label size="md">
              {translate(
                'components.brand-safety.sign-up.instagram-instructions.info'
              )}{' '}
              &nbsp;
              <a
                href={instagramInstructionsLink}
                target="_blank"
                rel="noreferrer"
              >
                {translate(
                  'components.brand-safety.sign-up.instagram-instructions.follow-these-steps'
                )}
              </a>
            </Label>
          </div>
        </div>
        <Button
          appearance="neutral"
          size="large"
          mode="filled"
          onClick={handleInstagramAuth}
          icon={
            <Icon name="Social-Facebook" size="medium" appearance="white" />
          }
          className={styles.continueButton}
        >
          {translate(
            'components.brand-safety.sign-up.instagram-instructions.continue'
          )}
        </Button>
      </div>
    </div>
  );
}

export default InstagramInstructions;
