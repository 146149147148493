function kindFromFileType(fileType: string) {
  if (fileType.indexOf('image') > -1) {
    return 'photo';
  }
  if (fileType.indexOf('video') > -1) {
    return 'video';
  }
  return null;
}

export default kindFromFileType;
