import DeltaHelper from 'src/services/delta/DeltaHelper';
import SIGNUP_BUTTON_PRESSED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupButtonPressed';
import SIGNUP_STEP_STARTED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupStepStarted';
import {v4 as uuid} from 'uuid';
import Sentry from 'src/services/sentry/Sentry';
import SIGNUP_STEP_ENDED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupStepEnded';
import {useEffect, useCallback, useMemo} from 'react';
import SIGNUP_FLOW_STARTED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupFlowStarted';
import SIGNUP_FLOW_ENDED_SCHEMA from 'src/lib/delta/delta-schemas/ppWebCreatorsSignupFlowEnded';
import Delta from '@/utils/wrappers/Delta';
import {Platform, PLATFORM_TYPES} from '@/types/platforms';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from '@/lib/delta/delta-schemas/ppWebCreatorsSignupScreenPresented';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from '@/lib/delta/delta-schemas/ppWebCreatorsSignupScreenDismissed';

const STORAGE_KEY = 'signup_steps_process_ids';

const signupStepInputValue = (platform: string) =>
  `clicked connect to: ${platform}`;

const reportMissingUserId = () => {
  Sentry.captureMessage('Tried to send signup analytics without userId', {
    level: 'warning',
  });
};

const baseSignupStepStartedPayload = (
  flow_id: string,
  processId: string,
  platform: Platform
) => ({
  flow_id,
  process_id: processId,
  signup_provider: platform,
  request_type: 'connect platform',
  input_value: signupStepInputValue(platform),
  number_of_required_fields: 0,
  number_of_fields_completed: 0,
});

export const sendSignUpScreenEvent = <T extends string>(
  flowId: string,
  step: T,
  eventType: 'presented' | 'dismissed',
  screenPresentationId: string,
  presentedDetails?: Record<string, any>,
  screenOptions?: Record<string, any>,
  dismissedReason?: string,
  source?: string
) => {
  const schema =
    eventType === 'presented'
      ? SIGNUP_SCREEN_PRESENTED_SCHEMA.name
      : SIGNUP_SCREEN_DISMISSED_SCHEMA.name;

  Delta.sendEvent(schema, {
    flow_id: flowId,
    screen_presentation_id: screenPresentationId,
    signup_screen_name: step,
    presented_details: JSON.stringify(presentedDetails),
    screen_options: JSON.stringify(screenOptions),
    dismissed_reason: dismissedReason,
    source,
  });
};

export const useWizardSignupEvents = <T extends string>(
  stepUuidMap: Record<T, string>,
  flowName: string,
  userId?: string,
  flowId?: string
) => {
  const getStoredProcessIds = useCallback((): Record<Platform, string> => {
    if (!userId) {
      reportMissingUserId();
      return {} as Record<Platform, string>;
    }
    const stored = localStorage.getItem(STORAGE_KEY);
    const allUserIds = stored ? JSON.parse(stored) : {};

    const defaultProcessIds = PLATFORM_TYPES.reduce(
      (acc, platform) => ({
        ...acc,
        [platform]: '',
      }),
      {} as Record<Platform, string>
    );

    return allUserIds[userId]
      ? {...defaultProcessIds, ...allUserIds[userId]}
      : defaultProcessIds;
  }, [userId]);

  const setStoredProcessId = useCallback(
    (platform: Platform, processId: string) => {
      if (!userId) {
        reportMissingUserId();
        return;
      }
      const stored = localStorage.getItem(STORAGE_KEY);
      const allUserIds = stored ? JSON.parse(stored) : {};

      allUserIds[userId] = {
        ...getStoredProcessIds(),
        [platform]: processId,
      };

      localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
    },
    [userId, getStoredProcessIds]
  );

  const clearStoredProcessId = useCallback(
    (platform: Platform) => {
      if (!userId) {
        reportMissingUserId();
        return;
      }
      const stored = localStorage.getItem(STORAGE_KEY);
      const allUserIds = stored ? JSON.parse(stored) : {};

      if (allUserIds[userId]) {
        allUserIds[userId][platform] = '';

        if (Object.values(allUserIds[userId]).every((value) => value === '')) {
          delete allUserIds[userId];
        }

        localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
      }
    },
    [userId]
  );

  const clearStoredProcessIds = useCallback(() => {
    if (!userId) {
      return;
    }
    const stored = localStorage.getItem(STORAGE_KEY);
    const allUserIds = stored ? JSON.parse(stored) : {};

    delete allUserIds[userId];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(allUserIds));
  }, [userId]);

  const sendSignUpScreenButtonPressedEvent = useCallback(
    (buttonName: string, step: T) => {
      try {
        Delta.sendEvent(SIGNUP_BUTTON_PRESSED_SCHEMA.name, {
          flow_id: flowId,
          screen_presentation_id: stepUuidMap[step],
          signup_screen_name: step,
          button_name: `${step}-${buttonName}`,
        });
      } catch (error: any) {
        Sentry.captureException(error, {
          title: 'Failed to send signup button pressed event',
        });
      }
    },
    [flowName, stepUuidMap]
  );

  const sendSignUpStepStartedEvent = useCallback(
    (platform: Platform) => {
      try {
        const processId = uuid();
        setStoredProcessId(platform, processId);
        const signupStepStartedPayload = baseSignupStepStartedPayload(
          flowId ?? '',
          processId,
          platform
        );
        Delta.sendEvent(
          SIGNUP_STEP_STARTED_SCHEMA.name,
          signupStepStartedPayload
        );
      } catch (error: any) {
        Sentry.captureException(error, {
          title: 'Failed to send signup step started event',
        });
      }
    },
    [flowName, setStoredProcessId]
  );

  const sendSignUpStepEndedEvent = useCallback(
    (platform: Platform, reason: string, error?: string) => {
      try {
        if (!flowId) {
          throw new Error('Flow ID is required for signup step ended event');
        }
        let processId = 'Cannot find previous process id';
        const allProcessIds = getStoredProcessIds();
        if (allProcessIds[platform]) {
          processId = allProcessIds[platform];
        }
        const signupStepEndedPayload = baseSignupStepStartedPayload(
          flowId,
          processId,
          platform
        );
        Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
          ...signupStepEndedPayload,
          reason,
          error,
        });
      } catch (e: any) {
        Sentry.captureException(e, {
          title: 'Failed to send signup step ended event',
        });
      } finally {
        clearStoredProcessId(platform);
      }
    },
    [flowName, getStoredProcessIds, clearStoredProcessId]
  );

  const useSendNewConnectedPlatformEvent = (
    updatedConnectedPlatforms: Platform[]
  ) => {
    useEffect(() => {
      if (!userId) return;
      const storedProcessIds = getStoredProcessIds();

      const finishedPlatforms = updatedConnectedPlatforms.filter(
        (platform) => storedProcessIds[platform]
      );
      finishedPlatforms.forEach((platform) => {
        const processId = storedProcessIds[platform];
        if (processId) {
          Delta.sendEvent(SIGNUP_STEP_ENDED_SCHEMA.name, {
            flow_id: flowId ?? '',
            process_id: processId,
            signup_provider: platform,
            request_type: 'connect platform',
            input_value: signupStepInputValue(platform),
            reason: 'success',
          });
          clearStoredProcessId(platform);
        }
      });
    }, [
      flowId,
      updatedConnectedPlatforms,
      userId,
      getStoredProcessIds,
      clearStoredProcessId,
    ]);
  };

  const sendSignUpFlowStartedEvent = useCallback(
    (scInviteCode?: string) => {
      Delta.sendEvent(SIGNUP_FLOW_STARTED_SCHEMA.name, {
        flow_id: flowId ?? '',
        flow_name: flowName,
        source: `inviteCode: ${scInviteCode ?? 'in-platform'}`,
      });
    },
    [flowName, flowId]
  );

  const sendSignUpFlowEndedEvent = useCallback(
    (reason: string, connectedPlatforms: Platform[]) => {
      Delta.sendEvent(SIGNUP_FLOW_ENDED_SCHEMA.name, {
        flow_id: flowId ?? '',
        flow_name: flowName,
        reason,
        ad_networks_connected: JSON.stringify(connectedPlatforms),
      });
    },
    [flowName, flowId]
  );

  return {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
    useSendNewConnectedPlatformEvent,
    sendSignUpFlowStartedEvent,
    sendSignUpFlowEndedEvent,
    clearStoredProcessIds,
  };
};
