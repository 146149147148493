import {useEffect, useState} from 'react';
import useUserQuery from 'src/hooks/queries/use-user-query';
import {User} from '@/types/user';
import {SocialPlatform} from '@/types/platforms';
import useUpdateBirthdate from '@/hooks/queries/mutations/use-update-birthdate';

export const providerToPlatformMap: Record<string, SocialPlatform> = {
  instagram: 'Instagram',
  tiktok_v2: 'TikTok',
  youtube: 'YouTube',
  facebook: 'Facebook',
} as const;

export type UserDataCallbacks = {
  onAvatarUrlChanged?: (url: string | undefined) => void;
  onConnectedPlatformsChanged?: (platforms: SocialPlatform[]) => void;
  onUserChanged?: (user: User) => void;
};

export function useUserData(callbacks: UserDataCallbacks) {
  const [birthDateUpdateError, setBirthDateUpdateError] = useState<
    string | null
  >(null);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const [connectedPlatforms, setConnectedPlatforms] = useState<
    SocialPlatform[]
  >([]);

  const updateBirthdayMutation = useUpdateBirthdate({
    onError: () => {
      setBirthDateUpdateError('Failed to update birthday. Please try again.');
    },
  });

  const {user, isLoading, isError} = useUserQuery<User>({});

  useEffect(() => {
    if (user) {
      callbacks.onUserChanged?.(user);
    }
  }, [user]);

  useEffect(() => {
    if (!isLoading && !isError && user) {
      if (user.identities) {
        const platforms = user.identities
          .map((identity) => {
            const {provider} = identity;
            if (isValidProvider(provider)) {
              return providerToPlatformMap[provider];
            }
            return null;
          })
          .filter(
            (platform): platform is NonNullable<SocialPlatform> =>
              platform !== null
          );
        setConnectedPlatforms(platforms);
        callbacks.onConnectedPlatformsChanged?.(platforms);
        const identityWithAvatar = user.identities.find(
          ({cachedProfileImageUrl}) => cachedProfileImageUrl
        );
        if (identityWithAvatar?.cachedProfileImageUrl && !avatarUrl) {
          setAvatarUrl(identityWithAvatar.cachedProfileImageUrl);
          callbacks.onAvatarUrlChanged?.(
            identityWithAvatar.cachedProfileImageUrl
          );
        }
      }
    }
  }, [
    isError,
    isLoading,
    user,
    avatarUrl,
    callbacks.onAvatarUrlChanged,
    callbacks.onConnectedPlatformsChanged,
  ]);

  return {
    user,
    isLoading,
    isError,
    avatarUrl,
    connectedPlatforms,
    updateBirthday: updateBirthdayMutation.mutate,
    isLoadingBirthday: updateBirthdayMutation.isLoading,
    birthdayError: birthDateUpdateError,
    userBirthDate: user?.birthDate,
  };
}

function isValidProvider(
  provider: string
): provider is keyof typeof providerToPlatformMap {
  return provider in providerToPlatformMap;
}
