import {useEffect, useState} from 'react';
import {
  getToken,
  getTokenSync,
  isAuthenticated,
  STORAGE_TOKEN_KEY,
  Token,
  tokenSerializer,
} from '@/utils/token';
import Storage from '@/utils/wrappers/Storage';
import authRequests from '../../api/auth';

const useToken = (
  subscribeToTokenChange = false,
  initializeSynchronous = false
) => {
  const [initialized, setInitialized] = useState(false);
  const [isTokenSaved, setIsTokenSaved] = useState(false);
  const [token, setToken] = useState<Token | null | undefined>(
    initializeSynchronous ? getTokenSync() : null
  );

  const storageListener = async ({key}: StorageEvent) => {
    if (key !== STORAGE_TOKEN_KEY) {
      return;
    }
    const newUserToken = await getToken();
    setToken(newUserToken);
    setIsTokenSaved(!!newUserToken);
  };

  useEffect(() => {
    (async () => {
      const userToken = await getToken();
      setToken(userToken);
      setInitialized(true);
      if (subscribeToTokenChange) {
        window.addEventListener('storage', storageListener);
        Storage.addSubscriber(STORAGE_TOKEN_KEY, async () => {
          const newUserToken = await getToken();
          setToken(newUserToken);
          setIsTokenSaved(!!newUserToken);
        });
      }
    })();

    return () => {
      if (subscribeToTokenChange) {
        Storage.removeSubscriber(STORAGE_TOKEN_KEY);
        window.removeEventListener('storage', storageListener);
      }
    };
  }, []);

  const validateToken = async (userToken: Token): Promise<boolean> => {
    return authRequests.authenticateToken(userToken);
  };

  const saveToken = async (userToken: Token | null) => {
    if (userToken) {
      const isTokenValid = await validateToken(userToken);
      if (!isTokenValid) {
        return false;
      }
    }
    await Storage.setItem(STORAGE_TOKEN_KEY, userToken);
    setToken(userToken);
    setIsTokenSaved(!!userToken);
    return true;
  };

  return {
    resetToken: async () => saveToken(null),
    setToken: saveToken,
    token,
    isAuthenticated: isAuthenticated(token as Token),
    serializer: tokenSerializer,
    initialized,
    isTokenSaved,
  };
};

export default useToken;
