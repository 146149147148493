const style = {
  section: {
    withBorder: {
      borderBottom: '1px solid #d2d2d2',
      paddingBottom: '36px',
    },
    withoutBottomMargin: {
      paddingBottom: '12px',
    },
    paddingBottom: '24px',
    header: {
      marginTop: '35px',
      marginBottom: '24px',
      title: {
        fontSize: '20px',
        color: '#404040',
        fontWeight: 700,
        height: '23px',
      },
      subtitle: {
        fontSize: '16px',
        color: '#a5a5a5',
        fontWeight: 300,
        lineHeight: '18px',
        marginBottom: '0',
      },
    },
  },
  sectionTags: {
    height: '220px',
    overflow: 'hidden',
    innerTags: {
      marginTop: '-4px',
    },
  },
  sectionTagsExtended: {
    height: 'auto',
  },
  sectionShowMoreButton: {
    marginTop: '12px',
  },
};

export default style;
