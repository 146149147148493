import {ERRORS} from '../../config/constants';
import axios from '@/utils/axios';
import {
  getSerializedToken,
  getSessionDataFromHeaders,
  Token,
} from '@/utils/token';

const REGISTER_URL = '/auth';
const LOGIN_URL = '/auth/sign_in';
const CHECK_EMAIL_EXIST_URL = '/auth/creators/email_exists';

type LoginFormData = {
  email: string;
  password: string;
  invite_code?: string;
  campaign_direct_link?: string;
  ltx_account_id?: string;
};

type RegisterFormData = {
  email: string;
  password: string;
  invite_code?: string;
  campaign_direct_link?: string;
  ltx_account_id?: string;
  auth_code?: string;
  ltx_id?: string;
  sc_invite_code?: string;
};

const authRequests = {
  login: async (loginFormData: LoginFormData) => {
    const data = {
      email: loginFormData.email,
      password: loginFormData.password,
    };

    const loginResponse = await axios.post(LOGIN_URL, data);
    if (loginResponse.data.data.attributes.status === 'suspended') {
      throw new Error(ERRORS.USER_SUSPENDED);
    }
    return {
      response: loginResponse,
      sessionData: getSessionDataFromHeaders(loginResponse.headers),
    };
  },
  register: async (registerFormData: RegisterFormData) => {
    const data = {
      ...registerFormData,
      password: registerFormData.password,
      email: registerFormData.email,
      invite_code: registerFormData.invite_code,
      campaign_direct_link: registerFormData.campaign_direct_link,
      ltx_account_id: registerFormData.ltx_account_id,
      auth_code: registerFormData.auth_code,
      ltx_id: registerFormData.ltx_id,
      sc_invite_code: registerFormData.sc_invite_code,
    };

    const registerResponse = await axios.post(REGISTER_URL, data);
    return {
      response: registerResponse,
      sessionData: getSessionDataFromHeaders(registerResponse.headers),
    };
  },
  authenticateToken: async (token: Token, authenticationEndPoint = '/user') => {
    const URL = authenticationEndPoint;
    try {
      const response = await axios.get(URL, {
        headers: {...getSerializedToken(token)},
      });
      if (response?.data?.data?.attributes?.status === 'suspended') {
        return false;
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        return false;
      }
      throw error;
    }
    return true;
  },
  checkEmailExists: async (email: string): Promise<boolean> => {
    const URL = `${CHECK_EMAIL_EXIST_URL}?email=${encodeURIComponent(email)}`;
    const checkEmailExistsResponse = await axios.get(URL);
    const {email_address_exists} = checkEmailExistsResponse.data;
    return email_address_exists;
  },
};

export default authRequests;
