import getCircularReplacer from '../getCircularReplacer';

export interface StorageDriver {
  getItem(key: string): string | number | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  getAllKeys?(): Array<string>;
  clear(): void;
}

interface Subscriber {
  key: string;
  callback: () => void;
}

class Storage {
  private driver: StorageDriver | undefined;

  private subscribers: Subscriber[] = [];

  setDriver(driver: StorageDriver | any): void {
    this.driver = driver;
  }

  addSubscriber = (key: string, callback: () => void): any => {
    this.subscribers.push({key, callback});
  };

  removeSubscriber = (key: string): number => {
    const beforeAmount = this.subscribers.length;
    this.subscribers = this.subscribers.filter(
      (subscriber) => subscriber.key !== key
    );
    const afterAmount = this.subscribers.length;

    return beforeAmount - afterAmount;
  };

  removeAllSubscribers = (): void => {
    this.subscribers = [];
  };

  private notifySubscribers = (key: string): void => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.key === key) {
        subscriber.callback();
      }
    });
  };

  async setItem(
    key: string,
    value: string | number | object | null
  ): Promise<void> {
    this.checkDriver();

    const normalizeValue =
      typeof value === 'string'
        ? value
        : JSON.stringify(value, getCircularReplacer());

    this.driver!.setItem(key, normalizeValue);

    this.notifySubscribers(key);
  }

  async getItem(key: string): Promise<any> {
    this.checkDriver();

    const value = await this.driver!.getItem(key);

    try {
      return JSON.parse(<string>value);
    } catch (e) {
      return value;
    }
  }

  getItemSync(key: string): any {
    this.checkDriver();

    const value = this.driver!.getItem(key);

    try {
      return JSON.parse(<string>value);
    } catch (e) {
      return value;
    }
  }

  async removeItem(key: string): Promise<void> {
    this.checkDriver();

    return this.driver!.removeItem(key);
  }

  async getAllKeys(): Promise<Array<string>> {
    this.checkDriver();

    if (this.driver?.getAllKeys) {
      return this.driver!.getAllKeys();
    }

    return Object.keys(this.driver!);
  }

  async clear(): Promise<void> {
    this.checkDriver();

    this.driver!.clear();
  }

  checkDriver() {
    if (!this.driver) {
      throw Error('Storage driver was not initialized!');
    } else {
      ['setItem', 'getItem', 'removeItem', 'clear'].forEach((key) => {
        if (!(key in this.driver!)) {
          throw Error(`Storage driver must have a ${key} method`);
        }
      });
    }
  }
}

export default new Storage();
