import React from 'react';
import {Label, Icon} from '@lightricks/react-design-system';
import MultiSelect from '../../../components/multi-select';
import styles from './SocialSelect.module.scss';
import {SocialPlatform} from '@/types/platforms';
import {SelectItem} from './selectItem';
import translate from '@/utils/translate';

interface SocialItemProps {
  label: string;
  icon?: React.ReactNode;
}

interface SocialSelectProps {
  selectedSocialApps: Set<SocialPlatform>;
  onSocialAppChange: (socialApp: Set<SocialPlatform>) => void;
}

export const SOCIAL_OPTIONS: SelectItem<SocialPlatform>[] = [
  {
    label: translate('TikTok'),
    image: <Icon name="Social-TikTok" size="medium" appearance="neutral" />,
    value: 'TikTok',
  },
  {
    label: translate('Instagram'),
    image: <Icon name="Social-Instagram" size="medium" appearance="neutral" />,
    value: 'Instagram',
  },
  {
    label: translate('YouTube'),
    image: <Icon name="Social-YouTube" size="medium" appearance="neutral" />,
    value: 'YouTube',
  },
  {
    label: translate('Facebook'),
    image: <Icon name="Social-Facebook" size="medium" appearance="neutral" />,
    value: 'Facebook',
  },
];

function SocialItem({label, icon}: SocialItemProps) {
  return (
    <div className={styles.socialItem}>
      {icon}
      <Label size="md">{label}</Label>
    </div>
  );
}

export default function SocialSelect({
  selectedSocialApps,
  onSocialAppChange,
}: SocialSelectProps) {
  const selectedItems = SOCIAL_OPTIONS.filter((option) =>
    selectedSocialApps.has(option.value)
  );

  return (
    <MultiSelect
      items={SOCIAL_OPTIONS}
      numberOfColumns={2}
      selectedItems={selectedItems}
      onSelectionChange={(items) =>
        onSocialAppChange(new Set(items.map((item) => item.value)))
      }
      renderItem={({label, image}) => <SocialItem label={label} icon={image} />}
      getItemKey={(item) => item.value}
      itemClassName={styles.socialItem}
    />
  );
}
