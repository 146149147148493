import React from 'react';
import {
  Banner,
  Body,
  Button,
  designSystemToken,
  Headline,
  Icon,
} from '@lightricks/react-design-system';
import ProgressBar from 'src/components/progress-bar';
import clsx from 'clsx';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import translate from '@/utils/translate';
import styles from './WizardLayout.module.scss';

export type WizardLayoutProps = {
  banner?: React.ComponentProps<typeof Banner>;
  progress: number | null;
  heading?: string;
  subheading?: string;
  renderTopHeader?: () => React.ReactNode;
  renderContent: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderDesktopVisual?: () => React.ReactNode;
  desktopVisualBackgroundColor?: string;
  onBack?: () => void;
  onNext: () => void;
  onSkip?: () => void;
  onCloseBanner?: () => void;
  wizardFooterButtonProps?: {
    label?: string;
    iconName?: IconName;
    hideNextButton?: boolean;
  };
  isNextDisabled?: boolean;
  isUploading?: boolean;
};

function WizardLayout(props: WizardLayoutProps) {
  const {
    banner,
    progress,
    heading,
    subheading,
    renderContent,
    renderFooter,
    renderDesktopVisual,
    desktopVisualBackgroundColor,
    onBack,
    onNext,
    onSkip,
    onCloseBanner,
    wizardFooterButtonProps,
    isNextDisabled,
    renderTopHeader,
    isUploading = false,
  } = props;

  const wizardFooterButton = !wizardFooterButtonProps?.hideNextButton && (
    <Button
      disabled={isNextDisabled || isUploading}
      className={styles.cta}
      onClick={onNext}
      appearance="neutral"
      size="large"
      mode="filled"
      isLoading={isUploading}
      icon={
        wizardFooterButtonProps?.iconName && (
          <Icon
            name={wizardFooterButtonProps.iconName}
            size="medium"
            appearance="inverse"
          />
        )
      }
    >
      {wizardFooterButtonProps?.label ?? translate('Next')}
    </Button>
  );

  return (
    <div className={styles.wizardLayoutContainer}>
      <div
        className={styles.desktopContent}
        style={{backgroundColor: desktopVisualBackgroundColor}}
      >
        {renderDesktopVisual?.()}
      </div>

      <div className={styles.primaryContent}>
        <Banner
          className={styles.wizardBanner}
          mode="light"
          onActionClick={onCloseBanner}
          {...banner}
        />

        <WizardTopNav onBack={onBack} onSkip={onSkip} progress={progress} />

        <div className={styles.primaryContentInner}>
          {renderTopHeader && renderTopHeader()}

          {(heading || subheading) && (
            <div className={clsx(styles.typography)}>
              {heading && <Headline size="xl">{heading}</Headline>}
              {subheading && <Body size="lg">{subheading}</Body>}
            </div>
          )}

          <div className={clsx(styles.children)}>{renderContent()}</div>
        </div>
        <div className={styles.footer}>
          {renderFooter?.()}
          {wizardFooterButton}
        </div>
      </div>
    </div>
  );
}

type WizardTopNavProps = {
  onBack?: () => void;
  onSkip?: () => void;
  progress: number | null;
};

function WizardTopNav(props: WizardTopNavProps) {
  const {onBack, onSkip, progress} = props;

  return (
    <div className={styles.topNav}>
      <div className={styles.topNavButtonContainer}>
        {onBack && (
          <Button
            onClick={onBack}
            appearance="overlay"
            size="medium"
            mode="plain"
            className={styles.topNavButton}
            icon={
              <Icon
                name="Actions-Arrow-Back-Large-Primary"
                size="medium"
                appearance="primary"
              />
            }
          />
        )}
      </div>
      {progress !== null && (
        <ProgressBar
          className={styles.progressBar}
          value={progress}
          size="md"
          fillColor={`linear-gradient(
              90deg, ${designSystemToken('reference.purple.50')} 0%,
                ${designSystemToken('semantic.fg.brand')} 97%)`}
        />
      )}
      <div className={clsx(styles.topNavButtonContainer, styles.hideOnMobile)}>
        {onSkip && (
          <Button
            onClick={onSkip}
            className={styles.topNavButton}
            appearance="neutral"
            size="medium"
            mode="plain"
          >
            {translate('Skip')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default WizardLayout;
