import React from 'react';
import clsx from 'clsx';
import {Icon, Headline, Button} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import styles from './PlatformList.module.scss';
import {Platform} from '@/types/platforms';

export type PlatformListProps = {
  selectedPlatforms: Platform[];
  connectedPlatforms: Platform[];
  onConnectPlatform: (platform: Platform) => void;
  isConnectablePlatform?: (platform: Platform) => boolean;
  showEmailPlatform?: boolean;
  sortFunction?: (a: Platform, b: Platform) => number;
};

function PlatformList({
  selectedPlatforms,
  connectedPlatforms,
  onConnectPlatform,
  isConnectablePlatform = () => true,
  showEmailPlatform = true,
  sortFunction,
}: PlatformListProps) {
  const renderActionButton = (platform: Platform, isConnected: boolean) => {
    if (!isConnectablePlatform(platform)) {
      return null;
    }
    if (isConnected) {
      return (
        <Icon
          name="Actions-Patch-Accept-Line"
          size="large"
          appearance="brand"
        />
      );
    }

    return (
      <Button
        appearance="neutral"
        size="small"
        mode="plain"
        onClick={() => onConnectPlatform(platform)}
        className={styles.connectButton}
        icon={
          <Icon name="Actions-Add-Small1" size="large" appearance="white" />
        }
      />
    );
  };

  const sortedPlatforms = [...selectedPlatforms].sort(sortFunction);

  return (
    <div className={styles.platformList}>
      {showEmailPlatform && (
        <div className={styles.platformRow}>
          <div className={clsx(styles.platformItem, styles.completed)}>
            <Icon name="Navigation-Mail" size="medium" appearance="primary" />
            <Headline size="xs">
              {translate('components.brand-safety.sign-up.platform-list.email')}
            </Headline>
          </div>
          <Icon
            name="Actions-Patch-Accept-Line"
            size="large"
            appearance="brand"
          />
        </div>
      )}

      {sortedPlatforms.map((platform) => {
        const isConnected = connectedPlatforms.includes(platform);
        const isConnectable = isConnectablePlatform(platform);
        const label = !isConnectable
          ? translate(
              'components.brand-safety.sign-up.platform-list.coming-soon'
            )
          : platform;

        return (
          <div className={styles.platformRow} key={platform}>
            <div className={styles.platformItem}>
              <Icon
                name={`Social-${platform}` as any}
                size="medium"
                appearance="primary"
              />
              <Headline size="xs">{label}</Headline>
            </div>
            {renderActionButton(platform, isConnected)}
          </div>
        );
      })}
    </div>
  );
}

export default PlatformList;
