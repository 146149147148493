import {getEnv} from '@/config/environment';
import constructRedirectUrls from '@/utils/redirectUrlUtils';
import {navigateWithAnalytics} from '@/utils/wrappers/Delta';

export default async function authenticateFacebook(
  userId: string | null,
  redirectQueryParams: Record<string, string>
) {
  const authUrl = getFacebookAuthUrl(userId, redirectQueryParams);
  await navigateWithAnalytics(authUrl);
}

const getFacebookAuthUrl = (
  userId: string | null,
  redirectQueryParams: Record<string, string>
): string => {
  const {successRedirectUrl, failureRedirectUrl} = constructRedirectUrls({
    provider: 'facebook',
    additionalParams: redirectQueryParams,
  });

  const facebookAppId = getEnv().FACEBOOK_APP_ID;
  const serverCallbackUri = getEnv().META_SERVER_CALLBACK_URI;

  const state = {
    network: 'facebook',
    success_redirect_url: successRedirectUrl,
    failure_redirect_url: failureRedirectUrl,
    user_id: userId,
    redirect_uri: serverCallbackUri,
  };

  const scopes = [
    'business_management',
    'pages_read_user_content',
    'pages_manage_metadata',
    'pages_read_engagement',
  ].join(',');

  const authUrl = new URL('https://www.facebook.com/v16.0/dialog/oauth');
  authUrl.searchParams.set('client_id', facebookAppId);
  authUrl.searchParams.set('redirect_uri', serverCallbackUri);
  authUrl.searchParams.set('response_type', 'code,granted_scopes');
  authUrl.searchParams.set('scope', scopes);
  authUrl.searchParams.set('state', JSON.stringify(state));

  return authUrl.toString();
};
