// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {DeltaClient} from '@lightricks/delta-client-js';
import Analytics from '@popularpays/analytics';
import deltaSchemaMapperProduction from '../../lib/delta/deltaSchemaMapperProduction';
import deltaSchemaMapperIntegration from '../../lib/delta/deltaSchemaMapperIntegration';
import {
  deltaClientEvents,
  SchemaMapperType,
} from '../../lib/delta/deltaConstants';
import {getToken} from '../token';
import Sentry from '../../services/sentry/Sentry';
import {getEnv} from '../../config/environment';
import getLtid from '../getLtid';
import {getPlatform} from '../getPlatform';
import {getUtmAttributionFromSessionStorage} from '../utmAttributionTracker';

const appIdentifier = 'creators';
const appBundleId = 'creators';

type DeltaEnv = 'integration' | 'production';

class Delta {
  deltaEnv: DeltaEnv;

  deltaSchemaMapper: SchemaMapperType;

  pageLanguage: string;

  attributeOverrides: object;

  creatorId: string | null;

  isDisabled: boolean;

  deltaClient;

  constructor(pageLanguage = 'english', attributeOverrides: object = {}) {
    this.deltaEnv =
      getEnv().MODE === 'production' ? 'production' : 'integration';
    this.pageLanguage = pageLanguage;
    this.attributeOverrides = attributeOverrides;
    this.creatorId = null;
    this.deltaSchemaMapper =
      this.deltaEnv === 'production'
        ? deltaSchemaMapperProduction
        : deltaSchemaMapperIntegration;

    this.isDisabled = ['test', 'development'].includes(getEnv().MODE || '');

    const event = {
      ...this.getRegularAttributes(),
      ...this.attributeOverrides,
    };

    const sessionStartedEvent = this.isDisabled
      ? null
      : {
          event,
          ...this.deltaSchemaMapper[deltaClientEvents.generic.session_started],
        };

    const sessionEndedEvent = this.isDisabled
      ? null
      : {
          event,
          ...this.deltaSchemaMapper[deltaClientEvents.generic.session_ended],
        };

    const handleError = (error: Error, extra: object) => {
      Sentry.captureException(error, extra);
    };

    const platform = getPlatform();

    const deltaOptions = {
      eventSource: {
        deltaEnvironment: this.deltaEnv,
        platform,
        identifier: appIdentifier,
      },
      sessionObjects: {
        sessionStartObject: sessionStartedEvent,
        sessionEndObject: sessionEndedEvent,
      },
      onBatchError: handleError,
      onEventError: handleError,
      identifyIsLtxTeamUser: async () => {
        const token = await getToken();
        const userEmail = token?.authenticated?.email;
        if (userEmail) {
          return userEmail.includes('lightricks');
        }

        return false;
      },
    };

    this.deltaClient = new DeltaClient(deltaOptions);
  }

  setCreatorId(creatorId: string) {
    this.creatorId = creatorId;
  }

  getRegularAttributes = () => {
    return {
      app_bundle_id: appBundleId,
      creator_id: this.creatorId,
      page_language: this.pageLanguage,
      lt_id: getLtid(),
      attribution: {
        utm_line: getUtmAttributionFromSessionStorage(),
      },
    };
  };

  sendEvent = (eventName: string, eventData: object) => {
    if (this.isDisabled) {
      return;
    }

    const {schema, schemaId} = this.deltaSchemaMapper[eventName] || {};

    const updatedEvent = {
      ...this.getRegularAttributes(),
      ...eventData,
      ...this.attributeOverrides,
    };

    Analytics.dispatchEvent(eventName, updatedEvent);
    this.deltaClient.sendEvent(updatedEvent, schema, schemaId);
  };

  flushEvents = async () => {
    await this.deltaClient.dischargeEventBatch();
  };
}

/**
 * Navigates to the given URL after flushing analytics events.
 * This is done to ensure that all analytics events are flushed before leaving the app
 * and losing the session.
 *
 * @param url - The URL to navigate to.
 */
export async function navigateWithAnalytics(url: string): Promise<void> {
  try {
    await delta.flushEvents();
    // eslint-disable-next-line no-restricted-properties, no-restricted-syntax
    window.location.href = url;
  } catch (error) {
    console.error('Failed to flush analytics:', error);
    // eslint-disable-next-line no-restricted-properties, no-restricted-syntax
    window.location.href = url;
  }
}

const delta = new Delta();
export default delta;
