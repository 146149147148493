import {create} from 'zustand';
import {devtools, persist} from 'zustand/middleware';
import {Banner} from '@lightricks/react-design-system';
import {Step, STEPS, DEFAULT_STEP_ORDER} from './onboardingSteps';
import {type Specialty, type Capability, type Locale} from '@/schema/User';
import type {PortfolioMediaItem} from '@/types/portfolioMediaItem';
import {SocialPlatform} from '@/types/platforms';

export type Segment = 'creator' | 'influencer';

export type GenderOption = 'Female' | 'Male' | 'Other' | 'Prefer not to say';

const ONBOARDING_STORAGE_KEY = 'onboarding-storage';

export type Brand = {
  id: string;
  name: string;
};

export type OnboardingState = {
  step: Step;
  previousStep: Step | undefined;
  isCurrentStepValid: boolean;
  banner: Pick<
    React.ComponentProps<typeof Banner>,
    'isOpen' | 'title' | 'children' | 'severity'
  >;
  fullName: string;
  birthdate: Date | undefined;
  segment: Segment | undefined;
  location: string | undefined;
  locale: Locale | undefined;
  gender: GenderOption | undefined; // this transitions from undefined to null if the user selects "prefer not to say"
  bio: string | undefined;
  selectedSocialApps: Set<SocialPlatform>;
  preferredCollabs: Set<Brand>;
  specialties: Set<Specialty>;
  capabilities: Set<Capability>;
  portfolioMediaItems: PortfolioMediaItem[];
  portfolioLink: string | undefined;
  profilePictureUrl: string | undefined;
  platformError?: string;
  isHydrated: boolean;
  stepOrder: Step[];
  isStepOrderPersisted: boolean;
  isStepUploading: boolean;
  analyticsFlowId: string | undefined;
};

export type OnboardingActions = {
  setStep: (step: Step) => void;
  setStepValidity: (isValid: boolean) => void;
  openBanner: (banner: Omit<OnboardingState['banner'], 'isOpen'>) => void;
  closeBanner: () => void;
  hydrate: (state: Partial<OnboardingState>) => void;
  setFullName: (fullName: string) => void;
  setBirthdate: (birthdate: Date) => void;
  setSegment: (segment?: Segment) => void;
  setLocation: (location: string | undefined) => void;
  setLocale: (locale: Locale | undefined) => void;
  setGender: (gender?: GenderOption) => void;
  setBio: (bio: string) => void;
  setSelectedSocialApps: (socialApps: Set<SocialPlatform>) => void;
  setPreferredCollabs: (collabs: Set<Brand>) => void;
  setSpecialties: (specialties: Set<Specialty>) => void;
  setCapabilities: (capabilities: Set<Capability>) => void;
  setPortfolioMediaItems: (mediaItems: PortfolioMediaItem[]) => void;
  setPortfolioLink: (link: string) => void;
  setProfilePictureUrl: (url: string) => void;
  setPlatformError: (error: string | undefined) => void;
  setStepOrder: (stepOrder: Step[]) => void;
  setStepUploading: (isStepUploading: boolean) => void;
  setAnalyticsFlowId: (analyticsFlowId: string) => void;
  clearPersistentData: () => void;
};

const DEFAULT_ONBOARDING_STATE: OnboardingState = {
  step: STEPS.NAME_AND_BIRTHDATE,
  previousStep: undefined,
  banner: {isOpen: false, title: null, children: null, severity: 'info'},
  isCurrentStepValid: false,
  fullName: '',
  birthdate: undefined,
  segment: undefined,
  location: undefined,
  locale: undefined,
  gender: undefined,
  bio: undefined,
  selectedSocialApps: new Set(),
  specialties: new Set(),
  capabilities: new Set(),
  preferredCollabs: new Set(),
  portfolioMediaItems: [],
  portfolioLink: undefined,
  profilePictureUrl: undefined,
  platformError: undefined,
  isHydrated: false,
  stepOrder: DEFAULT_STEP_ORDER,
  isStepOrderPersisted: false,
  isStepUploading: false,
  analyticsFlowId: undefined,
};

type State = OnboardingState & OnboardingActions;

type PersistedState = {
  selectedSocialApps: SocialPlatform[];
  preferredCollabs: Brand[];
  stepOrder: Step[];
  step: Step;
  previousStep: Step | undefined;
  analyticsFlowId: string | undefined;
};

const isValidPersistedState = (state: unknown): state is PersistedState => {
  if (!state || typeof state !== 'object') return false;

  const maybeValid = state as Partial<PersistedState>;

  if (
    maybeValid.selectedSocialApps &&
    !Array.isArray(maybeValid.selectedSocialApps)
  ) {
    return false;
  }

  if (
    maybeValid.preferredCollabs &&
    !Array.isArray(maybeValid.preferredCollabs)
  ) {
    return false;
  }

  if (maybeValid.preferredCollabs) {
    const isValidBrand = maybeValid.preferredCollabs.every(
      (brand): brand is Brand =>
        typeof brand === 'object' &&
        brand !== null &&
        typeof brand.id === 'string' &&
        typeof brand.name === 'string'
    );
    if (!isValidBrand) return false;
  }

  if (
    maybeValid.stepOrder &&
    !maybeValid.stepOrder.every((step: Step) =>
      Object.values(STEPS).includes(step)
    )
  ) {
    return false;
  }

  if (maybeValid.step && !Object.values(STEPS).includes(maybeValid.step)) {
    return false;
  }

  if (
    maybeValid.previousStep &&
    !Object.values(STEPS).includes(maybeValid.previousStep)
  ) {
    return false;
  }

  if (
    maybeValid.analyticsFlowId &&
    typeof maybeValid.analyticsFlowId !== 'string'
  ) {
    return false;
  }

  return true;
};

export const useOnboardingStore = create<State>()(
  devtools(
    persist(
      (set) => ({
        ...DEFAULT_ONBOARDING_STATE,
        setStep: (step) => {
          set((state) => ({previousStep: state.step, step}));
        },
        setStepValidity: (isValid) => set({isCurrentStepValid: isValid}),
        openBanner: (banner) => set({banner: {...banner, isOpen: true}}),
        closeBanner: () => set({banner: {...DEFAULT_ONBOARDING_STATE.banner}}),
        hydrate: (state) => set({...state, isHydrated: true}),
        setFullName: (fullName) => set({fullName}),
        setBirthdate: (birthdate) => set({birthdate}),
        setSegment: (segment) => set({segment}),
        setLocation: (location) => set({location}),
        setLocale: (locale) => set({locale}),
        setGender: (gender) => set({gender}),
        setBio: (bio) => set({bio}),
        setSelectedSocialApps: (socialApps: Set<SocialPlatform>) =>
          set({selectedSocialApps: socialApps}),
        setPreferredCollabs: (collabs: Set<Brand>) =>
          set({preferredCollabs: collabs}),
        setSpecialties: (specialties: Set<Specialty>) => set({specialties}),
        setCapabilities: (capabilities: Set<Capability>) => set({capabilities}),
        setPortfolioMediaItems: (newMediaItems: PortfolioMediaItem[]) =>
          set((state) => ({
            portfolioMediaItems: [
              ...state.portfolioMediaItems,
              ...newMediaItems,
            ],
          })),
        setPortfolioLink: (link: string) => set({portfolioLink: link}),
        setProfilePictureUrl: (url: string) => set({profilePictureUrl: url}),
        setPlatformError: (platformError) => set({platformError}),
        setStepOrder: (stepOrder: Step[]) => set({stepOrder}),
        setStepUploading: (isStepUploading: boolean) => set({isStepUploading}),
        setAnalyticsFlowId: (analyticsFlowId: string) => set({analyticsFlowId}),
        clearPersistentData: () => {
          localStorage.removeItem(ONBOARDING_STORAGE_KEY);
        },
      }),
      {
        name: ONBOARDING_STORAGE_KEY,
        version: 1,
        partialize: (state: State): PersistedState => ({
          selectedSocialApps: Array.from(state.selectedSocialApps),
          preferredCollabs: Array.from(state.preferredCollabs),
          stepOrder: state.stepOrder,
          step: state.step,
          previousStep: state.previousStep,
          analyticsFlowId: state.analyticsFlowId,
        }),
        migrate: (persistedState: unknown, version: number) => {
          if (version === 2) {
            // Example: migrate from version 1 to version 2
            // persistedState = migrateV1ToV2(persistedState);
          }

          // Validate the data
          if (!isValidPersistedState(persistedState)) {
            console.warn(
              'Invalid persisted state found in localStorage, using defaults'
            );
            return {
              selectedSocialApps: [],
              preferredCollabs: [],
              stepOrder: DEFAULT_STEP_ORDER,
              step: DEFAULT_ONBOARDING_STATE.step,
              previousStep: DEFAULT_ONBOARDING_STATE.previousStep,
              analyticsFlowId: DEFAULT_ONBOARDING_STATE.analyticsFlowId,
            };
          }

          return persistedState;
        },
        merge: (persistedState: unknown, currentState: State) => {
          // At this point, persistedState has been validated by migrate
          const validState = persistedState as PersistedState;
          return {
            ...currentState,
            selectedSocialApps: new Set(validState.selectedSocialApps || []),
            preferredCollabs: new Set(validState.preferredCollabs || []),
            stepOrder: validState.stepOrder || DEFAULT_STEP_ORDER,
            step: validState.step || DEFAULT_ONBOARDING_STATE.step,
            previousStep:
              validState.previousStep || DEFAULT_ONBOARDING_STATE.previousStep,
            isStepOrderPersisted: Boolean(validState.stepOrder?.length),
            analyticsFlowId:
              validState.analyticsFlowId ||
              DEFAULT_ONBOARDING_STATE.analyticsFlowId,
          };
        },
      }
    )
  )
);
