enum Platform {
  WEB = 'web',
  NATIVE_MOBILE_IOS = 'native-mobile-ios',
  NATIVE_MOBILE_ANDROID = 'native-mobile-android',
}

// This is injected from the react native app:
// https://github.com/PopularPays/creator-react-native-app
declare global {
  interface Window {
    PP_APP_INFO?: {
      source: string;
      platform: string;
    };
  }
}

export function getPlatform(): Platform {
  if (typeof window !== 'undefined' && window.PP_APP_INFO) {
    const {platform} = window.PP_APP_INFO;
    if (platform === 'ios') {
      return Platform.NATIVE_MOBILE_IOS;
    }
    if (platform === 'android') {
      return Platform.NATIVE_MOBILE_ANDROID;
    }
  }

  return Platform.WEB;
}

export function isNativeMobile(): boolean {
  return getPlatform() !== Platform.WEB;
}
