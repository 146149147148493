import {useMutation} from '@tanstack/react-query';
import profileUpdaters from '@/api/updaters/profile';

type CompleteOnboardingArgs = {
  onError?: (error: string) => void;
  onSuccess?: () => void;
};

function useCompleteOnboarding({
  onError,
  onSuccess,
}: CompleteOnboardingArgs = {}) {
  return useMutation({
    mutationKey: ['completeOnboarding'],
    mutationFn: profileUpdaters.completeOnboarding,
    onSuccess,
    onError,
  });
}

export default useCompleteOnboarding;
