import React from 'react';
import {Label, Icon} from '@lightricks/react-design-system';
import {IconName} from '@lightricks/react-design-system/dist/components/icon/iconNames';
import MultiSelect from '@/components/multi-select';
import styles from './CapabilitiesSelect.module.scss';
import {Capability} from '@/schema/User';
import {SelectItem} from './selectItem';
import translate from '@/utils/translate';

interface CapabilitiesItemProps {
  label: string;
  icon?: React.ReactNode;
}

interface CapabilitiesSelectProps {
  selectedCapabilities: Set<Capability>;
  onCapabilitiesChange: (segment: Set<Capability>) => void;
}

export const CAPABILITIES: Array<{
  value: Capability;
  label: string;
  icon: IconName;
}> = [
  {
    label: translate('Animated gif'),
    icon: 'Navigation-Gif',
    value: 'animated gif',
  },
  {
    label: translate('Graphic design'),
    icon: 'Features-ClothesWhites',
    value: 'graphic design',
  },
  {
    label: translate('Copywriting'),
    icon: 'Features-PortraitLight',
    value: 'copywriting',
  },
  {
    label: translate('Illustration'),
    icon: 'Features-Brush',
    value: 'illustration',
  },
  {
    label: translate('Infographics'),
    icon: 'Navigation-Statistics',
    value: 'infographics',
  },
  {
    label: translate('Long-form video'),
    icon: 'Features-VideoEdit',
    value: 'long-form video',
  },
  {
    label: translate('Pro photography'),
    icon: 'Navigation-Camera',
    value: 'professional photography',
  },
  {
    label: translate('Photo & Video Editing'),
    icon: 'Navigation-Photos-Light',
    value: 'photo/video editing',
  },
  {
    label: translate('Short-form video'),
    icon: 'Features-VideoEdit',
    value: 'short-form video',
  },
  {
    label: translate('Stop-motion video'),
    icon: 'Features-Animation-Main',
    value: 'stop-motion video',
  },
  {
    label: translate('Blog Writing'),
    icon: 'Features-Makeup-Liner',
    value: 'blog writing',
  },
];

const CAPABILITY_OPTIONS: Array<
  SelectItem<{name: Capability; id: Capability}>
> = CAPABILITIES.map((capability) => ({
  label: capability.label,
  image: <Icon name={capability.icon} size="medium" appearance="neutral" />,
  value: {id: capability.value, name: capability.value},
}));

function CapabilitiesItem({label, icon}: CapabilitiesItemProps) {
  return (
    <div className={styles.capabilitiesItem}>
      {icon}
      <div>
        <Label size="md">{label}</Label>
      </div>
    </div>
  );
}

export default function CapabilitiesSelect({
  selectedCapabilities,
  onCapabilitiesChange,
}: CapabilitiesSelectProps) {
  const selectedItems = CAPABILITY_OPTIONS.filter((option) =>
    Array.from(selectedCapabilities).some(
      (capability) => capability === option.value.name
    )
  );

  return (
    <MultiSelect
      items={CAPABILITY_OPTIONS}
      selectedItems={selectedItems}
      numberOfColumns={2}
      onSelectionChange={(items) =>
        onCapabilitiesChange(new Set(items.map((item) => item.value.name)))
      }
      renderItem={({label, image}) => (
        <CapabilitiesItem label={label} icon={image} />
      )}
      itemClassName={styles.capabilitiesItem}
    />
  );
}
