import React from 'react';
import {Skeleton as MuiSkeleton} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';

const DEFAULT_ROUNDED_BORDER_RADIUS = 4;

export type SkeletonProps = {
  variant: 'text' | 'rectangular' | 'rounded' | 'circular';
  animation?: 'pulse' | 'wave' | false;
  width: number | string;
  height: number | string;
  backgroundColor?: string;
  borderRadius?: number;
  margin?: string | number;
};

function Skeleton({
  variant,
  animation = 'wave',
  width,
  height,
  backgroundColor,
  borderRadius,
  margin,
}: SkeletonProps) {
  return (
    <MuiSkeleton
      variant={variant}
      animation={animation}
      width={width}
      height={height}
      sx={{
        'margin': margin,
        '&.MuiSkeleton-wave': {
          background: backgroundColor || designSystemToken('semantic.bg.alpha'),
        },
        '&.MuiSkeleton-wave::after': {
          background:
            'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent)',
        },
        '&.MuiSkeleton-pulse': {
          background: backgroundColor || designSystemToken('semantic.bg.alpha'),
        },
        '&.MuiSkeleton-rounded': {
          borderRadius: borderRadius || DEFAULT_ROUNDED_BORDER_RADIUS,
        },
      }}
    />
  );
}

export default Skeleton;
