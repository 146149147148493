import {deltaClientEvents, SchemaMapperType} from './deltaConstants';
import FLOW_STARTED_SCHEMA from './delta-schemas/ppWebCreatorsFlowStarted';
import FLOW_ENDED_SCHEMA from './delta-schemas/ppWebCreatorsFlowEnded';
import BUTTON_PRESSED_SCHEMA from './delta-schemas/ppWebCreatorsButtonPressed';
import MESSAGE_PRESENTED_SCHEMA from './delta-schemas/ppWebCreatorsMessagePresented';
import MESSAGE_DISMISSED_SCHEMA from './delta-schemas/ppWebCreatorsMessageDismissed';
import SCREEN_PRESENTED_SCHEMA from './delta-schemas/ppWebCreatorsScreenPresented';
import SCREEN_DISMISSED_SCHEMA from './delta-schemas/ppWebCreatorsScreenDismissed';
import SESSION_STARTED_SCHEMA from './delta-schemas/ppWebCreatorsSessionStarted';
import SESSION_ENDED_SCHEMA from './delta-schemas/ppWebCreatorsSessionEnded';
import LOGIN_STEP_STARTED_SCHEMA from './delta-schemas/ppWebCreatorsLoginStepStarted';
import LOGIN_STEP_ENDED_SCHEMA from './delta-schemas/ppWebCreatorsLoginStepEnded';
import SIGNUP_STEP_STARTED_SCHEMA from './delta-schemas/ppWebCreatorsSignupStepStarted';
import SIGNUP_STEP_ENDED_SCHEMA from './delta-schemas/ppWebCreatorsSignupStepEnded';
import SIGNUP_BUTTON_PRESSED_SCHEMA from './delta-schemas/ppWebCreatorsSignupButtonPressed';
import SIGNUP_FLOW_ENDED_SCHEMA from './delta-schemas/ppWebCreatorsSignupFlowEnded';
import SIGNUP_FLOW_STARTED_SCHEMA from './delta-schemas/ppWebCreatorsSignupFlowStarted';
import SIGNUP_SCREEN_DISMISSED_SCHEMA from './delta-schemas/ppWebCreatorsSignupScreenDismissed';
import SIGNUP_SCREEN_PRESENTED_SCHEMA from './delta-schemas/ppWebCreatorsSignupScreenPresented';

const deltaSchemaMapperIntegration: SchemaMapperType = {
  [deltaClientEvents.generic.flow_started]: {
    schema: FLOW_STARTED_SCHEMA,
    schemaId: 105143,
  },
  [deltaClientEvents.generic.flow_ended]: {
    schema: FLOW_ENDED_SCHEMA,
    schemaId: 105142,
  },
  [deltaClientEvents.generic.button_pressed]: {
    schema: BUTTON_PRESSED_SCHEMA,
    schemaId: 105141,
  },
  [deltaClientEvents.generic.message_presented]: {
    schema: MESSAGE_PRESENTED_SCHEMA,
    schemaId: 105147,
  },
  [deltaClientEvents.generic.message_dismissed]: {
    schema: MESSAGE_DISMISSED_SCHEMA,
    schemaId: 105146,
  },
  [deltaClientEvents.generic.screen_presented]: {
    schema: SCREEN_PRESENTED_SCHEMA,
    schemaId: 105149,
  },
  [deltaClientEvents.generic.screen_dismissed]: {
    schema: SCREEN_DISMISSED_SCHEMA,
    schemaId: 105148,
  },
  [deltaClientEvents.generic.session_started]: {
    schema: SESSION_STARTED_SCHEMA,
    schemaId: 105151,
  },
  [deltaClientEvents.generic.session_ended]: {
    schema: SESSION_ENDED_SCHEMA,
    schemaId: 105150,
  },
  [deltaClientEvents.auth.login_step_started]: {
    schema: LOGIN_STEP_STARTED_SCHEMA,
    schemaId: 105145,
  },
  [deltaClientEvents.auth.login_step_ended]: {
    schema: LOGIN_STEP_ENDED_SCHEMA,
    schemaId: 105144,
  },
  [deltaClientEvents.auth.signup_step_started]: {
    schema: SIGNUP_STEP_STARTED_SCHEMA,
    schemaId: 105471,
  },
  [deltaClientEvents.auth.signup_step_ended]: {
    schema: SIGNUP_STEP_ENDED_SCHEMA,
    schemaId: 105470,
  },
  [SIGNUP_BUTTON_PRESSED_SCHEMA.name]: {
    schema: SIGNUP_BUTTON_PRESSED_SCHEMA,
    schemaId: 107094,
  },
  [SIGNUP_SCREEN_PRESENTED_SCHEMA.name]: {
    schema: SIGNUP_SCREEN_PRESENTED_SCHEMA,
    schemaId: 107093,
  },
  [SIGNUP_SCREEN_DISMISSED_SCHEMA.name]: {
    schema: SIGNUP_SCREEN_DISMISSED_SCHEMA,
    schemaId: 107097,
  },
  [SIGNUP_FLOW_STARTED_SCHEMA.name]: {
    schema: SIGNUP_FLOW_STARTED_SCHEMA,
    schemaId: 107095,
  },
  [SIGNUP_FLOW_ENDED_SCHEMA.name]: {
    schema: SIGNUP_FLOW_ENDED_SCHEMA,
    schemaId: 107096,
  },
};

export default deltaSchemaMapperIntegration;
