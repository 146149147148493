import React, {useState, useEffect} from 'react';
import {
  Button,
  Modal,
  Body,
  designSystemToken,
} from '@lightricks/react-design-system';
import {useMutation} from '@tanstack/react-query';
import {queryClient} from '@/utils/reactQueryClient';
import translate from '@/utils/translate';
import userUpdaters from '../../api/updaters/user';
import styles from './ModalTermsOfService.module.scss';
import useUserQuery from '../../hooks/queries/use-user-query';
import useToken from '../../hooks/use-token';

const TRANSLATION_PREFIX = 'components.modal-terms-of-service';

export type ModalTermsOfServiceProps = {
  testID?: string;
};

function ModalTermsOfService(props: ModalTermsOfServiceProps) {
  const {testID = 'modal-terms-of-service'} = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {isAuthenticated, initialized: tokenInitialized} = useToken(true);
  const {user, isLoading: isLoadingUser} = useUserQuery({
    overrideEnabled: isAuthenticated,
    include: [],
  });
  const {token, initialized} = useToken(true);

  useEffect(() => {
    if (!isLoadingUser && user) {
      const needsToAcceptTos =
        user.acceptedTosVersion !== user.currentTosVersion;
      setIsModalOpen(needsToAcceptTos);
    }
  }, [isLoadingUser, user]);

  const updateUserData = useMutation(userUpdaters.acceptTerms, {
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
      setIsModalOpen(false);
    },
  });

  if (!token || !initialized) {
    return null;
  }

  if (!isLoadingUser && !user) {
    return null;
  }

  const open = user?.acceptedTosVersion !== user?.currentTosVersion;

  const onClickAccept = () => {
    updateUserData.mutate(user?.currentTosVersion);
  };

  return (
    <Modal open={open} style={{padding: '8px'}} data-testid={testID}>
      <div className={styles.container}>
        <h1>{translate(`${TRANSLATION_PREFIX}.h1`)}</h1>
        <Body
          size="sm"
          color={designSystemToken('semantic.fg.secondary')}
          dangerouslySetInnerHTML={{
            __html: translate(`${TRANSLATION_PREFIX}.p`),
          }}
        />
        <Button
          testID="modal-terms-of-service__button"
          appearance="neutral"
          size="medium"
          mode="filled"
          onClick={onClickAccept}
        >
          {translate(`${TRANSLATION_PREFIX}.button-text`)}
        </Button>
      </div>
    </Modal>
  );
}

export default ModalTermsOfService;
