import {useShallow} from 'zustand/shallow';
import useUpdateName from '@/hooks/queries/mutations/use-update-name';
import Sentry from '@/services/sentry/Sentry';
import useUpdateBirthdate from '@/hooks/queries/mutations/use-update-birthdate';
import useUpdateProfile from '@/hooks/queries/mutations/use-update-profile';
import useCompleteOnboarding from '@/hooks/queries/mutations/use-complete-onboarding';
import assertUnreachable from '@/utils/assertUnreachable';
import {STEPS} from './onboardingSteps';
import {useOnboardingStore} from './OnboardingStore';

function useStepMutation() {
  // Mutation of fields that are updated immediately after the user selects them (without pressing next)
  // needs to be fetched using useOnboardingStore.getState().<field> because the hook doesn't update in time
  // as it depends on re-rendering the component
  const {
    step,
    fullName,
    birthdate,
    location,
    locale,
    openBanner,
    bio,
    specialties,
    capabilities,
    portfolioLink,
    setStepUploading,
  } = useOnboardingStore(useShallow((state) => state));

  const updateName = useUpdateName();
  const updateBirthdate = useUpdateBirthdate();
  const updateProfile = useUpdateProfile();
  const completeOnboarding = useCompleteOnboarding();

  const handleError = (error: Error) => {
    const message = 'Attempt to save onboarding data failed';
    Sentry.captureException(error, {message, step, error});

    openBanner({
      title: 'Error Saving Changes',
      children: 'There was a problem saving your changes. Please try again.',
      severity: 'error',
    });

    throw error;
  };

  const withUploading = (handler: () => Promise<void>) => async () => {
    setStepUploading(true);
    try {
      await handler();
    } finally {
      setStepUploading(false);
    }
  };

  const handleNameAndBirthdate = async () => {
    const [firstName, ...lastNameParts] = fullName.trim().split(' ');
    const lastName = lastNameParts.join(' ');

    await updateName.mutateAsync({firstName, lastName});
    if (birthdate) {
      await updateBirthdate.mutateAsync(birthdate);
    }
  };

  const handleCreatorSegment = async () => {
    const {segment} = useOnboardingStore.getState();
    switch (segment) {
      case 'creator':
        await updateProfile.mutateAsync({
          contentCreator: true,
          influencer: false,
        });
        break;
      case 'influencer':
        await updateProfile.mutateAsync({
          influencer: true,
          contentCreator: false,
        });
        break;
      default:
        throw new Error(`Invalid segment selected: ${segment}`);
    }
  };

  const handleLocation = async () => {
    if (!location) return;

    await updateProfile.mutateAsync({
      locale,
    });
  };

  const handleGender = async () => {
    const currentGender = useOnboardingStore.getState().gender;
    if (currentGender === undefined) return;

    await updateProfile.mutateAsync({gender: currentGender});
  };

  const handleSocialApps = async () => {
    // analytics only
  };

  const handleBestCollabs = async () => {
    // analytics only
  };

  const handleSpecialties = async () => {
    if (specialties.size === 0) return;

    await updateProfile.mutateAsync({
      tags: Array.from(specialties),
    });
  };

  const handleCapabilities = async () => {
    if (capabilities.size === 0) return;

    await updateProfile.mutateAsync({
      contentPreferences: Array.from(capabilities).map((name) => ({
        id: name,
        kind: name,
      })),
    });
  };

  const handleBio = async () => {
    try {
      await completeOnboarding.mutateAsync();
    } catch (error) {
      console.error('Error completing onboarding:', error);
    }

    if (bio === undefined) return;

    await updateProfile.mutateAsync({bio});
  };

  const handlePortfolio = async () => {
    if (portfolioLink) {
      await updateProfile.mutateAsync({portfolioLink});
    }
  };

  const getStepMutation = async (): Promise<void> => {
    switch (step) {
      case STEPS.NAME_AND_BIRTHDATE:
        return handleNameAndBirthdate();
      case STEPS.CREATOR_SEGMENT:
        return handleCreatorSegment();
      case STEPS.LOCATION:
        return handleLocation();
      case STEPS.GENDER:
        return handleGender();
      case STEPS.SOCIAL_APPS:
        return handleSocialApps();
      case STEPS.BEST_COLLABS:
        return handleBestCollabs();
      case STEPS.SPECIALTIES:
        return handleSpecialties();
      case STEPS.CAPABILITIES:
        return handleCapabilities();
      case STEPS.BIO:
        return handleBio();
      case STEPS.PORTFOLIO:
        return handlePortfolio();
      case STEPS.COMPLETION:
      case STEPS.INSTAGRAM_INSTRUCTIONS:
      case STEPS.SYNC_PLATFORMS:
      case STEPS.REONBOARDING:
        return Promise.resolve();
      default:
        return assertUnreachable(step);
    }
  };

  return async () => {
    try {
      await withUploading(getStepMutation)();
    } catch (error) {
      handleError(error as Error);
    }
  };
}

export default useStepMutation;
