import React, {useEffect} from 'react';
import {useShallow} from 'zustand/shallow';
import {useSearchParams} from 'react-router-dom';
import {MapboxFeature} from '@/types/mapbox';
import assertUnreachable from '@/utils/assertUnreachable';
import {NameAndBirthdate, Location} from './components/steps';
import {STEPS} from './onboardingSteps';
import {useOnboardingStore} from './OnboardingStore';
import GenderSelect from './steps/GenderSelect';
import SocialSelect from './steps/SocialSelect';
import BrandsSelect from './steps/BrandsSelect';
import Portfolio from './steps/Portfolio';
import Bio from './steps/Bio';
import SegmentSelect from './steps/SegmentSelect';
import SpecialtiesSelect from './steps/SpecialtiesSelect';
import CapabilitiesSelect from './steps/CapabilitiesSelect';
import Reonboarding from './steps/Reonboarding';
import ConnectPlatformsContent from './components/connect-platforms/ConnectPlatformsContent';
import authenticateFacebook from '@/services/facebook/Facebook';
import authenticateTiktok from '@/services/tiktok_creator_market_place/TiktokCreatorMarketPlace';
import authenticateYouTube from '@/services/youtube/Youtube';
import Sentry from '@/services/sentry/Sentry';
import {Platform} from '@/types/platforms';
import {useUserData} from '@/hooks/useUserData';
import {useOnboardingAnalytics} from './useOnboardingAnalytics';
import InstagramInstructions from './components/connect-platforms/InstagramInstructions';
import useAutoBio from './useAutoBio';
import OnboardingCompletion from './steps/OnboardingCompletion';

type LocationContextObject = {
  id: string;
  text: string;
};

function useResetStepValues() {
  const [setGender, setSegment, step] = useOnboardingStore(
    useShallow((store) => [store.setGender, store.setSegment, store.step])
  );

  useEffect(() => {
    if (step === STEPS.GENDER) {
      setGender(undefined);
    }
    if (step === STEPS.CREATOR_SEGMENT) {
      setSegment(undefined);
    }
  }, [step, setGender, setSegment]);
}

function useRenderStepContent(onNext?: () => Promise<void>) {
  const {step, setPlatformError, setStep, analyticsFlowId} =
    useOnboardingStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const {user} = useUserData({});
  useResetStepValues();
  const {
    sendSignUpScreenButtonPressedEvent,
    sendSignUpStepStartedEvent,
    sendSignUpStepEndedEvent,
  } = useOnboardingAnalytics(analyticsFlowId ?? '');

  const handleConnectPlatform = async (platform: Platform) => {
    sendSignUpScreenButtonPressedEvent(`connect-${platform}`, step);
    sendSignUpStepStartedEvent(platform);
    if (platform === 'Instagram') {
      setStep(STEPS.INSTAGRAM_INSTRUCTIONS);
      return;
    }
    try {
      const redirectQueryParams = {
        step: STEPS.SYNC_PLATFORMS,
      };

      switch (platform) {
        case 'TikTok':
          await authenticateTiktok(user?.id ?? null, redirectQueryParams);
          break;
        case 'YouTube':
          await authenticateYouTube(user?.id ?? null, redirectQueryParams);
          break;
        case 'Facebook':
          await authenticateFacebook(user?.id ?? null, redirectQueryParams);
          break;
        default:
          throw new Error(`Platform ${platform} is not supported.`);
      }
    } catch (err) {
      sendSignUpStepEndedEvent(platform, 'error', JSON.stringify(err));
      setPlatformError(`Failed to connect to ${platform}. Please try again.`);
      Sentry.captureException(
        {
          message: `Attempt to connect to unsupported platform: ${platform} failed`,
          extra: {
            platform,
            error: err,
          },
        },
        {
          level: 'error',
        }
      );
    }
  };

  const handleClosePlatformError = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('success');
    newParams.delete('message');
    newParams.delete('provider');
    setSearchParams(newParams);

    setPlatformError(undefined);
  };

  useAutoBio();

  const [
    fullName,
    birthdate,
    segment,
    location,
    gender,
    bio,
    socialApps,
    preferredCollabs,
    specialties,
    capabilities,
    profilePictureUrl,
    portfolioMediaItems,
  ] = useOnboardingStore(
    useShallow((store) => [
      store.fullName,
      store.birthdate,
      store.segment,
      store.location,
      store.gender,
      store.bio,
      store.selectedSocialApps,
      store.preferredCollabs,
      store.specialties,
      store.capabilities,
      store.profilePictureUrl,
      store.portfolioMediaItems,
    ])
  );

  const [
    setFullName,
    setBirthdate,
    setSegment,
    setLocation,
    setLocale,
    setGender,
    setBio,
    setSocialApps,
    setPreferredCollabs,
    setSpecialities,
    setCapabilities,
    setProfilePictureUrl,
    setPortfolioMediaItems,
  ] = useOnboardingStore(
    useShallow((store) => [
      store.setFullName,
      store.setBirthdate,
      store.setSegment,
      store.setLocation,
      store.setLocale,
      store.setGender,
      store.setBio,
      store.setSelectedSocialApps,
      store.setPreferredCollabs,
      store.setSpecialties,
      store.setCapabilities,
      store.setProfilePictureUrl,
      store.setPortfolioMediaItems,
    ])
  );

  switch (step) {
    case STEPS.NAME_AND_BIRTHDATE:
      return (
        <NameAndBirthdate
          nameValue={fullName}
          onChangeName={setFullName}
          birthdateValue={birthdate}
          onChangeBirthdate={setBirthdate}
        />
      );
    case STEPS.CREATOR_SEGMENT:
      return (
        <SegmentSelect
          selectedSegment={segment}
          onSegmentChange={async (newSegment) => {
            setSegment(newSegment);
            await onNext?.();
          }}
        />
      );
    case STEPS.LOCATION:
      return (
        <Location
          onChangeLocation={(selectedLocation: MapboxFeature | null) => {
            if (!selectedLocation) {
              setLocation(undefined);
              setLocale(undefined);
              return;
            }

            const englishPlaceName = selectedLocation?.place_name_en;
            const parsedContext = selectedLocation.context.reduce(
              (acc: Record<string, string>, context: LocationContextObject) => {
                acc[context.id.split('.')[0]] = context.text;
                return acc;
              },
              {}
            );

            setLocation(englishPlaceName ?? undefined);
            setLocale({
              id: selectedLocation.id,
              apiUid: selectedLocation.id,
              city: englishPlaceName?.split(',')[0],
              state: parsedContext.region,
              country: parsedContext.country,
              latitude: selectedLocation.center[1],
              longitude: selectedLocation.center[0],
            });
          }}
        />
      );
    case STEPS.GENDER:
      return (
        <GenderSelect
          selectedGender={gender}
          onGenderChange={async (newGender) => {
            setGender(newGender);
            await onNext?.();
          }}
        />
      );
    case STEPS.SOCIAL_APPS:
      return (
        <SocialSelect
          selectedSocialApps={socialApps}
          onSocialAppChange={setSocialApps}
        />
      );
    case STEPS.BEST_COLLABS:
      return (
        <BrandsSelect
          selectedBrands={preferredCollabs}
          onBrandChange={setPreferredCollabs}
        />
      );
    case STEPS.SPECIALTIES:
      return (
        <SpecialtiesSelect
          selectedSpecialties={specialties}
          onSpecialtiesChange={setSpecialities}
        />
      );
    case STEPS.CAPABILITIES:
      return (
        <CapabilitiesSelect
          selectedCapabilities={capabilities}
          onCapabilitiesChange={setCapabilities}
        />
      );
    case STEPS.BIO:
      return <Bio value={bio} onChange={setBio} />;
    case STEPS.PORTFOLIO:
      return (
        <Portfolio
          fullName={fullName}
          birthdate={birthdate}
          location={location}
          specialties={Array.from(specialties)}
          profilePictureUrl={profilePictureUrl}
          setProfilePictureUrl={setProfilePictureUrl}
          portfolioMediaItems={portfolioMediaItems}
          setPortfolioMediaItems={setPortfolioMediaItems}
        />
      );
    case STEPS.COMPLETION:
      return <OnboardingCompletion />;
    case STEPS.SYNC_PLATFORMS:
      return (
        <ConnectPlatformsContent
          onConnectPlatform={handleConnectPlatform}
          onCloseError={handleClosePlatformError}
        />
      );
    case STEPS.INSTAGRAM_INSTRUCTIONS:
      return <InstagramInstructions />;
    case STEPS.REONBOARDING:
      return <Reonboarding />;
    default:
      return assertUnreachable(step);
  }
}

export default useRenderStepContent;
