import {camelCase, cloneDeep} from 'lodash';
import deepMapKeys from 'deep-map-keys';

/**
 * helper function to convert object keys from one case to another
 * ex.
 * given object
 * data = {foo_bar: {baz_raz: 42}}
 * mapKeysToCase(data) => {fooBar: {bazRaz: 42}}
 * @param obj
 * @param toCaseFunc
 */
const mapKeysToCase = (
  obj: Array<object> | object | null,
  toCaseFunc = camelCase
): Array<object> | object | null => {
  if (obj) {
    return deepMapKeys(cloneDeep(obj), toCaseFunc);
  }
  return null;
};

export default mapKeysToCase;
