import {Player} from '@lottiefiles/react-lottie-player';
import React from 'react';
import {Body, Headline} from '@lightricks/react-design-system';
import reonboardingLottieAnimation from '@/assets/lotties/reonboarding.json';
import translate from '@/utils/translate';
import styles from './Reonboarding.module.scss';

export default function Reonboarding() {
  return (
    <div className={styles.reonboardingContainer}>
      <Player
        autoplay
        keepLastFrame
        loop
        src={reonboardingLottieAnimation}
        className={styles.reonboardingAnimation}
      />
      <div className={styles.reonboardingText}>
        <Headline size="xl">{translate('Complete account setup')}</Headline>
        <Body size="lg">
          {translate(
            'Complete a few steps from where you left, and get better matched with relevant gigs.'
          )}
        </Body>
      </div>
    </div>
  );
}
